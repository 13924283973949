<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-body">
            <div class="btn-popup pull-right">
                <button class="btn btn-secondary" (click)="exportExcel()">Exportar a Excel</button>
            </div>
            <div id="batchDelete" class="">
                <div class="table-responsive">
                    <table datatable
                    [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger"
                    class=" table table-lg table-hover row-border hover">
                    <thead>
                        <tr>
                        <th>Noº de pedido</th>
                        <th>Fecha</th>
                        <th>Cliente</th>
                        <th>Total</th>
                        <th>Status</th>
                        <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let order of orders">
                        <td>{{order.id}}</td>
                        <td>{{order.SaleOrderDate | date}}</td>
                        <td>{{order.Customer.firstName}} {{order.Customer.lastName}}</td>
                        <td>{{order.TotalAmount | currency}} {{order.CurrencyCode}}</td>
                        <td><span [ngClass]="{'open': order.SaleOrderStatus == 'Abierto', 'transit': order.SaleOrderStatus == 'En tránsito', 'close-order': order.SaleOrderStatus == 'Cerrado'}">{{order.SaleOrderStatus}}</span></td>
                        <td class="actions-cell">
                            <span>
                            <a [routerLink]="['show', order.id]"
                                title="Ver Pedido">
                                <i class="fa fa-eye"></i>
                            </a>
                            </span>
                            <span *ngIf="order.SaleOrderStatus == 'Abierto'" (click)="setModalContent(content, order.id)"
                            title="Agregar guía" class="cursor">
                                <i class="fa fa-truck"></i>
                            </span>
                            <span *ngIf="order.SaleOrderStatus == 'En tránsito'" (click)="setModalContentClose(contentClose, order.id)"
                            title="Cerrar pedido" class="cursor">
                                <i class="fa fa-check-circle"></i>
                            </span>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>

                <div class="table-responsive noshow">
                    <table datatable
                    [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger"
                    class=" table table-lg table-hover row-border hover"
                    id="excel-sales">
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Fecha</th>
                            <th>Cliente</th>
                            <th>Status</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let order of orders">
                            <td>{{order.id}}</td>
                            <td>{{order.SaleOrderDate | date}}</td>
                            <td>{{order.Customer.firstName}} {{order.Customer.lastName}}</td>
                            <td>{{order.SaleOrderStatus}}</td>
                            <td>{{order.TotalAmount | currency}} {{order.CurrencyCode}}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng-template #content
let-modal>
    <div class="modal-header">
    <h4 class="modal-title text-center width-100"
        id="modal-title">Agregar guía</h4>
    </div>
    <div class="modal-body text-center">
    <ng-container *ngIf="!guideIsSuccess">
        <p class="no-mrg">Se agregará la guía al pedido.</p>
    </ng-container>

    <ng-container *ngIf="guideIsSuccess">
        <p>Guía agregada correctamente</p>
    </ng-container>
    </div>
    <ng-container *ngIf="!guideIsSuccess">
    <div class="modal-footer">
        <form [formGroup]="form"
        (ngSubmit)="onSubmit()" class="right">
        <div class="form-group">
            <input type="text"
            class="form-control"
            placeholder="Nº de guía"
            formControlName="TrackingNumber"
            [ngClass]="{'error': submitted && formState?.TrackingNumber?.errors?.required}">
        </div>
        <a type="button"
        class="btn btn-outline-light"
        (click)="modal.close()">Cancelar</a>
        <button type="submit"
        class="btn btn-inverse btn-success">Agregar
        </button>
        </form>
    </div>
    </ng-container>
</ng-template>

<ng-template #contentClose
  let-modal>
  <div class="modal-header">
    <h4 *ngIf="!orderCloseSuccess" class="modal-title text-center width-100"
      id="modal-title">Cerrar Pedido</h4>

    <h4 *ngIf="orderCloseSuccess" class="modal-title text-center width-100"
      id="modal-title">Pedido <strong>Cerrado</strong></h4>
  </div>
  <div class="modal-body text-center">
    <ng-container *ngIf="!orderCloseSuccess">
      <p class="no-mrg">Se actualizará el status del pedido</p>
      <p class="no-mrg">Con esto confirmas que el pedido ya ha sido entregado al cliente.</p>
      <p class="no-mrg">Esta acción no se puede deshacer.</p>
    </ng-container>

    <ng-container *ngIf="orderCloseSuccess">
      <p>Pedido cerrado correctamente</p>
    </ng-container>
  </div>
  <ng-container *ngIf="!orderCloseSuccess">
    <div class="modal-footer right">
      <a type="button"
        class="btn btn-outline-light"
        (click)="modal.close()">Cancelar</a>
      <button type="button"
        class="btn btn-inverse btn-danger"
        (click)="finishOrder()">Actualizar</button>
    </div>
  </ng-container>
</ng-template>