<div class="container-fluid">
    <div class="position-relative">
        <div class="card">
            <div class="card-body" style="padding: 0;">
                <div id="batchDelete">
                    <!-- Opciones de filtrado -->
                    <div class="col-xs-12" style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">
                        <mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedIndexChange)="onTabChange($event)" class="custom-tab-group">
                            <mat-tab label="Todos ({{ total }})"></mat-tab>
                            <mat-tab label="Revisión ({{revisionCount}})"></mat-tab>
                            <mat-tab label="Pago ({{pagoCount}})"></mat-tab>
                            <mat-tab label="Autorización ({{autorizacionCount}})"></mat-tab>
                        </mat-tab-group>
                    </div>
                    <!-- Opciones de filtrado -->

                    <!-- Barra de Búsqueda -->
                    <div class="search-bar" >
                        <div class="flex-grow-1 mr-1 search-container">
                            <i class="fa fa-search search-icon" aria-hidden="true"></i>
                            <input placeholder="Buscar por folio o nombre de ciudadano" class="form-control search-input" id="validationCustom1" type="text" [(ngModel)]="searchValue">
                        </div>
                        <div class="mr-1">
                            <button class="btn btn-primary btn-buscar" (click)="applyFilters()">Buscar</button>
                        </div>
                        <div>
                            <button class="btn btn-primary btn-filtros" (click)="toggleSearchMenu()">
                                <i class="fa fa-filter" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                    <!-- Barra de Búsqueda -->
                    
                    <div class="chips">
                        <mat-chip-list>
                          <mat-chip
                            *ngFor="let filter of selectedFilters"
                            [removable]="true"
                            (removed)="removeFilter(filter.type)">
                            {{ getFilterLabel(filter.type) }} {{ filter.value }}
                            <mat-icon matChipRemove>x</mat-icon>
                          </mat-chip>
                        </mat-chip-list>
                    </div>  
                </div>
                
                <div class="table-responsive">
                    <table mat-table class="table" matSort (matSortChange)="sortData($event)" [dataSource]="dataSource">
                        <ng-container matColumnDef="tramite">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="tramite" class="titulos">TRÁMITE</th>
                            <td mat-cell *matCellDef="let item">{{ item.Form.FormsCommon.Name }}</td>
                        </ng-container>
                        
                        <ng-container matColumnDef="folio">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="folio" class="titulos">FOLIO</th>
                            <td mat-cell *matCellDef="let item">{{ item.CitizenRequest.Folio }}</td>
                        </ng-container>
                        
                        <ng-container matColumnDef="ciudadano">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="ciudadano" class="titulos">CIUDADANO</th>
                            <td mat-cell *matCellDef="let item">{{ item.Applicant.Name }}<br>{{ item.Applicant.CURP }}</td>
                        </ng-container>
                        
                        <ng-container matColumnDef="fechaCreacion">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="fechaCreacion" class="custom-h-fecha">FECHA DE CREACIÓN</th>
                            <td mat-cell *matCellDef="let item" class="custom-c-fecha">{{ formatDate(item.CitizenRequest.created_at) }}</td>
                        </ng-container>
                        
                        <ng-container matColumnDef="estatus">
                            <th mat-header-cell *matHeaderCellDef class="custom-h-estatus"> ESTATUS </th>
                            <td mat-cell *matCellDef="let element">
                                <mat-chip-list class="status-column">
                                    <mat-chip class="status-chip" [ngClass]="getChipClass(element.Phases.Phase[0]?.Status)">
                                        {{element.Phases.Phase[0]?.Status || 'Estado no disponible'}}
                                    </mat-chip>
                                </mat-chip-list>
                            </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="verDetalle">
                            <th mat-header-cell *matHeaderCellDef class="custom-h-verdetalle">DETALLE</th>
                            <td mat-cell *matCellDef="let item" class="custom-c-verdetalle">
                                <!-- Abrir Ver Trámite en una pestaña nueva. -->
                                <a mat-icon-button [routerLink]="['/catalogs/ver-tramite', item.CitizenRequest.id]" target="_blank" class="boton-detalle verdetalle">
                                    <img src="assets\images\ojo.png" alt="Ojo">
                                </a>
                                <!-- ./Abrir Ver Trámite en una pestaña nueva. -->
                            </td>
                        </ng-container>
                        
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
                
                <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="pageSize" (pageChange)="loadPage($event)"></ngb-pagination>
            </div>
        </div>
        
        <div *ngIf="showSearchMenu" id="search-menu" class="menu-container right-menu menu-open position-absolute p-3">
            <div class="d-flex align-items-center mb-3">
                <i class="fa fa-filter" aria-hidden="true"></i>
                <h3 class="mb-0 ml-2">Filtro</h3>
                <button type="button" class="close ml-auto" aria-label="Close" (click)="toggleSearchMenu()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="form-group mb-2">
                <label for="area">Área:</label>
                <ng-select bindLabel="name" bindValue="id" [(ngModel)]="selectedArea" (ngModelChange)="loadTramitesByArea(selectedArea)">
                    <ng-option [value]="null">Todos</ng-option>
                    <ng-option *ngFor="let area of areas" [value]="area.id">{{ area.name }}</ng-option>
                </ng-select>
            </div>            
            
            <div class="form-group mb-2">
                <label for="tramite">Trámite:</label>
                <ng-select bindLabel="name" [(ngModel)]="selectedTramite">
                    <ng-option [value]="null">Todos</ng-option>
                    <ng-option *ngFor="let tramite of tramites" [value]="tramite">{{ tramite }}</ng-option>
                </ng-select>
            </div>
            
            <div class="form-group mb-2">
                <label for="estatus">Estatus:</label>
                <ng-select bindLabel="label" bindValue="value" [(ngModel)]="selectedEstatus" >
                  <ng-option *ngFor="let estatus of estatusOptions" [value]="estatus.value">{{ estatus.label }}</ng-option>
                </ng-select>
            </div>
            
            <div class="row mb-2">
                <div class="col-md-6 date-field">
                    <label for="fechaInicio">Creado desde el:</label>
                    <input type="date" class="form-control" id="fechaInicio" [(ngModel)]="fechaInicio">
                </div>
                <div class="fechafin date-field">
                    <label for="fechaFin">hasta el:</label>
                    <input type="date" class="form-control" id="fechaFin" [(ngModel)]="fechaFin">
                </div>
            </div>
            <div class="d-flex justify-content-between menu-buttons mt-3">
                <button class="btn btn-sm mr-1 btn-filtrar" (click)="applyFilters()">Filtrar</button>
                <button class="btn btn-sm btn-limpiar" (click)="clearFilters()">Limpiar</button>
            </div>
        </div>
    </div>
</div>
