import { ReportsService } from '@/app/components/services/reports/reports.service';
import { ApiService } from '@/app/shared/service/api.service';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-list-tramites',
  templateUrl: './list-tramites.component.html',
  styleUrls: [
    './list-tramites.component.scss',
    '../../../catalogs/ver-tramite/ver-tramite.component.scss'
  ]
})
export class ListTramitesComponent implements OnInit {

  @Input() id: string;
  @Input() location: string;
  
  public data: any[];
  backData: any[];
  dependencies: any;
  suscripciones: Subscription[] = [];

  tramiteId: number = null;

  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic',
    size: 'lg',
    centered: true
  };

  constructor(
    private apiService: ApiService,
    private reportService: ReportsService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    // Obtener los departamentos.
    this.suscripciones.push(
      this.getDependencies().subscribe(
        (dependencias) => this.dependencies = dependencias,
        (error) => console.log(error)
      )
    );
    
    // Cargar la lista de trámites del Ciudadano.
    this.suscripciones.push(
      this.cargarDatos().subscribe(
        (tramitesCiudadano) => {
          this.data = this.ordenarListaDescendente(tramitesCiudadano);
          this.backData = this.data;
        },
        (error) => console.log(error)
      )
    );
  }

  actualizarTramiteId(id: number): void {
    this.tramiteId= id;
  }

  hasTramiteIdValue(): boolean {
    if(this.tramiteId && this.tramiteId != null)
      return true;

    return false;
  }

  ngOnDestroy(): void {
    // Operaciones adicionales al abandonar la sección Lista de Trámites.
    if(this.suscripciones.length > 0){
      this.suscripciones.forEach((s: Subscription) => s.unsubscribe());
    }
  }

  modalProcederAPago(content: any): void {
    this.modalService.open(content, this.modalConfig);
    return;
  }

  realizarPagoTramite(): void {
    // Cambiar el estatus del trámite por "Pendiente de pago"
    
  }

  closeModal(): void {
    this.modalService.dismissAll();
    return;
  }
  
  ordenarListaDescendente(lista: any[]): any[] {
    return lista.sort((a,b) => b.Phases.CitizenRequest.id - a.Phases.CitizenRequest.id);
  }

  getDependencies(): Observable<any[]> {
    return this.reportService.getDataCatalogs("Deparments", 9999, 0);
  }

  updateMinDate(event) {
    const userDateParsed = moment(event.target.value);
    this.data = this.backData.filter(item => moment(item.Phases.CitizenRequest.created_at).isAfter(userDateParsed));
  }

  updateMaxDate(event) {
    const userDateParsed = moment(event.target.value);
    this.data = this.backData.filter(item => moment(item.Phases.CitizenRequest.created_at).isBefore(userDateParsed));
  }

  updateStatus(event) {
    const userDateParsed = event.target.value
    if (userDateParsed !== 'All') this.data = this.backData.filter(item => item.Phases.Status === userDateParsed);
    else this.data = this.backData;
  }

  updateFolio(event) {
    const userDateParsed = event.target.value;
    console.log(userDateParsed);
    this.data = this.backData.filter(item => item.Phases.CitizenRequest.Folio.includes(userDateParsed));
  }

  cargarDatos() : Observable<any[]> {
    return this.apiService.getTramitesByUser(this.id);
  }
}
