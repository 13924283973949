<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-body tab2-card">
                    <ngb-tabset class="tab-coupon">
                        <ngb-tab title="Cuenta">
                            <ng-template ngbTabContent>                                                                                                                                        <div class="tab-pane fade active show" id="account" role="tabpanel"
                                        aria-labelledby="account-tab">
                                    <form [formGroup]="accountForm" (ngSubmit)="onSubmit()" class="form-horizontal auth-form" novalidate>
                                        <h4 class="mb-4">Detalles de cuenta</h4>
                                        <div class="row">
                                            <div class="col-xs-12 col-sm-6">
                                                <div class="form-group">
                                                    <input required="" formControlName="firstName" type="text"
                                                    class="form-control" placeholder="Nombre"
                                                    [ngClass]="{'error': submitted && formState?.firstName?.errors?.required}">
                                                </div>
                                            </div>
                                            <div class="col-xs-12 col-sm-6">
                                                <div class="form-group">
                                                    <input required="" formControlName="lastName" type="text"
                                                    class="form-control" placeholder="Apellido"
                                                    [ngClass]="{'error': submitted && formState?.lastName?.errors?.required}">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xs-12 col-sm-6">
                                                <div class="form-group">
                                                    <input class="form-control" formControlName="password" placeholder="Contraseña"
                                                    type="password" [ngClass]="{'error': submitted && formState?.password?.errors?.required}">
                                                </div>
                                            </div>
                                            <div class="col-xs-12 col-sm-6">
                                                <div class="form-group">
                                                    <input class="form-control" formControlName="confirmedPassword" placeholder="Confirmar contraseña"
                                                    type="password" [ngClass]="{'error': submitted && formState?.confirmedPassword?.errors?.required}">
                                                    <label class="errorRed" style="padding-left: 6%;"
                                                        *ngIf="submitted && formState?.confirmedPassword?.errors">
                                                        Las contraseñas no coinciden.
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xs-12 col-sm-6">
                                                <div class="form-group">
                                                    <input class="form-control" formControlName="email" placeholder="Correo electrónico o numero de teléfono a 10 dígitos"
                                                        type="text" [ngClass]="{'error': submitted && formState?.email?.errors?.required}">
                                                    <label class="errorRed" style="padding-left: 6%;"
                                                        *ngIf="submitted && formState?.email?.errors?.pattern">
                                                        Ingresa un correo válido o un numero de teléfono de 10 dígitos.
                                                    </label>
                                                </div>
                                            </div>

                                        
                                            <div class="col-xs-12 col-sm-6"  *ngIf="showArea === true">
                                                <div class="form-group">
                                                    <ng-select class="form-control" formControlName="area" placeholder="Selecciona Área">
                                                        <ng-option value="" disabled selected>Seleccione el área</ng-option>
                                                        <ng-option *ngFor="let value of values" [value]="value">
                                                            {{value}}
                                                        </ng-option>
                                                    </ng-select>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="pull-right mt-4">
                                            <button class="btn btn-primary"
                                                [disabled]="isLoading" *ngIf="onEdit == false">
                                                {{ isLoading ? 'Creando cuenta' : 'Registrar'}}
                                                <i class="fa fa-spinner fa-spin"
                                                [hidden]="!isLoading"></i>
                                            </button>
                                            <button type="submit" class="btn btn-primary" *ngIf="onEdit == true">
                                                {{ isLoading ? 'Editando cuenta' : 'Editar usuario'}}
                                                <i class="fa fa-spinner fa-spin"
                                                [hidden]="!isLoading"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng2-toasty></ng2-toasty>