import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportsRoutingModule } from './reports-routing.module';
import { ReportsComponent } from './reports.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import { ChartsModule } from 'ng2-charts';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartistModule } from 'ng-chartist'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ToastyModule } from 'ng2-toasty';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { DataTablesModule } from 'angular-datatables';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReportsAreaComponent } from './reports-area/reports-area.component';
import { ReportsSuburbComponent } from './reports-suburb/reports-suburb.component';
import { ReportsUsersComponent } from './reports-users/reports-users.component';
import { ReportsGeneralComponent } from './reports-general/reports-general.component';
import { CreateReportComponent } from './create-report/create-report.component';
import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { ReportDetailsComponent } from './report-detail/report-detail.component';
import { ReportsByUserComponent } from './reports-by-user/reports-by-user.component';
import { ReportsByAreaComponent } from './reports-by-area/reports-by-area.component';
import { ExportReportComponent } from './export-report/export-report.component';
import { environment } from 'src/environments/environment';
import { ExportReportAreaComponent } from './export-report-area/export-report-area.component';
import { ReportsNotAssignedComponent } from './reports-not-assigned/reports-not-assigned.component';
import { ReportsSourceComponent } from './reports-source/reports-source.component';
import {ToolbarModule} from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import {InputTextModule} from 'primeng-lts/inputtext';
import {MessagesModule} from 'primeng-lts/messages';
import {MessageModule} from 'primeng-lts/message';
import {AutoCompleteModule} from 'primeng-lts/autocomplete';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { GalleriaModule } from 'primeng/galleria';

const API: string = environment.API_KEY;

@NgModule({
  declarations: [ReportsComponent,
    ReportsGeneralComponent,
    ReportsSourceComponent,
    ReportsAreaComponent,
    ReportsUsersComponent,
    ReportsByAreaComponent,
    ReportsSuburbComponent,
    ReportsByUserComponent,
    ExportReportComponent,
    CreateReportComponent,
    ReportDetailsComponent,
    ExportReportAreaComponent,
    ReportsNotAssignedComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    Ng2GoogleChartsModule,
    NgxChartsModule,
    ChartistModule,
    ReportsRoutingModule,
    Ng2SmartTableModule,
    ToastyModule,
    NgSelectModule,
    AgmCoreModule.forRoot({
      apiKey: `${API}`,
      libraries: ["geometry", "places"],
      apiVersion: 'quarterly'
    }),
    MatGoogleMapsAutocompleteModule,
    DataTablesModule,
    NgxDatatableModule,
    NgbModule,
    NgMultiSelectDropDownModule.forRoot(),



    ToolbarModule,
    ButtonModule,
    SplitButtonModule,
    InputTextModule,
    MessagesModule,
    MessageModule,
    AutoCompleteModule,
    NgxSkeletonLoaderModule,
    GalleriaModule
    
    
  ]
})
export class ReportsModule { }
