import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Dashboardv2RoutingModule } from './dashboardv2-routing.module';
import { Dashboardv2Component } from './dashboardv2.component';
import { CountToModule } from 'angular-count-to';
import { ChartsModule } from 'ng2-charts';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartistModule } from 'ng-chartist';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { ToastyModule } from 'ng2-toasty';
import {InputSwitchModule} from 'primeng/inputswitch';
import {RadioButtonModule} from 'primeng/radiobutton';
import {ButtonModule} from 'primeng/button';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SplitButtonModule } from 'primeng/splitbutton';


@NgModule({
  declarations: [Dashboardv2Component],
  imports: [
    CommonModule,
    FormsModule,
    Dashboardv2RoutingModule,
    CountToModule,
    ReactiveFormsModule,
    SharedModule,
    ChartsModule,
    Ng2GoogleChartsModule,
    NgxChartsModule,
    ChartistModule,
    CommonModule,
    AgmCoreModule,
    ToastyModule,

    FormsModule,
    CountToModule,
    SharedModule,
    ChartsModule,
    Ng2GoogleChartsModule,
    NgxChartsModule,
    ChartistModule,

    InputSwitchModule,
    RadioButtonModule,
    ButtonModule,
    NgxSkeletonLoaderModule,
    SplitButtonModule,
  ]
})
export class Dashboardv2Module { }
