<div class="w-100">
    <div class="card w-100">
        <div class="card-header d-flex justify-content-between align-items-end">
            <span class="title mb-0">
                Mi Expediente Electrónico
            </span>
        </div>
        <div class="card-body">
            <div class="card-body px-0">
                <div class="container-fluid">
                    <div class="row document-header">
                        <div class="col-12 col-md-9">
                            Documentos cargados
                        </div>
                        <div class="col-12 col-md-1">
                            <a>Ver</a>
                        </div>
                        <div class="col-12 col-md-2">
                            <a>Reemplazar</a>
                        </div>
                    </div>
                    <div class="row document-row mt-3" *ngFor="let document of data; let i = index;">
                        <div class="col-12 pl-0 col-md-9">
                            <div class="document-data row">
                                <div class="col-12 col-md-7 px-0">
                                    <span class="document-info">{{ document.document }}</span>
                                </div>
                                <div class="col-12 col-md-5 px-0">
                                    <span class="document-date"> Última actualización: {{
                                        document.data.updated_at | date: 'dd/MM/Y' }} </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-1 d-flex justify-content-center px-0">
                            <div
                                class="document-data text-center h-100 d-flex align-items-center justify-content-center">
                                <a style="cursor: pointer;" (click)="openDocument(document)">
                                    <i class="fa fa-eye mr-0" style="color: #31896C;"></i>
                                </a>
                            </div>
                        </div>
                        <div class="col-12 col-md-2 px-0">
                            <div
                                class="document-data text-center h-100 d-flex align-items-center justify-content-center">
                                <input type="file" #update (change)="changeDocument($event, document)"
                                    style="display: none;">
                                <a style="cursor: pointer;" (click)="update.click();">
                                    <i class="fa fa-refresh mr-0" style="color: #2687BA;"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng2-toasty></ng2-toasty>