import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CatalogsComponent } from './catalogs.component';
import { CreateServiciosComponent } from './create-servicios/create-servicios.component';
import { BandejatramitesComponent } from './bandejatramites/bandejatramites.component';
import { CreateTramitesComponent } from './create-tramites/create-tramites.component';
import { VerTramiteComponent } from './ver-tramite/ver-tramite.component';
import { ShowTramiteComponent } from './show-tramite/show-tramite.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'ver_ficha/:id',
        component: ShowTramiteComponent,
        data: {
          title: 'Ver Trámite',
        }
      },
      {
        path: 'ver-tramite/:id',
        component: VerTramiteComponent,
        data: {
          title: 'Ver Trámite',
        }
      },
      {
        path: 'create-servicios',
        component: CreateServiciosComponent,
        data: {
          title: 'Crear Servicio',
        }
      },
      {
        path: 'create-tramites',
        component: CreateTramitesComponent,
        data: {
          title: 'Crear Trámite',
        }
      },
      {
        path: 'areas',
        component: CatalogsComponent,
        data: {
          title: "Áreas",
          breadcrumb: "Áreas"
        }
      },
      {
        path: 'dependencias',
        component: CatalogsComponent,
        data: {
          title: "Dependencias",
          breadcrumb: "Dependencias"
        }
      },
      {
        path: 'services',
        component: CatalogsComponent,
        data: {
          title: "Servicios",
          breadcrumb: "Servicios"
        }
      },
      {
        path: 'sources',
        component: CatalogsComponent,
        data: {
          title: "Fuentes",
          breadcrumb: "Fuentes"
        }
      },
      {
        path: 'users',
        component: CatalogsComponent,
        data: {
          title: "Perfiles",
          breadcrumb: "Perfiles"
        }
      },
      {
        path: 'bandeja',
        component: BandejatramitesComponent,
        data: {
          title: "Bandeja de Trámites",
          breadcrumb: "Bandeja de Trámites",
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CatalogsRoutingModule { }
