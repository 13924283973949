<div class="page-wrapper">
    <div class="authentication-box">
        <div class="container">
            <div class="row">
                <div class="col-md-3">  </div>
                <div class="col-md-6 p-0">
                    <div class="card tab2-card">
                        <div class="card-body">
                            <div class="tcenter">
                                <img src="../../../../assets/images/logo-ver.png" style="width: 60%;" alt="">
                            </div>
                            <ngb-tabset class="tab-coupon mb-0">
                                <ngb-tab>
                                    <ng-template ngbTabContent>
                                        <h5 class="center">Iniciar reporte como:</h5>
                                        <div class="tab-pane fade active show" id="account" role="tabpanel"
                                            aria-labelledby="account-tab">
                                            <button [routerLink]="['/iniciar-sesion']"class="btn btn-primary">
                                                Ciudadano
                                            </button>
                                            <button [routerLink]="['/iniciar-sesion']"class="btn btn-primary">
                                                Jefe de manzana
                                            </button>
                                            <button [routerLink]="['/reporte']"class="btn btn-primary">
                                                Invitado
                                            </button>
                                        </div>
                                    </ng-template>
                                </ngb-tab>
                            </ngb-tabset>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">  </div>
            </div>
        </div>
    </div>
</div>

<ng2-toasty></ng2-toasty>