import { Component, OnInit,ViewChild,AfterViewInit,Input } from '@angular/core';
import { Chart,ChartType } from 'chart.js';
import { ChartOptions } from 'ng-chartist';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Color, Label, MultiDataSet } from 'ng2-charts';
import { MenuItem } from 'primeng/api';
import { ReportsService } from '../services/reports/reports.service';
import * as moment from 'moment-timezone';


interface City {
  name: string,
  code: string
}



@Component({
  selector: 'app-dashboardv2',
  templateUrl: './dashboardv2.component.html',
  styleUrls: ['./dashboardv2.component.scss']
})
export class Dashboardv2Component implements OnInit {

  @ViewChild("baseC") ctx;
  @ViewChild('chart', { static: false }) private chartContainer: any;
  @ViewChild('tooltip', {static: false}) tooltipRef: any;
  @ViewChild('chartCanvas', { static: false }) chartCanvas: any;
  @ViewChild('chartCanvastwo', { static: false }) chartCanvastwo: any;
  @ViewChild('chartCanvasthree', { static: false }) chartCanvasthree: any;
  @ViewChild('baseC', { static: false }) chartCanvasline: any;
  private svg;
  private tooltip: HTMLElement;
  datax = [
    { label: 'A', value: 50, color: '#ff8c00' },
    { label: 'B', value: 20, color: '#3cb371' },
    { label: 'C', value: 30, color: '#6495ed' }
  ];

  ctxone; 
  ctxtwo; 
  ctxthree; 
  ctxline; 

  chartone;
  chartwo;
  charthree;
  charline;

  chartoneExit = 0;
  charttwoExit = 0;
  chartthreeExit = 0;
  chartLineExit = 0;
 
  exitsData = true;
  registerForm:FormGroup;
  zones = []
  surveys = []
  totalReportZone = 0;
  isLoading = false;
  active_download = false;
  category;
  colorsDounet:any[];
  mesesDiferent = 0;
  checked: boolean;
  doughnutChartLabels: Label[] = []
  doughnutChartData: MultiDataSet = [];
  @Input() data: any[];

  itemsFilter: MenuItem[];
  
  doughnutChartType: ChartType = 'doughnut';

  doughnutChartLabelsZones: Label[] = []
  doughnutChartDataZones: MultiDataSet = [
    []
  ];
  chartOptions: ChartOptions = {
    plugins: {
      
    },
  }


  

  dasboardReport:any;

        // Array of different segments in chart
        lineChartData: any[] = [
          { data: [], label: '' },
          { data: [], label: '' }
        ];
      
        //Labels shown on the x-axis
        lineChartLabels: Label[] = [];
      
        // Define chart options
        lineChartOptions: ChartOptions = {
          responsive: true
        };
      


        // Define colors of chart segments

        
        lineChartColors: Color[] = [
         {
      
         }
        ];
      
        // Set true to show legends
        lineChartLegend = true;
      
        // Define type of chart
        lineChartType = 'line';
      
        lineChartPlugins = [];





        public barChartData: any[] = [
          { data: [0, 0, 0, 0], label: 'Series A' },
          { data: [0, 0, 0, 0], label: 'Series B' },
        ];
        
        public lineChartData2: Array<any> = [
          {
            data: [0, 0, 0, 0],
            label: 'Series A',
            lineTension: 0,
            fill: false,
            borderColor: 'blue',
            borderWidth: 2,
          },
        ];
        public chartLabels: Array<any> = ['January', 'February', 'March', 'April'];

        public chartData: Array<any> = [
          { data: [0, 0, 0, 0], label: 'reportes del mes', type: 'bar' },
          {
            data: [0, 0, 0, 0],
            label: 'reportes acumulados',
            lineTension: 0,
            fill: false,
            type: 'line',
            borderColor: 'blue',
            borderWidth: 2,
          },
        ];




        public chartOptions2: any = {
          scaleShowVerticalLines: false,
          responsive: true,
        };
        
        public chartType: string = 'bar';
        public chartLegend: boolean = true;
        

        
        
        public chartColors: Array<any> = [
          {
            backgroundColor: '',
            borderColor: 'rgba(255,0,0,0.8)',
            pointBackgroundColor: 'rgba(255,0,0,0.8)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(255,0,0,0.8)',
          },
          {
            backgroundColor: 'rgba(0,255,0,0.3)',
            borderColor: 'rgba(0,255,0,0.8)',
            pointBackgroundColor: 'rgba(0,255,0,0.8)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(0,255,0,0.8)',
          },
          {
            borderColor: 'blue',
            borderWidth: 2,
            fill: false,
          },
        ];





      
        // events
        chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
          console.log(event, active);
        }
      
        chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
          console.log(event, active);
        }


      
        






        public doughnutChartLabels_one = [
          '',
          '',
          '',
        ];
        public doughnutChartData_one= [[0, 0, 0, 0, 0, ]];



        public doughnutChartLabels_two = [
          '',
          '',
          '',
        ];
        public doughnutChartData_two= [[0, 0, 0, 0, 0, ]];


        public doughnutChartLabels_three= [
          '',
          '',
          '',
        ];
        public doughnutChartData_three= [[0, 0, 0, 0, 0, ]];

        public options_item_one = {
            legend: {
              display: false,
            },

            scales: {
              y: {
                  beginAtZero: true,
                  ticks: {
                      display: true // Mostrar los labels en el eje y
                      
                  }
              },
              x: {
                  ticks: {
                      display: true // Mostrar los labels en el eje x
                  }
              }
          },

          plugins: {
            tooltip: {
                enabled: false // Desactivar los tooltips
            }
        }
    
        };
        public doughnutChartType_one: ChartType = 'doughnut';
        public doughnutChartPlugins_one = [
          {
            afterDraw(chart) {
              // console.log(
              //   chart.tooltip._data.datasets[0].data.reduce((a, b) => a + b)
              // );
              const ctx = chart.ctx;
              var txt1 = 'Rahul';
              var txt2 = '93%';
      
              try {
                var check = chart.active
                  ? chart.tooltip._active[0]._datasetIndex
                  : 'None';
                if (check !== 'None') {
                  // txt1 = chart.tooltip._data.labels[chart.tooltip._active[0]._index];
                  // txt2 = chart.tooltip._data.datasets[0].data[chart.tooltip._active[0]._index];
                  txt2 =
                    (
                      (chart.tooltip._data.datasets[0].data[
                        chart.tooltip._active[0]._index
                      ] /
                        chart.tooltip._data.datasets[0].data.reduce(
                          (a, b) => a + b
                        )) *
                      100
                    )
                      .toFixed(2)
                      .toString() + '%';
                } else {
                  // txt1 = chart.tooltip._data.labels[0];
                  // txt2 = chart.tooltip._data.datasets[0].data[0];
                  txt2 =
                    (
                      (chart.tooltip._data.datasets[0].data[
                        chart.tooltip._active[0]._index
                      ] /
                        chart.tooltip._data.datasets[0].data.reduce(
                          (a, b) => a + b
                        )) *
                      100
                    )
                      .toFixed(0)
                      .toString() + '%';
                }
              } catch (err) {
                txt1 = chart.tooltip._data.labels[0];
                txt2 =
                  (
                    (chart.tooltip._data.datasets[0].data[0] /
                      chart.tooltip._data.datasets[0].data.reduce((a, b) => a + b)) *
                    100
                  )
                    .toFixed(0)
                    .toString() + '%';
                // console.log(txt2);
              }
      
              //Get options from the center object in options
              // const sidePadding = 60;
              // const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
      
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
              const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
      
              //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
      
              // const stringWidth = ctx.measureText(txt1).width;
              // const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;
      
              // Find out how much the font can grow in width.
              // const widthRatio = elementWidth / stringWidth;
              // const newFontSize = Math.floor(30 * widthRatio);
              // const elementHeight = (chart.innerRadius * 2);
      
              // Pick a new font size so it will not be larger than the height of label.
              const fontSizeToUse = 20;
              ctx.font = fontSizeToUse + 'px Arial';
              ctx.fillStyle = 'black';
              ctx.zIndex ='999999'
              ctx.fillText(txt2, centerX, centerY);
      
              // Draw text in center
              // var fontSizeToUse1 = 15;
              // ctx.font = fontSizeToUse1 + 'px Arial';
              // ctx.fillText(txt1, centerX, centerY + 10);
            },
          },
        ];





  constructor(private _surveysService:ReportsService, private fb: FormBuilder) { }

  ngOnInit(): void {

    var date = new Date();
    var dateForm = moment(date).format("YYYY") + "-01-01"
    var dateForm2 = moment(date).format("YYYY") + "-12-31"

    this.registerForm = this.fb.group({ 
      start_date: [dateForm],
      end_date: [dateForm2],
      zone: [""], 
      category:   [""],
    })


  
    
    this.itemsFilter = [

  ];

    
  // this._surveysService.getSurveyArea().subscribe(response => {
  //   this.zones = response;
  // })

  // this._surveysService.getSurveys().subscribe(response => {
  //   this.surveys = response;  
  // })


  // this._surveysService.getCategory().subscribe(response => {
  //   this.category = response;  

    
  // })





  }







getCategory(){
    return this.registerForm.controls['category'].value;
 }

 getZone(){
  return this.registerForm.controls['zone'].value;
 }

  filterDashboard(){
    this.exitsData = true;
    this.isLoading = true;
    const data  = this.buildForm();


    const fecha1 = moment(data.start_date);
    const fecha2 = moment(data.end_date);

    this.mesesDiferent = fecha2.diff(fecha1, 'months');
    this._surveysService.statisReport(data).subscribe(response => {


 
        this.dasboardReport = {
          Total:response.Total,
          ClosedReport:response.ClosedReport,
          pendingReport:response.pendingReport,
          compliance: Math.round((response.ClosedReport * 100) / response.Total) || 0,
          ChartDate: response.ChartDate,
          ChartCategory:response.ChartCategory,
          ChartZone:response.ChartZone,
          ChartFuente: response.ChartFuente
          }
        ;
      
         if(this.dasboardReport.ChartDate.length == 0){
          this.exitsData = false;

         }else{
          // this.lineChartLabels = this.dasboardReport.ChartDate.map(item => item.date.toString());
          // this.lineChartData[0].data = this.dasboardReport.ChartDate.map(item => Number(item.total));
         }
        //  this.chartLineExit = this.dasboardReport.ChartDate.length;


        const mesesDesordenados = this.dasboardReport.ChartDate.map(item => {
          const fechaMoment = moment(item.mes.split("T")[0]);
          const mesNumero = fechaMoment.month();
          return fechaMoment.locale('es').format('MMMM');
        });
        // console.log(mesesDesordenados)
        // const mesesOrdenados = this.ordenarMeses(mesesDesordenados);
        // console.log(mesesOrdenados)
        this.lineChartData2[0].data = this.dasboardReport.ChartDate.map(item => item.total);
        this.chartLabels = mesesDesordenados;
        this.chartData[0].data = this.dasboardReport.ChartDate.map(item => item.total);
         
      

        // valores de la grafica lineal
        var originalArray = this.dasboardReport.ChartDate.map(item => item.total);
        const newArray = [];
        let sum = 0;
        for (let i = 0; i < originalArray.length; i++) {
          sum += +originalArray[i];
          newArray.push(sum);
        }

        this.chartData[1].data = newArray;


         this.doughnutChartLabels_one = this.dasboardReport.ChartCategory.map(item => item.categoria);
         this.doughnutChartData_one = this.dasboardReport.ChartCategory.map(item => +item.total);
         this.chartoneExit = this.doughnutChartData_one.length;

         this.doughnutChartLabels_two = this.dasboardReport.ChartZone.map(item => item.zona);
         this.doughnutChartData_two = this.dasboardReport.ChartZone.map(item => +item.total);
         this.charttwoExit = this.doughnutChartData_one.length;

         this.doughnutChartLabels_three = this.dasboardReport.ChartFuente.map(item => item.fuente);
         this.doughnutChartData_three = this.dasboardReport.ChartFuente.map(item => +item.total);
         this.chartthreeExit = this.doughnutChartData_one.length;


 
        this.isLoading = false;

        const colorsone = this.doughnutChartData_one.map(item => this.generarNuevoColor());

        const chartData = {
          labels: this.doughnutChartLabels_one,
          datasets: [{
            data: this.doughnutChartData_one,
            backgroundColor: colorsone,
            hoverBackgroundColor: colorsone
          }]
        };


        const colorstwo = this.doughnutChartData_one.map(item => this.generarNuevoColor());

        const chartDatatwo = {
          labels: this.doughnutChartLabels_two,
          datasets: [{
            data: this.doughnutChartData_two,
            backgroundColor: colorstwo,
            hoverBackgroundColor:colorstwo
          }]
        };

        const colorsthree = this.doughnutChartData_one.map(item => this.generarNuevoColor());
        const chartDatathree = {
          labels: this.doughnutChartLabels_three,
          datasets: [{
            data: this.doughnutChartData_three,
            backgroundColor: colorsthree,
            hoverBackgroundColor: colorsthree
          }]
        };
    
        const chartOptions = {

          legend: {
            display: false,
          },


        plugins: {
          tooltip: {
              enabled: false // Desactivar los tooltips
          }
         }

        };
    
      if(this.chartthreeExit != 0 && this.chartthreeExit != 0 && this.chartthreeExit != 0){


      this.chartone.data = chartData;
      this.chartone.options = chartOptions;
      this.chartone.plugins = this.doughnutChartPlugins_one;
      this.chartone.update();

      this.chartwo.data = chartDatatwo;
      this.chartwo.options = chartOptions;
      this.chartwo.plugins = this.doughnutChartPlugins_one;
      this.chartwo.update();


      this.charthree.data = chartDatathree;
      this.charthree.options = chartOptions;
      this.charthree.plugins = this.doughnutChartPlugins_one;
      this.charthree.update();




      }

 
     
    })
  }

  public ordenarMeses(meses: string[]): string[] {
    const ordenMeses = {
      enero: 1,
      febrero: 2,
      marzo: 3,
      abril: 4,
      mayo: 5,
      junio: 6,
      julio: 7,
      agosto: 8,
      septiembre: 9,
      octubre: 10,
      noviembre: 11,
      diciembre: 12
    };
    return meses.sort((a, b) => ordenMeses[a] - ordenMeses[b]);
  }

  

  ngAfterViewInit(): void {
    
    const ctx2 =  this.ctx.nativeElement.getContext('2d');
    const gradient = ctx2.createLinearGradient(0,100,0,500);
    gradient.addColorStop(0, '#79C4FC');   
    gradient.addColorStop(1, '#C3E4FE');
  
    this.lineChartColors = [
            
      { // dark grey
            
        backgroundColor: gradient,
        borderColor: '#0390FB',
      },
      { // red
        backgroundColor: gradient,
        borderColor: '#00E396',
      }
    ]


    const ctx3 =  this.ctx.nativeElement.getContext('2d');
    const gradient3 = ctx2.createLinearGradient(0,100,0,500);
    gradient.addColorStop(0, '#79C4FC');   
    gradient.addColorStop(1, '#C3E4FE');

     this.chartColors = [
      {
        backgroundColor: gradient,
        borderColor: 'rgba(255,0,0,0.8)',
        pointBackgroundColor: 'rgba(255,0,0,0.8)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(255,0,0,0.8)',
      },
      {
        backgroundColor: gradient,
        borderColor: gradient,
        pointBackgroundColor: 'red',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'red',
      },
      {
        borderColor: 'blue',
        borderWidth: 2,
        fill: false,
      },
    ];




    this.filterDashboard()






    const chartData = {
      labels: this.doughnutChartLabels_one,
      datasets: [{
        data: this.doughnutChartData_one,
      }]
    };
    
  this.ctxone = this.chartCanvas.nativeElement.getContext('2d');      
   this.chartone = new Chart(this.ctxone, {
      type: 'doughnut',
      data: chartData,
      options: this.chartOptions,
      plugins:this.doughnutChartPlugins_one
    }); 


    this.ctxtwo = this.chartCanvastwo.nativeElement.getContext('2d');
    this.chartwo = new Chart(this.ctxtwo, {
      type: 'doughnut',
      data: chartData,
      options: this.chartOptions,
      plugins:this.doughnutChartPlugins_one
    });


    this.ctxthree = this.chartCanvasthree.nativeElement.getContext('2d');
    this.charthree = new Chart(this.ctxthree, {
      type: 'doughnut',
      data: chartData,
      options: this.chartOptions,
      plugins:this.doughnutChartPlugins_one
    });




    this.ctxline = this.chartCanvasline.nativeElement.getContext('2d');
    this.charline= new Chart(this.ctxline, {
      data: {
          datasets: [{
              type: 'bar',
              label: 'Bar Dataset',
              data: [10, 20, 30, 40]
          }, {
              type: 'line',
              label: 'Line Dataset',
              data: [50, 50, 50, 50],
          }],
          labels: ['January', 'February', 'March', 'April']
      },
      options: this.chartOptions
  });
   


  }



  private createChart() {
    // Resto del código
    this.svg.selectAll('path')
      .data(this.data)
      .enter()
      .append('path')
      .on('mousemove', (event, d) => {
        this.tooltip.style.display = 'block';
        this.tooltip.style.left = (event.pageX + 10) + 'px';
        this.tooltip.style.top = (event.pageY + 10) + 'px';
        this.tooltip.innerHTML = `${d.data.label}: ${d.value}`;
      })
      .on('mouseout', () => {
        this.tooltip.style.display = 'none';
      });
  }



  clickDownload(){
      this.active_download = !this.active_download;
  }


  porcentajeResponse(zone){
    return  (( (Number(zone.total) * 100) / this.totalReportZone) * 10).toFixed(2);
}


  buildForm(){
    return {
     start_date:this.registerForm.controls["start_date"].value,
     end_date:this.registerForm.controls["end_date"].value,
     zone:this.registerForm.controls["zone"].value,
     category:this.registerForm.controls["category"].value,
    }
   }


   
  generarNuevoColor(){
    var simbolos, color;
    simbolos = "0123456789ABCDEF";
    color = "#";
  
    for(var i = 0; i < 6; i++){
      color = color + simbolos[Math.floor(Math.random() * 16)];
    }
  
    return color;
  }



 polarToCartesian(radius: number, angle: number) {
    const x = radius * Math.cos(angle);
    const y = radius * Math.sin(angle);
    return { x, y };
  }





  

}
