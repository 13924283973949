import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';

import { SettingRoutingModule } from './setting-routing.module';
import { ProfileComponent } from './profile/profile.component';
import { SharedModule } from '../../shared/shared.module';
import { ToastyModule } from 'ng2-toasty';
import { DataComponent } from './data/data.component';
import { BreadcrumbComponent } from '@/app/shared/components/breadcrumb/breadcrumb.component';

@NgModule({
  declarations: [ProfileComponent, DataComponent],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    SettingRoutingModule,
    SharedModule,
    ToastyModule
  ]
})
export class SettingModule { }
