import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-show-tramite',
  templateUrl: './show-tramite.component.html',
  styleUrls: ['./show-tramite.component.scss']
})
export class ShowTramiteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
