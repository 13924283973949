import { Component, OnInit,ViewChild,AfterViewInit,ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as chartData from '../../shared/data/chart';
import { doughnutData, pieData } from '../../shared/data/chart';
import { AuthenticationService } from '../services/auth/authentication.service';
import { DashboardService } from '../services/dashboard/dashboard.service';
import * as moment from 'moment';
import {  ChartOptions, ChartType } from 'chart.js';
import { Color, Label, MultiDataSet } from 'ng2-charts';
declare const Chart:any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})



export class DashboardComponent implements OnInit,AfterViewInit {
  public doughnutData = doughnutData;
  public pieData = pieData;
  public rol: string;


  
  doughnutChartColors = [
    {
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
    }
  ];
  
  
  
  doughnutChartType: ChartType = 'doughnut';
  doughnutChartLabels: Label[] = ['Porcentaje 1', 'Porcentaje 2', 'Porcentaje 3']
  doughnutChartData: MultiDataSet = [ [40, 30, 30]];
  doughnutChartLabelsZones: Label[] = ["porcentaje,porcentaje 2", "porcentaje 3"]
  doughnutChartDataZones: MultiDataSet = [[20,40,55]];


  chartOptions: ChartOptions = {

  }



  


  constructor(
    private authService: AuthenticationService,
    private dashboardService: DashboardService
  ) {
    Object.assign(this, { doughnutData, pieData })
    this.rol = this.authService.currentUserValue.roles;
  }

  private subs: Array<Subscription> = [];

  public areas: any;
  public zones: any;
  public graphs: any;
  public graphs2: any;
  public reports: any;
  public reportsGraph:any;
  public startDate: string = '';
  public endDate: string = '';
  public showClean = false;
  public showTable = true;
  public hideButton = false;
  public area: string;
  public arrLabels = [];
  public arrSeries = [];


  // doughnut 2
  public view = chartData.view;
  public doughnutChartColorScheme = chartData.doughnutChartcolorScheme;
  public doughnutChartShowLabels = chartData.doughnutChartShowLabels;
  public doughnutChartGradient = chartData.doughnutChartGradient;
  public doughnutChartTooltip = chartData.doughnutChartTooltip;

  public chart5 = chartData.chart5;

  // lineChart
  public lineChartData = chartData.lineChartData;
  public lineChartLabels = chartData.lineChartLabels;
  public lineChartOptions = chartData.lineChartOptions;
  public lineChartColors = chartData.lineChartColors;
  public lineChartLegend = chartData.lineChartLegend;
  public lineChartType = chartData.lineChartType;

  // lineChart
  public smallLineChartData = chartData.smallLineChartData;
  public smallLineChartLabels = chartData.smallLineChartLabels;
  public smallLineChartOptions = chartData.smallLineChartOptions;
  public smallLineChartColors = chartData.smallLineChartColors;
  public smallLineChartLegend = chartData.smallLineChartLegend;
  public smallLineChartType = chartData.smallLineChartType;

  // lineChart
  public smallLine2ChartData = chartData.smallLine2ChartData;
  public smallLine2ChartLabels = chartData.smallLine2ChartLabels;
  public smallLine2ChartOptions = chartData.smallLine2ChartOptions;
  public smallLine2ChartColors = chartData.smallLine2ChartColors;
  public smallLine2ChartLegend = chartData.smallLine2ChartLegend;
  public smallLine2ChartType = chartData.smallLine2ChartType;

  // lineChart
  public smallLine3ChartData = chartData.smallLine3ChartData;
  public smallLine3ChartLabels = chartData.smallLine3ChartLabels;
  public smallLine3ChartOptions = chartData.smallLine3ChartOptions;
  public smallLine3ChartColors = chartData.smallLine3ChartColors;
  public smallLine3ChartLegend = chartData.smallLine3ChartLegend;
  public smallLine3ChartType = chartData.smallLine3ChartType;

  // lineChart
  public smallLine4ChartData = chartData.smallLine4ChartData;
  public smallLine4ChartLabels = chartData.smallLine4ChartLabels;
  public smallLine4ChartOptions = chartData.smallLine4ChartOptions;
  public smallLine4ChartColors = chartData.smallLine4ChartColors;
  public smallLine4ChartLegend = chartData.smallLine4ChartLegend;
  public smallLine4ChartType = chartData.smallLine4ChartType;

  public chart3 = chartData.chart3;
  public age = chartData.age;

  optionsBar: any ={
    showLabel: true,
    //showArea: true,
      // height: '450',
      // low: 0,
    height: 303,
    seriesBarDistance: 20,
    colors: ["#ff8084", "#a5a5a5"],
    legend: { position: 'none' },
    width: '100%',
    backgroundColor: 'transparent',
    axisX: {
       showGrid: true,
      // labelInterpolationFnc: function (value) {
      //   return value[0];
      // }
    }
  }

  labels = [];
  aux = [];
  series = [];
  reportGraph:any;

  // events
  public chartClicked(e: any): void {
  }
  public chartHovered(e: any): void {
  }

  ngOnInit() {

    
    this.subs.push(
      this.dashboardService
      .getBestArea()
      .subscribe(data => {
        this.areas = data;
      }),
      this.dashboardService
      .getBestZone()
      .subscribe(data => {
        this.zones = data;

      }),
      this.dashboardService
      .getAreasGraph(this.startDate,this.endDate)
      .subscribe(data => {
        this.graphs = data;
        this.graphs2 = {
          labels: data.labels,
          series: data.series
        }

      }),
 
    );




  }




  ngAfterViewInit() {

  

  
  }

  


  clearInputs(): any {
    this.area = '';
    this.startDate = '';
    this.endDate = '';
    this.showClean = false;
    this.showTable = true;
    this.hideButton = false;
    this.reports = null;
    this.ngOnInit();
  }

  getResults(): any {
    let cont = 1;
    this.showClean = true;
    this.showTable = true;
    this.hideButton = true;

    if(this.rol === 'superadmin' || this.rol === 'admin' || this.rol === 'supervisor'){
      this.subs.push(
        this.dashboardService.getAreasGraph(this.startDate,this.endDate).subscribe(data => {
          this.graphs = data;
          this.graphs2 = {
            labels: data.labels,
            series: data.series
          }
        })
      );

    }else{
      this.subs.push(
        this.dashboardService.getDashboardKPI(this.startDate,this.endDate).subscribe(data => {
          this.reports = data;
          data.Graph.forEach(item =>{
            this.arrLabels.push(moment(item.labels).format('DD/MM/YYYY'))
            this.arrSeries.push(Number.parseInt(item.series))
          })
  
          let aux = {
            labels: this.arrLabels,
            series: [this.arrSeries]
          }
          this.reportsGraph = aux;  
        })
      );
    }

    
  }

}
