import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';
import { Location } from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import { ReportsModel } from '../../reports/reports.models';
import { ReportsService } from '../../services/reports/reports.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedService } from '../../services/shared.service';
import { MapsAPILoader } from '@agm/core';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})

export class ReportComponent implements OnInit, OnDestroy {
  @Output() folio: EventEmitter<any> = new EventEmitter();
  @Output() childToParent = new EventEmitter<String>();
  public values: any;
  public problems: any;
  public searchText: any = "";
  public resultSearch: any;
  searchForm: FormGroup;
  msgs1: any;
  ubicationAuto = false;
  ubicationManuality = false;
  bounds;
  isloadingDirection = false;
  public form: FormGroup;
  public submitted: boolean = false;
  public submittedAddress: boolean = false;
  public isLoading = false;
  public isSearching = false;
  public latitude: number;
  public longitude: number;
  public selectedAddress: PlaceResult;
  public completeAddress: string;
  public showMap: boolean = false;
  public showMessage: boolean = false;
  public zoom: number;
  public other: any;
  public isUbication = false;

  public requiredFileType: string;
  public ImgfileName = '';
  public responseImgFile: string;
  public previewImg: any;
  public viewLoaderImg = false;

  /*Dirección*/
  public ExtNumber: any;
  public Street: string;
  public IntNumber: string;
  public Neighborhood: string;
  public State: string;
  public City: string;
  public ZipCode: number;


  public prueba: any;

  personalDetails!: FormGroup;
  addressDetails!: FormGroup;
  addressForm: FormGroup;
  personal_step = false;
  address_step = false;
  step = 1;

  private subs: Array<Subscription> = [];
  private source = '';

  constructor(
    private fb: FormBuilder,
    private reportService: ReportsService,
    private router: Router,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private activedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private sharedService: SharedService,
    private mapsAPILoader: MapsAPILoader
  ) {

    this.searchForm = this.fb.group({
      searchText: ["", Validators.required],
    });

    this.source = this.activedRoute.snapshot.queryParamMap.get('utm');
    //console.log(this.source);
    this.mapsAPILoader.load().then(() => {
      this.bounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(51.130739, -0.868052), // SW
        new google.maps.LatLng(51.891257, 0.559417) // NE
      );
    })
  }

  ngOnInit(): void {



    this.personalDetails = this.fb.group({
      Name: ['', Validators.required],
      Phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      Email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      Subject: ['', Validators.required],
      Area: [''],
      Description: ['', Validators.required],
      Address: [''],
      Check: [false]
    });


    this.addressForm = this.fb.group({
      calle: ['', Validators.required],
      numExt: ['', Validators.required],
      calleOne: ['', Validators.required],
      calleTwo: ['', Validators.required],
      colonia: ['', Validators.required],
      state: ['Veracruz', Validators.required],
      city: ['Mexico', Validators.required],

    });




    this.reportService
      .getProblems()
      .subscribe(data => {
        this.problems = data;
      });
    this.reportService
      .getValuestoCatalog('Departments')
      .subscribe(data => {
        this.values = data;
      });
    this.zoom = 16;
  }

  ngOnDestroy(): void {
    let sub: Subscription;
    while (sub = this.subs.shift()) {
      sub.unsubscribe();
    }
  }

  onImgSelected(event) {
    const file: File = event.target.files[0];
    if (file.type == 'image/png' || file.type == 'image/jpeg') {
      this.viewLoaderImg = true;
      setTimeout(() => {
        if (file) {
          const formData = new FormData();
          formData.append('eventImg', file);
          this.subs.push(
            this.reportService.uploadImg(formData)
              .subscribe(resolve => {
                if (resolve.FileUrl !== null) {
                  this.responseImgFile = resolve.ImageUrl;
                  this.viewLoaderImg = false;
                  this.viewBase64(file).then(image => {
                    this.previewImg = image;
                  })
                  this.ImgfileName = file.name;
                }
              })
          );
        }
      }, 1800);
    } else {
      this.NoTypeImg()
    }
  }

  viewBase64 = async ($event: any) => new Promise((resolve, reject) => {
    try {
      const unsafeImg = window.URL.createObjectURL($event);
      const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = () => {
        resolve({
          base: reader.result
        });
      };
      reader.onerror = error => {
        resolve({
          base: null
        });
      };
    } catch (e) {
      return null;
    }
  })

  onAutocompleteSelected(result: PlaceResult) {
    this.showMessage = false;
    let address = result.address_components;

    try {
      this.isUbication = true;
      this.ExtNumber = Number(address.filter(item => item.types.includes("street_number")[0]?.short_name)) || "",
        this.Street = address.filter(item => item.types.includes("route"))[0]?.short_name || null,
        this.IntNumber = address.filter(item => item.types.includes("IntNumber"))[0]?.short_name || null,
        this.Neighborhood = address.filter(item => item.types.includes("sublocality"))[0]?.short_name || null,
        this.State = address.filter(item => item.types.includes("locality"))[0]?.short_name || null,
        this.City = address.filter(item => item.types.includes("country"))[0]?.short_name || null,
        this.ZipCode = Number(address.filter(item => item.types.includes("postal_code")[0]?.short_name)) || null
      this.completeAddress = result.formatted_address;
      this.showMap = false;


      this.addressForm.controls["calle"].setValue(this.Street);
      this.addressForm.controls["numExt"].setValue(this.ExtNumber);
      this.addressForm.controls["calleOne"].setValue("");
      this.addressForm.controls["calleTwo"].setValue("");
      this.addressForm.controls["colonia"].setValue(this.Neighborhood);
      this.addressForm.controls["city"].setValue(this.City);
      this.addressForm.controls["state"].setValue(this.State);
      this.searchText = [this.Neighborhood]

      this.msgs1 = [
        { severity: 'warn', summary: '', detail: 'Valida tu dirección' },

      ];
    } catch (error) {

    }
  }

  onLocationSelected(location: Location) {
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }


  search() {
    const text = this.searchForm.get("searchText").value.toUpperCase();
    console.log(this.searchText)
    this.reportService.searchColonia(this.searchText).subscribe(response => {

      this.resultSearch = response;


    })

  }

  setCurrentLocation() {
    this.isloadingDirection = true;

    if (this.ubicationAuto === false) {
      this.ubicationAuto = true;
      this.ubicationManuality = false;
    }


    if ('geolocation' in navigator) {
      this.isUbication = true;
      this.isSearching = true;
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.showMap = true;
        this.isSearching = false;
        this.showMessage = true;
        this.getAddress(this.latitude, this.longitude);
        console.log(this.latitude, this.longitude)
      });
    }
  }

  disableUbicacion() {

    this.addressForm.reset();
    this.addressForm.controls["city"].setValue("mexico")
    this.addressForm.controls["state"].setValue("veracruz")
    if (this.ubicationManuality === false) {
      this.ubicationAuto = false;
      this.ubicationManuality = true;
    }

    this.isUbication = true;
    this.showMap = false;
    this.searchText = "";
  }

  markerDragEnd($event: any) {
    const latitude = $event.latLng.lat();
    const longitude = $event.latLng.lng();
    this.getAddress(latitude, longitude);
  }

  getAddress(latitude, longitude) {

    const latlng = new google.maps.LatLng(latitude, longitude);
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'location': latlng }, (results, status) => {
      console.log("resultados", results)
      if (status !== google.maps.GeocoderStatus.OK) {
        alert(status);
      }
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (status == google.maps.GeocoderStatus.OK) {
        this.completeAddress = (results[0].formatted_address);
        let address = results;
        this.personalDetails.controls['Address'].setValue(this.completeAddress);

        // if(address.length < 6){
        //   this.handleAddressFinal();
        //   return;
        // }


        // "street_number" -> numero exterior
        // "route" ->  calle
        // "political" -> colonia
        // "locality" -> estado
        // "postal_code" -> codigo postal
        // "administrative_area_level_1"
        // "country" -> ciudad


        this.ExtNumber = Number(this.strackElement(address, "street_number")?.short_name) || "",
          this.Street = this.strackElement(address, "route").short_name,
          this.IntNumber = '',
          this.Neighborhood = this.strackElement(address, "political").short_name,
          this.State = this.strackElement(address, "locality").short_name,
          this.City = this.strackElement(address, "country").short_name
        this.ZipCode = Number(this.strackElement(address, "postal_code").short_name)


        this.addressForm.controls["calle"].setValue(this.Street);
        this.addressForm.controls["numExt"].setValue(this.ExtNumber);
        this.addressForm.controls["calleOne"].setValue("");
        this.addressForm.controls["calleTwo"].setValue("");
        this.addressForm.controls["colonia"].setValue(this.Neighborhood);
        this.addressForm.controls["city"].setValue(this.City);
        this.addressForm.controls["state"].setValue(this.State);
        this.searchText = [this.Neighborhood]

        this.msgs1 = [
          { severity: 'warn', summary: '', detail: 'Confirma la dirección sea correcta' },

        ];
        this.isloadingDirection = false;

      }
    });
  }

  public get formState() {
    return this.personalDetails.controls;
  }


  public strackElement(data, value) {
    let general;
    const object = data.find(({ address_components }) => {

      const result = address_components.find(item => {
        if (item.types[0] == value) {

          return item.short_name;
        }
      })

      if (result) {
        general = result;
        return result;
      }
    })

    return general
  }

  public onSubmit(): void {


    this.submitted = true;

    if (!!this.addressForm.controls["colonia"].value) {
    } else {

      this.addressForm.controls["colonia"].setValue(this.searchText);

    }


    if (this.personalDetails.invalid) {
      return;
    }


    this.addressForm.markAllAsTouched()
    if (this.addressForm.invalid) {
      console.log(this.addressForm, this.searchText)
      this.addressError();
      return;
      // const toastOptions: ToastOptions = {
      //   title: 'Alerta',
      //   msg: 'No has ingresado la dirección del reporte',
      //   showClose: true,
      //   timeout: 2000
      // };
      // this.toastyService.warning(toastOptions);

      return;
    } else {

    }

    if (this.personalDetails.controls['Check'].value === false) {
      this.terminosError();
      return;
    }

    this.createReport();

  }

  private createReport() {

    const report: ReportsModel = this.buildReport();
    this.isLoading = true;
    this.subs.push(
      this.reportService.createReport(report)
        .subscribe(resolve => {
          this.handleReportCreation();
          this.sharedService.setId(resolve.Folio);
          setTimeout(() => {
            this.router.navigateByUrl('/registro-exitoso');
          }, 1800);
        }, error => {
          this.handleError(error)
        })
    );
  }

  private buildReport(): ReportsModel {
    if (this.other.ShortDescription === 'Otro') {
      return {
        Subject: this.other.ShortDescription,
        Department: this.personalDetails.controls['Area'].value,
        Description: this.personalDetails.controls['Description'].value,
        Name: this.personalDetails.controls['Name'].value,
        PhoneNumber: this.personalDetails.controls['Phone'].value,
        Email: this.personalDetails.controls['Email'].value,
        ImgUrl: this.responseImgFile,
        Source: this.source,
        Address: {
          Street: this.addressForm.controls["calle"].value,
          ExtNumber: this.addressForm.controls["numExt"].value,
          IntNumber: this.IntNumber,
          Neighborhood: this.addressForm.controls["colonia"].value,
          Street1: this.addressForm.controls["calleOne"].value,
          Street2: this.addressForm.controls["calleTwo"].value,
          ZipCode: this.ZipCode,
          State: this.addressForm.controls["state"].value,
          City: this.addressForm.controls["city"].value,
          Location: {
            lat: this.latitude,
            lng: this.longitude
          }
        }
      }
    } else {
      return {
        Subject: this.other.ShortDescription,
        Department: this.other.Department,
        Description: this.personalDetails.controls['Description'].value,
        Name: this.personalDetails.controls['Name'].value,
        PhoneNumber: this.personalDetails.controls['Phone'].value,
        Email: this.personalDetails.controls['Email'].value,
        ImgUrl: this.responseImgFile,
        Source: this.source,
        Address: {
          Street: this.addressForm.controls["calle"].value,
          ExtNumber: this.addressForm.controls["numExt"].value,
          IntNumber: this.IntNumber,
          Neighborhood: this.addressForm.controls["colonia"].value,
          Street1: this.addressForm.controls["calleOne"].value,
          Street2: this.addressForm.controls["calleTwo"].value,
          ZipCode: this.ZipCode,
          State: this.addressForm.controls["state"].value,
          City: this.addressForm.controls["city"].value,

          Location: {
            lat: this.latitude,
            lng: this.longitude
          }
        }
      }
    }

  }

  private addressError() {
    const toastOptions: ToastOptions = {
      title: 'Alerta',
      msg: 'No has ingresado la dirección del reporte',
      showClose: true,
      timeout: 2000
    };
    this.toastyService.warning(toastOptions);
    return [];
  }

  private handleError(error: any) {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: error.error.message,
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions);
    return [];
  }

  private handleReportCreation() {
    const toastOptions: ToastOptions = {
      title: 'Reporte',
      msg: 'Reporte creado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  private handleAddressFinal() {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Por favor agrega la dirección correctamente',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions);
    return [];
  }

  private terminosError() {
    const toastOptions: ToastOptions = {
      title: 'Alerta',
      msg: 'Debes marcar la casilla para ser contactado por un agente',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.info(toastOptions);
    return [];
  }

  private NoTypeImg() {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes subir una imagen de tipo png o jpg',
      showClose: true,
      timeout: 1800
    };

    this.toastyService.error(toastOptions);
  }

  ubication() {

    var geocoder = new google.maps.Geocoder();
    var textSelectM = "Veracruz";
    var textSelectE = "Veracruz";
    var inputAddress = "Jb Flores" + ' ' + textSelectM + ' ' + textSelectE;
    let lat, lng;
    geocoder.geocode({
      "address": inputAddress
    }, async function (results, status) {
      if (status == google.maps.GeocoderStatus.OK) {
        let lat = results[0].geometry.location.lat();
        let lng = results[0].geometry.location.lng()
        console.log(lat)
        this.bounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(lat, lng) // SW

        );

        console.log(",,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,", this.bounds);

      }

    });

  }


  formaterAdress() {

    const city = this.addressForm.controls["city"].value || "Mexico";
    const state = this.addressForm.controls['state'].value || "Veracruz"
    const street = this.addressForm.controls["calle"].value || ""
    const numExt = this.addressForm.controls["numExt"].value || ""
    const calleone = this.addressForm.controls["calleOne"].value || ""
    const calletwo = this.addressForm.controls["calleTwo"].value || ""
    console.log("----0,0", calleone)

    if (city != "" && state != "" && street != "") {
      return {
        response: true,
        address: `${street} ${numExt}, ${calleone}, ${calletwo} ${this.searchText}  ${state}, ${city}`
      }
    } else {
      return {
        response: false,
        address: ""
      }

    }

  }


  findLatLang(mainMap) {


    const { address, response } = this.formaterAdress()

    if (response) {


      var geocoder = new google.maps.Geocoder();
      return new Promise(function (resolve, reject) {

        geocoder.geocode({ 'address': address }, function (results, status) {
          if (status === 'OK') {

            console.log("--------------------------->", results);
            resolve([results[0].geometry.location.lat(), results[0].geometry.location.lng()]);
          } else {
            reject(new Error('Couldnt\'t find the location ' + address));
          }
        })
      })
    }


  }







  async inyectMensaje() {
    var locations = [this.findLatLang(map)]

    const items = await Promise.all(locations)
      .then(function (returnVals) {
        // you should have return values here when
        // all promises have rsolved
        return returnVals

      })

    if (items[0] != undefined) {
      this.latitude = items[0][0];
      this.longitude = items[0][1];
    }


  }


  //  setData(){
  //   this.ubication(function(pos){
  //     console.log(pos.lat())
  //     console.log(this.latitude)
  //     this.latitude = pos.lat();
  //     this.longitude = pos.lng();
  //     console.log(this.latitude)
  //   });
  //  }


}
