import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserModel, AuthModel, UserCreateModel, EmailModel, PasswordModel } from '../../auth/auth.models';
import { environment } from 'src/environments/environment';
import { getHeaders } from '../../utils/headers.functions';
import { catchError } from 'rxjs/operators';

const BASE_URL: string = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  activateAccount(token: string) {
    return this.http.post<any>(`${BASE_URL}/auth/activate/${token}`, {});
  }
  public enviarDatosDelFormulario(tramite: any): Observable<any> {
    const formData = this.construirFormData(tramite); // Método para construir el objeto FormData

    return this.http.post<any>(`${BASE_URL}/miveracruz/forms`, formData)
      .pipe(
        catchError(error => {
          console.error('Error al enviar los datos del formulario:', error);
          return throwError('Error al enviar los datos del formulario');
        })
      );
  }

  private construirFormData(tramite: any): FormData {
    const formData = new FormData();

    formData.append('claveRegistro', tramite.claveRegistro);
    formData.append('nombreTramite', tramite.nombreTramite);
    formData.append('dependencia', tramite.dependencia);
    formData.append('documento', tramite.documento);

    // Propiedades de tipoTramite
    formData.append('tipoTramite.obligacion', tramite.tipoTramite.obligacion ? 'true' : 'false');
    formData.append('tipoTramite.conservacion', tramite.tipoTramite.conservacion ? 'true' : 'false');

    // Propiedades de solicitante
    formData.append('solicitante.fisicas', tramite.solicitante.fisicas ? 'true' : 'false');
    formData.append('solicitante.morales', tramite.solicitante.morales ? 'true' : 'false');

    formData.append('casosSolicitud', tramite.casosSolicitud);
    formData.append('derechoUsuario', tramite.derechoUsuario);
    formData.append('formatoAutorizado', tramite.formatoAutorizado);
    formData.append('descripcion', tramite.descripcion);
    formData.append('efectosEliminacion', tramite.efectosEliminacion);
    formData.append('areaResponsable', tramite.areaResponsable);
    formData.append('oficina', tramite.oficina);
    formData.append('ubicacionOficina', tramite.ubicacionOficina);
    formData.append('telefonoOficina', tramite.telefonoOficina);
    formData.append('extensionOficina', tramite.extensionOficina);
    formData.append('horarioAtencionInicio', tramite.horarioAtencionInicio);
    formData.append('horarioAtencionFin', tramite.horarioAtencionFin);
    formData.append('tiempoRespuestaVigencia', tramite.tiempoRespuestaVigencia.toString());
    formData.append('tiempoRespuestaVigenciaUnidad', tramite.tiempoRespuestaVigenciaUnidad);
    formData.append('vigenciaDocumento', tramite.vigenciaDocumento);
    formData.append('duracionVigencia', tramite.duracionVigencia ? tramite.duracionVigencia.toString() : '');
    formData.append('duracionVigenciaUnidad', tramite.duracionVigenciaUnidad);

    return formData;
  }

  public sendFormData(formData: any): Observable<any> {
    return this.http.post<any>(`${BASE_URL}/miveracruz/forms`, formData);
  }

  private currentUserSubject: BehaviorSubject<AuthModel>;

  public currentUser: Observable<AuthModel>;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.currentUserSubject = new BehaviorSubject<AuthModel>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): AuthModel {
    return this.currentUserSubject.value;
  }

  public login(user: UserModel): Observable<AuthModel> {
    return this.http.post<AuthModel>(`${BASE_URL}/auth/signin`, user, {
      // headers: {
      //   'Type-Client': accessType
      // }
    })
      .pipe(map(currentUser => this.setUser(currentUser)));
  }

  public forgotPassword(email: EmailModel): Observable<any> {
    return this.http.post<any>(`${BASE_URL}/auth/changePassword`, email);
  }

  public resetPassword(password: PasswordModel, resetToken: string): Observable<any> {
    return this.http.post<any>(`${BASE_URL}/auth/updatePassword/${resetToken}`, password);
  }

  public validate(uuid: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/activate-business/${uuid}`, { headers });
  }

  public logout(): void {
    localStorage.removeItem('currentUser');
    localStorage.clear();
    this.currentUserSubject.next(null);
  }

  public handleUnauthorizedError(err: any) {
    if (err.error && err.error.status === 401) {
      this.logout();
      this.router.navigate(['/ciudadano/auth/login']);
    }

    return throwError('Session Expired');
  }

  public createUser(user: UserModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/auth/signup`, user, { headers });
  }

  public getUser(id: (number | string)): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/user/${id}`);
  }

  public getUserV2(id: (number | string)): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/v2/user/${id}`);
  }

  public async getUserV2Async(id: (number | string)): Promise<any> {
    const headers: HttpHeaders = getHeaders();
    return new Promise((resolve, reject) => {
      this.http.get<any>(`${BASE_URL}/v2/user/${id}`).subscribe(
        (response) => resolve(response),
        (error) => reject(error)
      );
    });
  }

  public createUserPerson(user: UserCreateModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/createuser`, user, { headers });
  }

  public editUserPerson(user: UserCreateModel, id: (number | string)): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/user/${id}`, user, { headers });
  }

  public getStore(id: (number | string)): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/business/${id}`);
  }

  public getCertificatesStore(id: (number | string)): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/certificates/${id}`);
  }

  public updateUserStore(id: number, user: any): Observable<any> {
    // console.log(id, user);
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/business/${id}`, user, { headers });
  }

  public validateFiles(files: any, id: (number | string)): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/business-validate/${id}`, files, { headers });
  }

  private setUser(user: AuthModel) {
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
    return user;
  }

  public changePassword(user: UserModel): Observable<any> {
    const buildUser = this.change(user);
    const updatedUser = this.addBackendStructure(buildUser);
    const headers: HttpHeaders = getHeaders();
    return this.http.post<Observable<any>>(`${BASE_URL}/auth/change-password`, updatedUser, { headers });
  }

  public mapRequiredValues(user: any): any {
    const userInfo: any = {};

    userInfo.firstName = user.firstName;
    userInfo.lastName = user.lastName;
    userInfo.email = user.email;
    userInfo.phoneNumber = user.phoneNumber;

    return userInfo;
  }

  public mapRequiredValuesBusiness(user: any): any {
    const userInfo: any = {};

    userInfo.Name = user.Name;
    userInfo.TaxRegime = user.TaxRegime;
    userInfo.TaxId = user.TaxId;
    userInfo.OfficePhone = user.OfficePhone;
    userInfo.MobilePhone = user.MobilePhone;
    userInfo.LocalType = user.LocalType;
    userInfo.ContactEmail = user.ContactEmail;
    userInfo.Address = user.Address;
    userInfo.Description = user.Description;

    return userInfo;
  }

  public mapRequiredValuesForPassword(user: any): any {
    const userInfo: any = {};
    userInfo.email = user.email;
    return userInfo;
  }

  public mapRequiredValuesForAngel(user: any): any {
    const userInfo: any = {};
    userInfo.ContactName = user.ContactName;
    userInfo.ContactPhoneNumber = user.ContactPhoneNumber;

    return userInfo;
  }

  private change(user: any): any {
    const changePassword = {
      email: user.email,
      password: user.password
    };
    return changePassword;
  }

  private addBackendStructure(user: any) {
    return {
      email: user.email,
      password: user.password
    };
  }
}
