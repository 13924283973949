<app-header-two></app-header-two>
<div class="bg-white">
    <img src="../../../../assets/images/miveracruz/banner-tramites.png" alt="Banner tramites">
    <div class="container bg-white pb-3">
        <div class="row">
            <app-breadcrumb-two></app-breadcrumb-two>
        </div>
        <div class="row">
            <div class="col-12 col-md-10">
                <h1 class="tramite-title text-primary">Licencia por Movimiento de Tierra (Fraccionamientos) <br>
                    (T-0PDU-FR-13)</h1>
            </div>
            <!-- <div class="col-12 col-md-2">
                <div>
                    <div class="table-responsive mt-5">
                        <table datatable class="table table-lg table-hover row-border hover">
                            <thead>
                                <tr>
                                    <th>TRÁMITE</th>
                                    <th>DEPENDENCIA</th>
                                    <th>FOLIO</th>
                                    <th>USUARIO</th>
                                    <th>FECHA DE CREACIÓN</th>
                                    <th>ESTATUS</th>
                                    <th>VER DETALLE</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of dataFromAPI">
                                    <td>{{ item.tramite }}</td>
                                    <td>{{ item.dependencia }}</td>
                                    <td>{{ item.folio }}</td>
                                    <td>{{ item.usuario }}</td>
                                    <td>{{ item.fechaCreacion }}</td>
                                    <td>{{ item.estatus }}</td>
                                    <td class="action-cell">
                                        <a [routerLink]="['/catalogs/ver-tramite', item.id]">Ver detalle</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <ngb-pagination (pageChange)="loadPage($event)" [collectionSize]="total" [(page)]="page"
                            [maxSize]="10" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
                    </div>
                </div>
            </div> -->
            <div class="col-12">
                <div class="row">
                    <div class="chip chip-tramite">Tramite</div>
                    <div class="chip chip-presencial">Modalidad presencial</div>
                </div>
            </div>
            <div class="col-12 px-0">
                <div class="row mt-3 mb-2 d-flex justify-content-between">
                    <div class="col-2 px-0">
                        <div class="header-description">
                            <h3 class="text-white text-center">Dependencia <br> que autoriza</h3>
                        </div>
                        <div class="body-description bg-light">
                            <span class="text-primary text-center">
                                Dirección de Obras Públicas y Desarrollo Urbano
                            </span>
                        </div>
                    </div>
                    <div class="col-2 px-0">
                        <div class="header-description">
                            <h3 class="text-white text-center">Tipo de <br> resolución</h3>
                        </div>
                        <div class="body-description bg-light">
                            <span class="text-primary text-center">
                                Licencia por movimiento de tierra
                            </span>
                        </div>
                    </div>
                    <div class="col-2 px-0">
                        <div class="header-description">
                            <h3 class="text-white text-center">Vigencia</h3>
                        </div>
                        <div class="body-description bg-light">
                            <span class="text-primary text-center">
                                365 día(s)
                            </span>
                        </div>
                    </div>
                    <div class="col-2 px-0">
                        <div class="header-description">
                            <h3 class="text-white text-center">Costo</h3>
                        </div>
                        <div class="body-description bg-light">
                            <span class="text-primary text-center">
                                0.85 UMA + 15% adicional
                            </span>
                        </div>
                    </div>
                    <div class="col-2 px-0">
                        <div class="header-description">
                            <h3 class="text-white text-center">Tiempo de respuesta</h3>
                        </div>
                        <div class="body-description bg-light">
                            <span class="text-primary text-center">
                                8 día(s) hábile(s)
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-8">
                    <div class="row">
                        <div class="col-12 bg-light p-3">
                            <h4 class="text-primary mb-0 font-weight-bold">Descripción del trámite</h4>
                            <span class="text-primary">Es el documento oficial que expide el ayuntamiento a través de la
                                dirección de
                                desarrollo urbano para autorizar la excavación o relleno mediante el cual se
                                autoriza: excavación: es el movimiento de tierras realizado a cielo abierto, y cuyo
                                objeto consiste en alcanzar el plano de arranque de la edificación, es decir las
                                cimentaciones; o en su caso el alojamiento de instalaciones. para efectos de este
                                concepto no será considerado como "excavación" la extracción de material sano
                                como tepetate, arena piedra etc.; este tipo de acciones será considerado como
                                explotación de banco de material. relleno: comprende la ejecución de trabajos
                                tendientes a rellenar zanjas (como es el caso de colocación de tuberías,
                                cimentaciones enterradas, etc.)</span>
                        </div>
                    </div>
                    <div class="container-fluid">
                        <div class="row my-2">
                            <div class="col-12 col-md-6 bg-light p-3 text-center">
                                <h4 class="text-primary mb-0 font-weight-bold">¿Quién puede solicitarlo?</h4>
                                <span class="text-primary">Personas físicas</span>
                            </div>
                            <div class="col-12 col-md-6 bg-light p-3 text-center">
                                <h4 class="text-primary mb-0 font-weight-bold">Forma de pago</h4>
                                <span class="text-primary">Efectivo, tarjeta bancaria y cheque</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12 px-0">
                                <button class="download">
                                    Descargar ficha completa ->
                                </button>
                            </div>
                        </div>
                        <div class="row my-2">
                            <div class="p-3 col-12 bg-light">
                                <h4 class="text-primary text-center font-weight-bold">
                                    Documentos para descarga
                                </h4>
                            </div>
                        </div>
                        <div class="row my-2">
                            <div class="p-3 col-12 bg-light text-center">
                                <h4 class="text-primary font-weight-bold mb-0">
                                    ¿Tienes alguna duda?
                                </h4>
                                <span class="text-primary">
                                    08:00 am a 02:00 pm <br>
                                    Teléfonos: (229) 200-2250 ext.1020
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 bg-light p-3">
                    <h2 style="text-transform: initial;" class="font-weight-bold text-primary mb-0">Requisitos</h2>
                    <table class="w-100 table-description">
                        <thead>
                            <tr>
                                <th></th>
                                <th class="text-primary">Persona física</th>
                                <th class="text-primary">Persona Moral</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <b class="text-primary">Formato Multitrámite</b> <br>
                                    <span class="text-primary">Con croquis de localización del predio y firmado por
                                        propietario y/o representante legal</span>
                                </td>
                                <td>
                                    Ok
                                </td>
                                <td>
                                    Ok
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b class="text-primary">Recibo de pago predial</b> <br>
                                    <p class="text-primary mb-0">Para comprobar el pago de derecho de piso al corriente.
                                        <span class="text-secondary">Se requiere 1 copia.</span>
                                    </p>
                                </td>
                                <td>
                                    Ok
                                </td>
                                <td>
                                    Ok
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b class="text-primary">Alineamiento y número oficial</b> <br>
                                    <p class="text-primary mb-0"><span class="text-secondary">Se
                                            requiere 1 copia.</span> </p>
                                </td>
                                <td>
                                    Ok
                                </td>
                                <td>
                                    Ok
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b class="text-primary">Deslinde</b> <br>
                                    <p class="text-primary mb-0">En caso de obra nueva y en caso de pie de casa. <span
                                            class="text-secondary">Se
                                            requiere 1 copia.</span> </p>
                                </td>
                                <td>
                                    Ok
                                </td>
                                <td>
                                    Ok
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b class="text-primary">Escritura</b> <br>
                                    <p class="text-primary mb-0">Públicas inscritas en el Registro Público de la
                                        Propiedad.
                                        <span class="text-secondary">Se
                                            requiere 1 copia.</span>
                                    </p>
                                </td>
                                <td>
                                    Ok
                                </td>
                                <td>
                                    Ok
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b class="text-primary">Fotografías</b> <br>
                                    <p class="text-primary mb-0">Del área a intervenir, pegadas o impresas en hojas
                                        blancas
                                        tamaño carta. <span class="text-secondary">Se
                                            requiere 1 copia.</span> </p>
                                </td>
                                <td>
                                    Ok
                                </td>
                                <td>
                                    Ok
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b class="text-primary">Documento</b> <br>
                                    <p class="text-primary mb-0">Que contenga la memoria y programa del proceso
                                        respectivo
                                    </p>
                                </td>
                                <td>
                                    Ok
                                </td>
                                <td>
                                    Ok
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b class="text-primary">Manifestación de impacto ambiental</b> <br>
                                    <p class="text-primary mb-0">Municipal o Estatal (positivo y autorizado). <span
                                            class="text-secondary">Se
                                            requiere 1 copia.</span></p>
                                </td>
                                <td>
                                    Ok
                                </td>
                                <td>
                                    Ok
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b class="text-primary">Licencia de uso de suelo</b> <br>
                                    <p class="text-primary mb-0"><span class="text-secondary">Se
                                            requiere 1 copia.</span> </p>
                                </td>
                                <td>
                                    Ok
                                </td>
                                <td>
                                    Ok
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b class="text-primary">Dictamen</b> <br>
                                    <p class="text-primary mb-0">De Protección Civil. <span class="text-secondary">Se
                                            requiere 1 copia.</span> </p>
                                </td>
                                <td>
                                    Ok
                                </td>
                                <td>
                                    Ok
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b class="text-primary">Documento</b> <br>
                                    <p class="text-primary mb-0">Que acredite contar con la validación de un Perito
                                        Responsable y/o Corresponsable de Obra que estén dados de alta en el Municipio
                                        de
                                        Veracruz.</p>
                                </td>
                                <td>
                                    Ok
                                </td>
                                <td>
                                    Ok
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b class="text-primary">Lona</b> <br>
                                    <span class="text-primary">En el que se identifique:</span>
                                    <ul class="text-primary">
                                        <li>Número de licencia de construcción</li>
                                        <li>Número del Registro Municipal del Perito Responsable de Obra y en su caso
                                            del
                                            (los) Perito(s) Corresponsable(s) de Obra.</li>
                                        <li>Tipo de obra</li>
                                        <li>Uso de suelo</li>
                                        <li>Niveles autorizados</li>
                                        <li>Superficie autorizada</li>
                                        <li>Ubicación indicando calle, número oficial y colonia</li>
                                    </ul>
                                </td>
                                <td>
                                    Ok
                                </td>
                                <td>
                                    Ok
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b class="text-primary">Bitácora de obra</b> <br>
                                    <p class="text-primary mb-0"><span class="text-secondary">Se
                                            requiere 1 copia.</span> </p>
                                </td>
                                <td>
                                    Ok
                                </td>
                                <td>
                                    Ok
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="container-fluid my-2">
                        <div class="row indications">
                            <div class="col-12 col-md-2">

                            </div>
                            <div class="col-12 col-md-10">
                                <ul>
                                    <li class="text-primary"><span>
                                            <b>El número de copias solo aplica</b> para trámites que se realizan de
                                            <b>manera
                                                presencial.</b>
                                        </span></li>
                                    <li class="text-primary"><span>
                                            Para <b>trámites en línea</b>, son requeridos <b>solo los documentos
                                                originales.</b>
                                        </span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-12 p-3">
                    <table class="w-100 table-description-2">
                        <tbody>
                            <tr>
                                <td>
                                    <b class="text-primary">Fundamento jurídico</b> <br>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p class="text-primary mb-0">
                                        Artículo 78 fracción I del Reglamento de Desarrollo Urbano, Fraccionamiento y
                                        Vivienda para el Municipio de Veracruz.
                                        Artículos 56
                                        fracción V, 59 fracción V, 165, 166, 167 y 168, del Reglamento de Construcciones
                                        Públicas y Privadas para el Municipio
                                        de Veracruz.
                                        Artículo 206 fracción III inciso o, del Código Hacendario para el Municipio de
                                        Veracruz.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b class="text-primary">Criterios de resolución</b> <br>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p class="text-primary mb-0">Dicha autorización se otorgará a solicitud del
                                        interesado,
                                        con fundamento en estudios geotécnicos que garanticen la
                                        protección de
                                        vías, instalaciones de servicios públicos, predios aledaños y construcciones
                                        vecinas.
                                        EI Ayuntamiento previa solicitud, señalará para cada predio que tenga frente a
                                        la
                                        vía pública un sólo número oficial,
                                        que
                                        corresponderá a la entrada del mismo. EI pago de derechos es de acuerdo a 10
                                        siguiente SECTOR VECES UMA DESGLOSE TOTAL
                                        UNIDAD Movimiento de Tierra Único 0.07 UMA $7.60 M2
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b class="text-primary">Fecha de última actualización</b> <br>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p class="text-primary mb-0">2023-04-27 19:06:10</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-12 p-3 text-center mb-0">
                    <span class="text-primary font-weight-bold">
                        El inicio de este trámite no garantiza una respuesta favorable y depende del cumplimiento de los
                        requisitos solicitados.
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>