import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '../../../shared/service/api.service' ;

@Component({
  selector: 'app-bandejatramites',
  templateUrl: './bandejatramites.component.html',
  styleUrls: ['./bandejatramites.component.scss']
})
export class BandejatramitesComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['tramite', 'folio', 'ciudadano', 'fechaCreacion', 'estatus', 'verDetalle'];
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource<any>();
  initialData: any[] = []; // Datos iniciales antes de cualquier filtrado
  originalDataMap: { [key: string]: any[] } = {}; // Guardar datos originales por categoría

  estatusOptions: { value: string; label: string }[] = [
    { value: 'Todos', label: 'Todos' },
    { value: 'Sin iniciar', label: 'Sin iniciar' },
    { value: 'Prevención', label: 'Prevención' },
    { value: 'Prevención respondida', label: 'Prevención Respondida' },
    { value: 'Caducado', label: 'Caducado' },
    { value: 'Pendiente de pago', label: 'Pendiente de pago' },
    { value: 'Pagado', label: 'Pagado' },
    { value: 'En revisión', label: 'En revisión' },
    { value: 'Rechazado', label: 'Rechazado' },
    { value: 'Aceptado', label: 'Aceptado' }
  ];
  selectedfase: string | null = null;
  selectedEstatus: string = '';
  selectedArea: number | null = null;
  selectedTramite: string | null = null;

  total: number = 0;
  abiertos: number = 0;
  cerrados: number = 0;
  cumplimiento: number = 0;
  page: number = 1;
  pageSize: number = 10;
  showSearchMenu: boolean = false;
  allDataFromAPI: any[] = [];
  dataFromAPI: any[] = [];

  fechaInicio: string = '';
  fechaFin: string = '';
  searchValue: string = '';

  areas: any[] = [];
  tramites: any[] = [];

  filteredDataByCategory: { [key: string]: any[] } = {};
  selectedTabIndex: number = 0;

  revisionCount: number = 0;
  pagoCount: number = 0;
  autorizacionCount: number = 0;

  selectedFilters: { type: string, value: string }[] = [];

  constructor(private http: HttpClient, private cdr: ChangeDetectorRef, private apiService: ApiService) {
    this.selectedEstatus = ''; // Asegurarse de que sea cadena vacía
  }

  ngOnInit(): void {
    this.loadData();
    this.loadAreas();
  }

  loadData() {
    this.apiService.getSolicitudes().subscribe(
        data => {
          this.allDataFromAPI = data;
          console.log(data);
          this.initialData = [...this.allDataFromAPI]; // Hacer una copia de los datos iniciales
          this.allDataFromAPI.sort((a, b) => new Date(b.CitizenRequest.created_at).getTime() - new Date(a.CitizenRequest.created_at).getTime());
          this.total = this.allDataFromAPI.length;

          console.log(this.allDataFromAPI);

          this.dataSource = new MatTableDataSource(this.allDataFromAPI);
          this.dataSource.sort = this.sort;
          this.applyFilters();  // Aplicar los filtros inmediatamente después de cargar los datos
          this.updatePageData();
          console.log('Datos cargados correctamente:', this.allDataFromAPI);
        },
        error => {
          console.error('Error al cargar los datos:', error.message, error.status, error.statusText);
          console.error('Detalles del error:', error);
        }
      );
  }

  loadAreas() {
    //this.http.get<any>('http://45.85.146.201:3000/api/catalogs-data/Areas')
    this.http.get<any>('https://veraxapi.stackcode.io/api/catalogs-data/Areas')
      .subscribe(
        data => {
          this.areas = data.list.map(item => ({
            id: item.id,
            name: item.ValueAlpha
          }));
          console.log('Áreas cargadas correctamente:', this.areas);
        },
        error => {
          console.error('Error al cargar las áreas:', error);
        }
      );
  }  

  loadTramitesByArea(areaId: number) {
    this.tramites = [];
    this.selectedTramite = null;

    if (areaId) {
      this.apiService.getTramitesByArea(areaId).subscribe(
        data => {
          console.log('Datos recibidos:', data); // Verificar los datos recibidos
          this.tramites = data.map(item => {
            if (item.Form && item.Form.FormsCommon && item.Form.FormsCommon.Name) {
              return item.Form.FormsCommon.Name;
            } else {
              console.warn('Estructura de datos inesperada:', item);
              return 'Nombre no disponible';
            }
          });
        },
        error => {
          console.error('Error al cargar los trámites por área:', error);
        }
      );
    } else {
      this.applyFilters();
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  updatePageData() {
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    const paginatedData = this.originalDataMap[this.selectedfase || 'Todos'].slice(startIndex, endIndex);
    this.dataSource.data = paginatedData;
    console.log('Pagina:', this.page);
    console.log('Datos Paginados:', this.dataFromAPI);
  }

  loadPage(page: number) {
    this.page = page;
    this.applyFilters();
  }

  toggleSearchMenu() {
    this.showSearchMenu = !this.showSearchMenu;
    const cardElement = document.querySelector('.card') as HTMLElement;
    if (cardElement) {
      if (this.showSearchMenu) {
        cardElement.classList.add('show-menu');
      } else {
        cardElement.classList.remove('show-menu');
      }
    }
  }

  formatDate(dateString: string | undefined): string {
    if (!dateString) {
      return 'Fecha no disponible';
    }
    const dateParts = dateString.split('T')[0].split('-');
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];
    return `${day}/${month}/${year}`;
  }

  applyFilters() {
    console.log('Aplicando filtros...');

    const fechaInicioObj = this.fechaInicio ? new Date(this.fechaInicio) : null;
    const fechaFinObj = this.fechaFin ? new Date(this.fechaFin) : null;
    const filterValue = this.searchValue.trim().toLowerCase();
    console.log('Valor de búsqueda:', filterValue);

    let filteredData = this.allDataFromAPI;
    console.log('Datos antes del filtrado:', filteredData);

    this.selectedFilters = [];

    if (fechaInicioObj && fechaFinObj) {
      filteredData = filteredData.filter(item => {
        const fechaSolicitud = new Date(item.CitizenRequest.created_at);
        return fechaSolicitud >= fechaInicioObj && fechaSolicitud <= fechaFinObj;
      });
      this.addFilter('Fecha', `Desde ${this.fechaInicio} hasta ${this.fechaFin}`);
    }

    if (filterValue) {
      filteredData = filteredData.filter(item => {
        return (
          item.Applicant.Name.toLowerCase().includes(filterValue) ||
          item.CitizenRequest.Folio.toLowerCase().includes(filterValue) ||
          item.Form.FormsCommon.Name.toLowerCase().includes(filterValue) ||
          item.Applicant.CURP.toLowerCase().includes(filterValue)
        );
      });
      this.addFilter('Búsqueda', filterValue);
    }

    if (this.selectedArea) {
      const areaName = this.areas.find(area => area.id === this.selectedArea)?.name || '';
      console.log('Área seleccionada:', areaName);
      filteredData = filteredData.filter(item => item.Form.Areas?.Name === areaName);
      this.addFilter('Área', areaName);
    }

    if (this.selectedTramite) {
      filteredData = filteredData.filter(item => item.Form.FormsCommon.Name === this.selectedTramite);
      this.addFilter('Trámite', this.selectedTramite);
    }

    // Filtrar por estatus
    if (this.selectedEstatus && this.selectedEstatus !== 'Todos') {
      console.log('Estatus seleccionado:', this.selectedEstatus);
      filteredData = filteredData.filter(item => {
        const firstPhase = item.Phases?.Phase[0];
        return firstPhase && firstPhase.Status === this.selectedEstatus;
      });
      this.addFilter('Estatus', this.selectedEstatus);
    }

    if (this.selectedfase && this.selectedfase !== 'Todos') {
      filteredData = this.filterDataBySelectedCategory(filteredData);
    }

    this.originalDataMap[this.selectedfase || 'Todos'] = filteredData;

    this.dataSource.data = filteredData;
    this.total = filteredData.length;

    console.log('Datos después del filtrado:', filteredData);
    this.sortData(this.sort);
    this.updatePageData();
    this.updateCounters();
  }

  addFilter(type: string, value: string) {
    if (!this.selectedFilters.some(filter => filter.type === type && filter.value === value)) {
      this.selectedFilters.push({ type, value });
    }
  }  

  removeFilter(type: string) {
    switch (type) {
      case 'Estatus':
        this.selectedEstatus = '';
        break;
      case 'Área':
        this.selectedArea = null;
        break;
      case 'Trámite':
        this.selectedTramite = null;
        break;
      case 'Búsqueda':
        this.searchValue = '';
        break;
      case 'Fecha':
        this.fechaInicio = '';
        this.fechaFin = '';
        break;
      default:
        break;
    }
    this.selectedFilters = this.selectedFilters.filter(filter => filter.type !== type);
    this.applyFilters();
  }   

  clearFilters() {
    this.fechaInicio = '';
    this.fechaFin = '';
    this.searchValue = '';
    this.selectedEstatus = ''; // Asegúrate de que se asigne una cadena vacía
    this.selectedArea = null;
    this.selectedTramite = null;
    this.selectedFilters = []; // Limpiar los filtros seleccionados
    this.applyFilters();
  }

  onSearch() {
    // When search button is clicked, add the filter and apply
    this.applyFilters();
  }
  
  onAreaChange(areaId: number) {
    this.selectedArea = areaId;
    this.loadTramitesByArea(areaId);
    this.applyFilters();
  }
  
  onEstatusChange() {
    console.log('Estatus cambiado:', this.selectedEstatus);
    this.applyFilters();
  }  

  getFilterLabel(type: string): string {
    switch (type) {
      case 'Fecha':
        return 'Fecha:';
      case 'Búsqueda':
        return 'Búsqueda:';
      case 'Área':
        return 'Área:';
      case 'Trámite':
        return 'Trámite:';
      case 'Estatus':
        return 'Estatus:';
      default:
        return '';
    }
  }

  filterDataBySelectedCategory(data: any[]) {
    switch (this.selectedfase) {
      case 'Revisión':
        return this.applyRevisionFilters(data);
      case 'Pago':
        return this.applyPagoFilters(data);
      case 'Autorización':
        return this.applyAutorizacionFilters(data);
      default:
        return data;
    }
  }
  
  compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  get totalPages(): number {
    return Math.ceil(this.dataSource.data.length / this.pageSize);
  }

  sortData(sort: Sort) {
    const data = this.originalDataMap[this.selectedfase || 'Todos'].slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }
  
    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'tramite':
          return this.compare(a.Form.FormsCommon.Name, b.Form.FormsCommon.Name, isAsc);
        case 'folio':
          return this.compare(a.CitizenRequest.Folio, b.CitizenRequest.Folio, isAsc);
        case 'ciudadano':
          return this.compare(a.Applicant.Name, b.Applicant.Name, isAsc);
        case 'fechaCreacion':
          return this.compare(a.CitizenRequest.created_at, b.CitizenRequest.created_at, isAsc);
        default:
          return 0;
      }
    });
  }

  onTabChange(index: number) {
    this.selectedTabIndex = index;

    switch (index) {
      case 0:
        this.selectedfase = 'Todos';
        break;
      case 1:
        this.selectedfase = 'Revisión';
        break;
      case 2:
        this.selectedfase = 'Pago';
        break;
      case 3:
        this.selectedfase = 'Autorización';
        break;
      default:
        this.selectedfase = 'Todos';
        break;
    }
    console.log('Cambio de pestaña:', this.selectedfase);
    this.applyFilters();
  }

  setStatusFilter(label: string) {
    this.selectedfase = label;
  
    switch (label) {
      case 'Revisión':
        this.estatusOptions = [
          { value: 'Todos', label: 'Todos' },
          { value: 'Sin iniciar', label: 'Sin iniciar' },
          { value: 'Prevención', label: 'Prevención' },
          { value: 'Prevención respondida', label: 'Prevención Respondida' },
          { value: 'En revisión', label: 'En revisión' },
          { value: 'Caducado', label: 'Caducado' }
        ];
        break;
      case 'Pago':
        this.estatusOptions = [
          { value: 'Todos', label: 'Todos' },
          { value: 'Pendiente de pago', label: 'Pendiente de pago' },
          { value: 'Pagado', label: 'Pagado' }
        ];
        break;
      case 'Autorización':
        this.estatusOptions = [
          { value: 'Todos', label: 'Todos' },
          { value: 'Aceptado', label: 'Aceptado' },
          { value: 'Rechazado', label: 'Rechazado' }
        ];
        break;
      default:
        this.estatusOptions = [
          { value: 'Todos', label: 'Todos' },
          { value: 'Sin iniciar', label: 'Sin iniciar' },
          { value: 'Prevención', label: 'Prevención' },
          { value: 'Prevención respondida', label: 'Prevención Respondida' },
          { value: 'Caducado', label: 'Caducado' },
          { value: 'Pendiente de pago', label: 'Pendiente de pago' },
          { value: 'Pagado', label: 'Pagado' },
          { value: 'En revisión', label: 'En revisión' },
          { value: 'Rechazado', label: 'Rechazado' },
          { value: 'Aceptado', label: 'Aceptado' }
        ];
        break;
    }
    this.applyFilters(); // Asegúrate de aplicar los filtros después de actualizar los estatus
  }

  contarSolicitudes(status: string): number {
    return this.allDataFromAPI.filter(item => {
      if (!item.Phases || !item.Phases.Phase || !item.Phases.Phase[0]) {
        return false;
      }
      return item.Phases.Phase[0].Status === status;
    }).length;
  }

  applyRevisionFilters(data: any[]): any[] {
    return data.filter(item => {
      if (!item.Phases || !item.Phases.Phase || !item.Phases.Phase[0]) {
        return false;
      }
      const status = item.Phases.Phase[0].Status;
      return status === 'Sin iniciar' || status === 'Prevención' || status === 'Prevención respondida' || status === 'En revisión' || status === 'Caducado';
    });
  }

  applyPagoFilters(data: any[]): any[] {
    return data.filter(item => {
      if (!item.Phases || !item.Phases.Phase || !item.Phases.Phase[0]) {
        return false;
      }
      const status = item.Phases.Phase[0].Status;
      return status === 'Pendiente de pago' || status === 'Pagado';
    });
  }

  applyAutorizacionFilters(data: any[]): any[] {
    return data.filter(item => {
      if (!item.Phases || !item.Phases.Phase || !item.Phases.Phase[0]) {
        return false;
      }
      const status = item.Phases.Phase[0].Status;
      return status === 'Aceptado' || status === 'Rechazado';
    });
  }

  updateCounters() {
  this.revisionCount = this.applyRevisionFilters(this.allDataFromAPI).length;
  this.pagoCount = this.applyPagoFilters(this.allDataFromAPI).length;
  this.autorizacionCount = this.applyAutorizacionFilters(this.allDataFromAPI).length;

  this.total = this.allDataFromAPI.length; // Total solicitudes
  }

  getChipClass(status: string): string {
    switch (status) {
      case 'Sin iniciar':
        return 'sin-iniciar';
      case 'Prevención':
        return 'prevencion';
      case 'Prevención respondida':
        return 'prevencion-respondida';
      case 'En Proceso':
        return 'en-proceso'
      case 'Caducado':
        return 'caducado';
      case 'Pendiente de Pago':
        return 'pendiente-de-pago';
      case 'Pagado':
        return 'pagado';
      case 'En revisión':
        return 'en-revision';
      case 'Rechazado':
        return 'rechazado';
      case 'Aceptado': // Finalizado
      case 'Finalizado':
        return 'aceptado';
      default:
        return 'default';
    }
  }
}
