import { Component, Input, OnInit } from "@angular/core";
import { TramiteService } from "@/app/components/catalogs/tramite.service";
import { Phase, TramiteResponse } from "@/app/shared/interfaces/list.interface";
import { finalize } from "rxjs/operators";
import { UserModel } from "@/app/components/auth/auth.models";
import { ApiService } from "@/app/shared/service/api.service";

@Component({
    selector: 'ver-detalle-tramite',
    templateUrl: './ver-detalle.component.html',
    styleUrls: [
        './ver-detalle.component.scss',
        './../list-tramites/list-tramites.component.scss',
        '../../../catalogs/ver-tramite/ver-tramite.component.scss'
    ]
})
export class VerDetalle implements OnInit {

    @Input() tramiteId: number;

    tramiteDetails: TramiteResponse= null;
    currentStatus: Phase;
    ciudadanoDetails: any;

    isLoadingTramite: boolean= true;
    isTramiteLoadedCorrectly: boolean = false;

    constructor(
        private tramiteService: TramiteService,
        private apiService: ApiService
    ){ }

    ngOnInit(): void {
        this.tramiteService.getTramiteById(this.tramiteId)
        .pipe(finalize(
            () => this.ngAfterServicesResponse()
        ))
        .subscribe(
            (tramite: TramiteResponse) => {
                this.tramiteDetails = tramite;
                this.isTramiteLoadedCorrectly = true;
            },
            (error) => {
                console.error(error);
                this.isTramiteLoadedCorrectly= false;
            },
            () => {
                this.isLoadingTramite= false;
            }
        );
    }

    private async ngAfterServicesResponse() {
        //console.log(this.tramiteDetails);

        // 1. Obtener estatus actual del trámite
        this.identificarEstatusActual();

        // 2. Obtener datos del ciudadano
        const user = JSON.parse(localStorage.getItem('currentUser'));
        this.ciudadanoDetails= await this.apiService.getUserData(user.id).toPromise();

        // 2.1. Separar los apellidos del ciudadano
        this.ciudadanoDetails.User.lastNames= this.ciudadanoDetails.User.lastName.split(' ');

        //console.log(this.ciudadanoDetails);
    }

    private identificarEstatusActual(): void {
        const activeStatus = this.tramiteDetails?.Phase.filter((p) => p.IsActive == true);

        if(activeStatus && activeStatus.length > 0){
            this.currentStatus = activeStatus[0];
        }
    }
}