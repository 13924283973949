<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card tab2-card">
        <div class="card-body">
            <ngb-tabset class="tab-coupon mb-0">
                <ngb-tab>
                    <ng-template ngbTabContent>
                        <div class="tab-pane fade active show" id="account" role="tabpanel"
                            aria-labelledby="account-tab">
                            <div class="mb-3">
                                <img src="../../../../assets/images/SIRC Logo.fw.png" style="width: 21%;" alt="">
                            </div>
                            <h3 class="mb-3 title">Nuevo Servicio</h3>
                        </div>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </div>
    </div>
</div>


<ng2-toasty></ng2-toasty>