import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { AuthenticationService } from '../../services/auth/authentication.service';
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';
import { Location } from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import { ProductsService } from '../../services/products/products.service';
import { ReportsModel } from '../../reports/reports.models';
import { ReportsService } from '../../services/reports/reports.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-create-report',
  templateUrl: './create-report.component.html',
  styleUrls: ['./create-report.component.scss']
})

export class CreateReportComponent implements OnInit, OnDestroy {

  public values: any;
  public problems: any;


  msgs1:any;
  ubicationAuto=false;
  ubicationManuality=false;
  bounds;
  public searchText:any = "";
  public resultSearch:any;
  isloadingDirection = false;
  public isUbication = false;
  public sources = [];
  public form: FormGroup;
  public submitted: boolean = false;
  public submittedAddress: boolean = false;
  public isLoading = false;
  public existsSessions:any = false;
  public isSearching = false;
  public latitude: number;
  public longitude: number;
  public selectedAddress: PlaceResult;
  public completeAddress: string;
  public showMap: boolean = false;
  public showMessage: boolean = false;
  public zoom: number;
  public source: string;
  public other: any;
  public rol: string;
  public folio: number;
  public isEmail = false;
  public requiredFileType: string;
  public ImgfileName = '';
  public responseImgFile: string;
  public previewImg: any;
  public viewLoaderImg = false;

  /*Dirección*/
  public ExtNumber: any;
  public Street: string;
  public IntNumber: string;
  public Neighborhood: string;
  public State: string;
  public City: string;
  public ZipCode: number;

  reportId: any;

  addressForm:FormGroup;
  personalDetails!: FormGroup;
  addressDetails!: FormGroup;
  personal_step = false;
  address_step = false;
  step = 1;
  searchForm: FormGroup;

  onEdit = false;

  private subs: Array<Subscription> = [];
  report: any;
  partAddress = [];
  address = [];

  @ViewChild('nombre') nombreInput: ElementRef;
  @ViewChild('search')
  public searchElementRef: ElementRef;
  userId: string;

  constructor(
    private fb: FormBuilder,
    private reportService: ReportsService,
    private authService: AuthenticationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private sanitizer: DomSanitizer,
    private mapsAPILoader: MapsAPILoader
  ) {
    this.rol = this.authService.currentUserValue.roles;
    this.userId = authService.currentUserValue.id


    this.mapsAPILoader.load().then(() => {
      this.bounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(51.130739, -0.868052), // SW
        new google.maps.LatLng(51.891257, 0.559417) // NE
      );


   })
  }

  ngOnInit(): void {
     const user = JSON.parse(localStorage.getItem("currentUser")) || {}
    if(Object.keys(user).length != 0){
      this.existsSessions = true;

    }else{
      this.existsSessions = false;
    }

    this.activatedRoute.paramMap.subscribe((data: any) => {
      if (data.params.id) {
        this.reportId = data.params.id;
        this.onEdit = true;
        this.subs.push(
          this.reportService.getReport(data.params.id).pipe(
            catchError(error => {
              return throwError(error);
            })
          ).subscribe(report => {
            this.setValues(report)
            this.report = report;
            this.reportService
            .getProblems()
            .subscribe(data => {
              this.problems = data;
              this.valueSubject(this.report)
            });
            this.sendAddress(this.report.Address);
          })
        );

      } else {
        this.onEdit = false;
      }
    });
    if (this.authService.currentUserValue.roles === 'user' || this.authService.currentUserValue.roles === 'citizenhead') {
      this.personalDetails = this.fb.group({
        Name: [this.authService.currentUserValue.displayName, Validators.required],
        Phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        Email: ['', [Validators.required, Validators.pattern('(^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$)|(^[0-9]{10}$)')]],
        Source: [''],
        Subject: ['', Validators.required],
        Area: [''],
        Description: ['', Validators.required],
        Address: [''],
        Street1: [''],
        Street2: [''],
        Check: [false]
      });
    } else {
      this.personalDetails = this.fb.group({
        Name: ['', Validators.required],
        Phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        Email: ['', [Validators.required, Validators.pattern('(^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$)|(^[0-9]{10}$)')]],
        Source: [''],
        Subject: ['', Validators.required],
        Area: [''],
        Description: ['', Validators.required],
        Address: [''],
        Street1: [''],
        Street2: [''],
        Check: [false]
      });
    }


    if(this.existsSessions){

        let isElment = this.authService.currentUserValue.email;
        this.personalDetails.controls["Name"].setValue(this.authService?.currentUserValue.displayName || "");
        if(+isElment){
          this.isEmail = false;
          this.personalDetails.controls["Phone"].setValue(this.authService.currentUserValue.email || "");
        }else{
          this.isEmail = true;
         
          this.personalDetails.controls["Email"].setValue(this.authService.currentUserValue.email || "");
        }


    }


    this.addressForm = this.fb.group({
      calle: ['', Validators.required],
      numExt: ['', Validators.required],
      calleOne: ['', Validators.required],
      calleTwo: ['', Validators.required],
      colonia: ['', Validators.required],
      state: ['Veracruz', Validators.required],
      city: ['Mexico', Validators.required],

  });



  
  this.searchForm = this.fb.group({
    searchText: ["", Validators.required],

  });



    if(!this.onEdit){
      this.reportService
      .getProblems()
      .subscribe(data => {
        this.problems = data;
      });
    }

    this.reportService
      .getValuestoCatalog('Departments')
      .subscribe(data => {
        this.values = data;
      });
    this.reportService
      .getSourcetoUser(this.userId)
      .subscribe(data => {
        this.sources = data;
        if(this.rol === 'citizenhead'){
            this.personalDetails.controls["Source"].setValue(this.sources[0]);
        }
      })
    this.zoom = 16;


    if(this.rol === 'user'){
      this.isVisibleName();
      this.isVisibleNumber();
      this.isVisibleEmail();
    }
    
  }

  isVisibleName(){

    if(!this.onEdit){
    
        this.existsSessions ? this.personalDetails.controls["Name"].disable() : true
    }

  }



  isVisibleNumber(){
      if(!this.onEdit){

            if(this.existsSessions){
                  this.personalDetails.controls["Phone"].disable()
            }
              // let isElment = this.authService.currentUserValue.email || "";
              // if(+isElment){     
              //     return this.existsSessions ? this.personalDetails.controls["Phone"].disable(): true;
              // }else{
              //   return this.existsSessions ? false: false;
              // }
      }
  }

  isVisibleEmail(){
          if(!this.onEdit){

                  if(this.existsSessions){
                    this.personalDetails.controls["Email"].disable()
                  }
                  // let isElment = this.authService.currentUserValue.email || "";
                  // if(+isElment){
                  //   return this.existsSessions ? true: true;
                  // }else{
                  //   return this.existsSessions  ? this.personalDetails.controls["Email"].disable(): true;
                  // }
          }
  }


  addAddress(address){

    this.addressForm.controls["calleOne"].setValue(address?.Street1);
    this.addressForm.controls["calleTwo"].setValue(address?.Street2);
    this.addressForm.controls["calle"].setValue(address?.Street);
    this.addressForm.controls["state"].setValue(address?.State);
    this.addressForm.controls["city"].setValue(address?.City);
    this.addressForm.controls["colonia"].setValue(address?.Neighborhood);
    this.searchText = address?.Neighborhood;
    this.addressForm.controls["numExt"].setValue(address?.ExtNumber);
    this.IntNumber = address?.IntNumber;
    this.ZipCode = address?.ZipCode;
    if(this.onEdit){
      console.log("check check check")
      // this.setCurrentLocation()
      this.inyectMensaje()
    }

  }


  valueSubject(report){
 
    const values = this.reportService.mapRequiredValues(report);
    const problem = this.problems.find(d => d.ShortDescription == values["Subject"]);
    this.personalDetails.controls["Subject"].setValue(problem);
    console.log("---",this.other)
    
  }

  sendAddress(address: any): void {
    
      console.log(address,this.addressForm)
    if (address.Street !== null) {
      this.partAddress.push(address.Street);
      this.address = [...this.partAddress];
      const addressParse = JSON.stringify(this.address);
      this.completeAddress = JSON.parse(addressParse);
    };
    if (address.ExtNumber !== null) {
      this.partAddress.push(address.ExtNumber);
      this.address = [...this.partAddress];
      const addressParse = JSON.stringify(this.address);
      this.completeAddress = JSON.parse(addressParse);
    };
    if (address.Neighborhood !== null) {
      this.partAddress.push(address.Neighborhood);
      this.address = [...this.partAddress];
      const addressParse = JSON.stringify(this.address);
      this.completeAddress = JSON.parse(addressParse);
    };
    if (address.ZipCode !== null) {
      this.partAddress.push(address.ZipCode);
      this.address = [...this.partAddress];
      const addressParse = JSON.stringify(this.address);
      this.completeAddress = JSON.parse(addressParse);
    };
    if (address.State !== null) {
      this.partAddress.push(address.State);
      this.address = [...this.partAddress];
      const addressParse = JSON.stringify(this.address);
      this.completeAddress = JSON.parse(addressParse);
    };
    if (address.City !== null) {
      this.partAddress.push(address.City);
      this.address = [...this.partAddress];
      const addressParse = JSON.stringify(this.address);
      this.completeAddress = JSON.parse(addressParse);
    };
  }

  ngOnDestroy(): void {
    let sub: Subscription;
    while (sub = this.subs.shift()) {
      sub.unsubscribe();
    }
  }

  onImgSelected(event) {
    const file: File = event.target.files[0];
    if (file.type == 'image/png' || file.type == 'image/jpeg') {
      this.viewLoaderImg = true;
      setTimeout(() => {
        if (file) {
          const formData = new FormData();
          formData.append('eventImg', file);
          this.subs.push(
            this.reportService.uploadImg(formData)
              .subscribe(resolve => {
                if (resolve.FileUrl !== null) {
                  this.responseImgFile = resolve.ImageUrl;
                  this.viewLoaderImg = false;
                  this.viewBase64(file).then(image => {
                    this.previewImg = image;
                  })
                  this.ImgfileName = file.name;
                }
              })
          );
        }
      }, 1800);
    } else {
      this.NoTypeImg()
    }
  }

  viewBase64 = async ($event: any) => new Promise((resolve, reject) => {
    try {
      const unsafeImg = window.URL.createObjectURL($event);
      const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = () => {
        resolve({
          base: reader.result
        });
      };
      reader.onerror = error => {
        resolve({
          base: null
        });
      };
    } catch (e) {
      return null;
    }
  })



  disableUbicacion(){

    this.addressForm.reset();  
    this.addressForm.controls["city"].setValue("mexico")
    this.addressForm.controls["state"].setValue("veracruz")
    if(this.ubicationManuality === false){
      this.ubicationAuto = false;
      this.ubicationManuality = true;
  }

     this.isUbication = true;
     this.showMap = false;
     this.searchText = "";

     if(this.onEdit && !!this.report){
      this.addAddress(this.report?.Address)
    }
  }


  onAutocompleteSelected(result: PlaceResult) {
    // console.log(result.geometry.location.lat(),result.geometry.location.lng())
    this.showMessage = false;
    let address = result.address_components;
    if (address.length < 6) {
      this.handleAddressFinal();
      return;
    }

    if (address.length == 8) {
        
        this.ExtNumber = Number(this.strackElement(address,"street_number").short_name) || "",
        this.Street = this.strackElement(address,"route").short_name,
        this.IntNumber = '',
        this.Neighborhood = this.strackElement(address,"political").short_name,
        this.State = this.strackElement(address,"locality").short_name,
        this.City = this.strackElement(address,"country").short_name
        this.ZipCode = Number(this.strackElement(address,"postal_code").short_name)
    } else {
 
        this.ExtNumber = Number(this.strackElement(address,"street_number").short_name) || "",
        this.Street = this.strackElement(address,"route").short_name,
        this.IntNumber = '',
        this.Neighborhood = this.strackElement(address,"political").short_name,
        this.State = this.strackElement(address,"locality").short_name,
        this.City = this.strackElement(address,"country").short_name
        this.ZipCode = Number(this.strackElement(address,"postal_code").short_name)
    }
    this.completeAddress = result.formatted_address;
    this.showMap = true;
  }

  onLocationSelected(location: Location) {
    const geocode = new google.maps.Geocoder();
    geocode.geocode({'location':{lat: location.latitude, lng: location.longitude}}, (results,status)=>{
    })
    
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }

  private setValues(report: any): void {
    this.responseImgFile = report?.ImgUrl;
    const values = this.reportService.mapRequiredValues(report);
    const { controls } = this.personalDetails;

    for (const value in values) {
      if (controls.hasOwnProperty(value)) {
        if(value == "Subject"){

        }else{
          this.personalDetails.controls[value].setValue(values[value]);

        }
      }
    }

    this.personalDetails.controls['Phone'].setValue(values.PhoneNumber)

    if (values.Address) {
      this.showMap = true;
      this.isloadingDirection = true;
      this.isUbication = true;
      this.latitude = values.Address.Location.lat;
      this.longitude = values.Address.Location.lng;
      this.getAddress(this.latitude, this.longitude);
    }
  }

  setCurrentLocation() {

    this.isloadingDirection = true;

   if(this.ubicationAuto === false){
       this.ubicationAuto = true;
       this.ubicationManuality = false;
   }
     

     if ('geolocation' in navigator) {
       this.isUbication = true;
       this.isSearching = true;
       navigator.geolocation.getCurrentPosition((position) => {
         this.latitude = position.coords.latitude;
         this.longitude = position.coords.longitude;
         this.showMap = true;
         this.isSearching = false;
         this.showMessage = true;
         this.getAddress(this.latitude, this.longitude);
         console.log(this.latitude,this.longitude)
       });
     }
   }

  markerDragEnd($event: any) {
    const latitude = $event.latLng.lat();
    const longitude = $event.latLng.lng();
    this.getAddress(latitude, longitude);
  }

  getAddress(latitude, longitude) {
    const latlng = new google.maps.LatLng(latitude, longitude);
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'location': latlng }, (results, status) => {
      if (status !== google.maps.GeocoderStatus.OK) {
       
      }

 
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (status == google.maps.GeocoderStatus.OK) {
        this.completeAddress = (results[0].formatted_address);
        let address = results;
        this.personalDetails.controls['Address'].setValue(this.completeAddress);
        // if (address.length < 6) {
        //   this.handleAddressFinal();
        //   return;
        // }
        // if (address.length == 8) {
        //   this.ExtNumber = Number(address[0].short_name),
        //     this.Street = address[1].short_name,
        //     this.IntNumber = '',
        //     this.Neighborhood = address[3].short_name,
        //     this.State = address[4].short_name,
        //     this.City = address[5].short_name
        //   this.ZipCode = Number(address[7].short_name)
        // } else {
        //   this.ExtNumber = Number(address[0].short_name),
        //     this.Street = address[1].short_name,
        //     this.IntNumber = '',
        //     this.Neighborhood = address[2].short_name,
        //     this.State = address[3].short_name,
        //     this.City = address[4].short_name,
        //     this.ZipCode = Number(address[5].short_name) || Number(address[6].short_name)
        // }


        console.log(address)
        this.ExtNumber = Number(this.strackElement(address,"street_number")?.short_name) || "",
        this.Street = this.strackElement(address,"route")?.short_name,
        this.IntNumber = '',
        this.Neighborhood = this.strackElement(address,"political")?.short_name,
        this.State = this.strackElement(address,"locality")?.short_name,
        this.City = this.strackElement(address,"country")?.short_name,
        this.ZipCode = Number(this.strackElement(address,"postal_code")?.short_name)

      
        this.addressForm.controls["calle"].setValue(this.Street);
        this.addressForm.controls["numExt"].setValue(this.ExtNumber);
        this.addressForm.controls["calleOne"].setValue("");
        this.addressForm.controls["calleTwo"].setValue("");
        if(this.onEdit && !!this.report){
          this.addAddress(this.report?.Address)
        }
        this.addressForm.controls["colonia"].setValue(this.Neighborhood);
        this.addressForm.controls["city"].setValue(this.City);
        this.addressForm.controls["state"].setValue(this.State);
        this.searchText = [this.Neighborhood]


        this.msgs1 = [
          {severity:'warn', summary:'', detail:'Confirma la dirección sea correcta'},
        
        ];
        this.isloadingDirection = false;
      }
    });



  }


  public get formState() {
    return this.personalDetails.controls;
  }


    public onSubmit(): void {
     

        this.submitted = true;
      
        if(!!this.addressForm.controls["colonia"].value){
        }else{
          
          this.addressForm.controls["colonia"].setValue(this.searchText);

        }
        

        if (this.personalDetails.invalid) {
            return;
        }


        this.addressForm.markAllAsTouched()
        if(this.addressForm.invalid){
        
          this.addressError();
          return;
          // const toastOptions: ToastOptions = {
          //   title: 'Alerta',
          //   msg: 'No has ingresado la dirección del reporte',
          //   showClose: true,
          //   timeout: 2000
          // };
          // this.toastyService.warning(toastOptions);
   
            return;
        }else{
         
        }

        if(this.personalDetails.controls['Check'].value === false){
          this.terminosError();
          return;
        }
      
        if (this.onEdit) {
          this.editReport();
        } else {
          this.createReport();
        }
    

    }

  public strackElement(data,value){
    let general;
    const object = data.find(({address_components}) =>  {
       
        const result =  address_components.find(item => {
        
          if(item.types[0] == value){
            
              return item.short_name;
          }
        })

        if(result){
         general = result;
          return result;
        }
    })

    return general
  }  

  private editReport() {
    const report: ReportsModel = this.buildReport();
    console.log("report",report)
    this.isLoading = true;
    this.subs.push(
      this.reportService.updateReport(this.reportId, report)
        .subscribe(resolve => {
          this.folio = resolve.Folio;
          if (this.rol === 'user' || this.rol === 'jefe-manzana') {
            this.handleReportEdit();
            setTimeout(() => {
              this.router.navigateByUrl('/reports/my-user-reports');
            }, 3000);
          }
          if (this.rol === 'funcionario') {
            this.handleReportEdit();
            setTimeout(() => {
              this.router.navigateByUrl('/reports/my-area-reports');
            }, 3000);
          }
          if (this.rol === 'superadmin' || this.rol === 'admin' || this.rol === 'supervisor') {
            this.handleReportEdit();
            setTimeout(() => {
              this.router.navigateByUrl('/reports');
            }, 3000);
          }
        }, error => {
          this.handleError(error)
        })

    );
  }

  private createReport() {
    const report: ReportsModel = this.buildReport();
    // console.log(report)
    this.isLoading = true;
    this.subs.push(
      this.reportService.createReport(report)
        .subscribe(resolve => {
          this.folio = resolve.Folio;
          if (this.rol === 'user' || this.rol === 'citizenhead') {
            this.handleReportCreation();
            setTimeout(() => {
              this.router.navigateByUrl('/reports/my-user-reports');
            }, 3000);
          }
          if (this.rol === 'funcionario') {
            this.handleReportCreation();
            setTimeout(() => {
              this.router.navigateByUrl('/reports/my-area-reports');
            }, 3000);
          }
          if (this.rol === 'superadmin' || this.rol === 'admin' || this.rol === 'supervisor') {
            this.handleReportCreation();
            setTimeout(() => {
              this.router.navigateByUrl('/reports');
            }, 3000);
          }
        }, error => {
          this.handleError(error)
        })

    );
  }

  private buildReport(): ReportsModel {
  
    if (this.other.ShortDescription === 'Otro') {
      return {
        Subject: this.other.ShortDescription ? this.other.ShortDescription : '',
        Department: this.personalDetails.controls['Area'].value,
        Description: this.personalDetails.controls['Description'].value,
        Name: this.personalDetails.controls['Name'].value,
        PhoneNumber: this.personalDetails.controls['Phone'].value,
        Email: this.personalDetails.controls['Email'].value,
        Source: this.personalDetails.controls['Source'].value,
        ImgUrl: this.responseImgFile ? this.responseImgFile : '',
        UserId: this.authService.currentUserValue.id,
        Address: {
          Street: this.addressForm.controls["calle"].value,
          ExtNumber: this.addressForm.controls["numExt"].value,
          IntNumber: this.IntNumber,
          Neighborhood: this.addressForm.controls["colonia"].value,
          Street1:this.addressForm.controls["calleOne"].value,
          Street2:this.addressForm.controls["calleTwo"].value,
          ZipCode: this.ZipCode,
          State: this.addressForm.controls["state"].value,
          City: this.addressForm.controls["city"].value,
          Location: {
            lat: this.latitude,
            lng: this.longitude
          }
        }
      }
    } else {
 
      return {
        Subject: this.other.ShortDescription ? this.other.ShortDescription : '',
        Department: this.other.Department ? this.other.Department : '',
        Description: this.personalDetails.controls['Description'].value,
        Name: this.personalDetails.controls['Name'].value,
        PhoneNumber: this.personalDetails.controls['Phone'].value,
        Email: this.personalDetails.controls['Email'].value,
        Source: this.personalDetails.controls['Source'].value,
        ImgUrl: this.responseImgFile ? this.responseImgFile : '',
        UserId: this.authService.currentUserValue.id,
        Address: {
          Street: this.addressForm.controls["calle"].value,
          ExtNumber: this.addressForm.controls["numExt"].value,
          IntNumber: this.IntNumber,
          Neighborhood: this.addressForm.controls["colonia"].value,
          Street1:this.addressForm.controls["calleOne"].value,
          Street2:this.addressForm.controls["calleTwo"].value,
          ZipCode: this.ZipCode,
          State: this.addressForm.controls["state"].value,
          City: this.addressForm.controls["city"].value,
          Location: {
            lat: this.latitude,
            lng: this.longitude
          }
        }
      }
    }
  }

  private addressError() {
    const toastOptions: ToastOptions = {
      title: 'Alerta',
      msg: 'No has ingresado la dirección del reporte',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.warning(toastOptions);
    return [];
  }

  private handleError(error: any) {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: error.error.message,
      showClose: true,
      timeout: 2000
    };
    this.toastyService.error(toastOptions);
    return [];
  }

  private handleReportCreation() {
    const toastOptions: ToastOptions = {
      title: 'Reporte',
      msg: 'Reporte creado correctamente con el folio Nº ' + this.folio,
      showClose: true,
      timeout: 3000
    };

    this.toastyService.success(toastOptions);
    return [];
  }
  private handleReportEdit() {
    const toastOptions: ToastOptions = {
      title: 'Reporte',
      msg: 'Reporte editado correctamente',
      showClose: true,
      timeout: 3000
    };

    this.toastyService.success(toastOptions);
    return [];
  }

  private handleAddressFinal() {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Por favor agrega la dirección correctamente',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions);
    return [];
  }

  private terminosError() {
    const toastOptions: ToastOptions = {
      title: 'Alerta',
      msg: 'Debes marcar la casilla para ser contactado por un agente',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.info(toastOptions);
    return [];
  }

  private NoTypeImg() {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes subir una imagen de tipo png o jpg',
      showClose: true,
      timeout: 1800
    };

    this.toastyService.error(toastOptions);
  }



  search(){
    const text = this.searchForm.get("searchText").value.toUpperCase();
    console.log(this.searchText)
    this.reportService.searchColonia(this.searchText).subscribe(response => {
      
        this.resultSearch = response;

 
    })

  }


  findLatLang( mainMap) {


    const {address,response} =  this.formaterAdress()
    console.log(address)
    if(response){


    var geocoder = new google.maps.Geocoder();
      return new Promise(function(resolve, reject) {

          geocoder.geocode({'address': address}, function(results, status) {
              if (status === 'OK') {
              
                  resolve([results[0].geometry.location.lat(), results[0].geometry.location.lng()]);
              } else {
                  reject(new Error('Couldnt\'t find the location ' + address));
              }
      })
      })
    }


  }


  async  inyectMensaje() {
    var locations =[ this.findLatLang(map)]
   
      const items =  await Promise.all(locations)     
          .then(function(returnVals){
                  // you should have return values here when
                  // all promises have rsolved
                return  returnVals
           
          })
   
        if(items[0] != undefined){
          this.latitude = items[0][0];
          this.longitude = items[0][1];
        }
        
}

formaterAdress(){
  const city = this.addressForm.controls["city"].value || "";
  const state = this.addressForm.controls['state'].value || ""
  const street = this.addressForm.controls["calle"].value || ""
  const numExt = this.addressForm.controls["numExt"].value || ""
  console.log(city, state, street, numExt)

  if(city != "" && state != "" && street != ""){
      return {
        response:true,
        address: `${street} ${numExt} ${this.searchText} ${city}  ${state}`
      }
  }else{
      return {
        response:false,
        address:""
      }

  }
  
}


}
