import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './sign-up/sign-up.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SharedModule } from '../../shared/shared.module';
import { ToastyModule } from 'ng2-toasty';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { ThanksComponent } from './thanks/thanks.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ActivateComponent } from './activate/activate.component';
import { ArchwizardModule } from 'angular-archwizard';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ChartistModule } from 'ng-chartist';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartsModule } from 'ng2-charts';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { HomeComponent } from './home/home.component';
import { ReportComponent } from './report/report.component';
import { environment } from 'src/environments/environment';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import {ButtonModule} from 'primeng-lts/button';
import {InputTextModule} from 'primeng-lts/inputtext';
import {MessagesModule} from 'primeng-lts/messages';
import {MessageModule} from 'primeng-lts/message';
import {AutoCompleteModule} from 'primeng-lts/autocomplete';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

const API: string = environment.API_KEY;

@NgModule({
  declarations: [
    HomeComponent,
    ReportComponent,
    LoginComponent,
    SignUpComponent,
    ThanksComponent,
    ActivateComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent],
  imports: [
    CommonModule,
    FormsModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    SharedModule,
    ToastyModule,
    NgSelectModule,
    AgmCoreModule.forRoot({
      apiKey: `${API}`,
      libraries: ["geometry", "places"],
      apiVersion: 'quarterly'
    }),
    MatGoogleMapsAutocompleteModule,
    ArchwizardModule,
    ChartsModule,
    Ng2GoogleChartsModule,
    NgxChartsModule,
    ChartistModule,
    Ng2SmartTableModule,
    NgMultiSelectDropDownModule.forRoot(),



    NgxSkeletonLoaderModule,
    ButtonModule,
    InputTextModule,
    MessagesModule,
    MessageModule,
    AutoCompleteModule
    
  ]
})
export class AuthModule { }
