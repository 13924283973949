<div class="page-main-header" [class.open]="open">
    <div class="main-header-right row">
        <div class="main-header-left d-lg-none">
            <div class="logo-wrapper"><a [routerLink]="'/dashboard'"><img class="blur-up lazyloaded"
                        src="assets/images/logo-ver.png" alt=""></a></div>
        </div>
        <div class="mobile-sidebar">
            <div class="media-body text-right switch-sm">
                <label class="switch">
                    <a>
                        <app-feather-icons id="sidebar-toggle" [icon]="'align-left'" (click)="collapseSidebar()">
                        </app-feather-icons>
                    </a>
                </label>
            </div>
        </div>
        <div class="nav-right col">
            <ul class="nav-menus" [ngClass]="{'open' : openNav}">
                <li>
                    <a href="javascript:void(0)" class="text-dark" toggleFullscreen>
                        <app-feather-icons [icon]="'maximize-2'"></app-feather-icons>
                    </a>
                </li>
                <li class="onhover-dropdown">
                    <div class="media align-items-center">
                        <span class="name pdd-right-5">{{currentUser?.displayName}}</span>
                        <i class="ti-angle-down font-size-10"></i>

                        <div class="dotted-animation"><span class="animate-circle"></span><span
                                class="main-circle"></span></div>
                    </div>
                    <ul class="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                        <li>
                            <a *ngIf="currentUser?.roles === 'user'"
                                [routerLink]="['/ciudadano/profile/', currentUser?.id]" title="Editar usuario">
                                <app-feather-icons [icon]="'user'"></app-feather-icons>Editar perfil
                            </a>
                            <a *ngIf="currentUser?.roles !== 'user'" [routerLink]="['/pages/profile/', currentUser?.id]"
                                title="Editar usuario">
                                <app-feather-icons [icon]="'user'"></app-feather-icons>Editar perfil
                            </a>
                        </li>
                        <li (click)="logout()"><a>
                                <app-feather-icons [icon]="'log-out'"></app-feather-icons>Cerrar sesión
                            </a></li>
                    </ul>
                </li>
            </ul>
            <div class="d-lg-none mobile-toggle pull-right" (click)="openMobileNav()">
                <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
            </div>
        </div>
    </div>
</div>