import { Component, Input, NgModule, OnInit } from '@angular/core';
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { ApiService } from '@/app/shared/service/api.service';
import { Subscription } from 'rxjs';
import { FielData, FielModel } from './data.models';

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss']
})

export class DataComponent implements OnInit {

  @Input() id: string;

  onEdit = false;
  currentUser: any;
  profile: any = { person: null };
  user: any;
  dadName = "";
  momName = "";
  address: any;

  fielFiles$: Subscription;
  fielFiles: FielData[] = [];

  fechaUltimaVez: any = {
    'cer': '',
    'key': ''
  };

  validCerFileTypes: any = [
    'application/x-x509-ca-cert',
    'application/x-cert',
    'application/pkix-cert'
  ];

  isCerLoaded: boolean = false;
  isKeyLoaded: boolean = false;

  constructor(
    private authService: AuthenticationService,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private apiService: ApiService
  ) {

    this.toastyConfig.theme = 'bootstrap';
    this.currentUser = this.authService.currentUserValue;

    if (localStorage['currentUser']) {
      const user = JSON.parse(localStorage['currentUser']);

      this.authService.getUserV2(user.id)
        .subscribe(user => {
          this.profile = user;
          if (user.Address) {
            this.address = user.Address;
          } else {
            this.address = {
              ZipCode: '',
              City: '',
              State: '',
              Neighborhood: '',
              Street: '',
              ExtNumber: '',
              IntNumber: '',
              Notes: '',
            }
          }

          this.dadName = user.User.lastName.split(" ")[0];
          this.momName = user.User.lastName.split(" ")[1];
        }, error => {
          console.log(error);
        });
    }

  }

  async ngOnInit() {
    if (this.currentUser?.roles == 'admin') {

      if (this.fielFiles.length == 0) {

        const aux = await this.obtenerArchivosFiel();
        console.log(this.validateFiles(aux));


        //console.log(this.fielFiles);
      }

      /**
       * Desactivar los botones hasta que se haya obtenido alguna respuesta de "obtenerArchivosFiel"
       */
    }
  }

  async obtenerArchivosFiel() {
    var aux: FielModel[] = [null, null];
    try {
      aux = await this.apiService.getFielFiles().toPromise();

      if (aux.length == 2) {
        // Objetivo: Guardar en localstorage los datos informativos sobre los archivos Fiel (excepto Data).

      } else if (aux.length < 2) {
        // Si solo se recuperó uno o ningún elemento, rellenar el arreglo con null.
        for (let i = 2; i >= aux.length + 1; i--) {
          aux.push(null);
        }
      }
    } catch (error) {
      console.error('async obtenerArchivosFiel(): ', error);
    }

    return aux;
  }

  ngOnDestroy(): void {
    if (this.fielFiles$) {
      this.fielFiles$.unsubscribe();
    }
  }

  validateFiles(fielFiles: FielModel[]): FielData {
    const result: FielData = {
      cerFile: {
        isValid: false,
        file: undefined
      },
      keyFile: {
        isValid: false,
        file: undefined
      }
    };

    if (!fielFiles || fielFiles.length === 0) {
      console.warn('No hay archivos para validar');
      return result;
    }

    // Filtrar archivos nulos
    const validFiles = fielFiles.filter(file => file != null);

    for (const file of validFiles) {
      const fileExtension = file.FileName.toLowerCase().split('.').pop() || '';

      // Validar archivo .cer por extensión y mime type
      if (fileExtension === 'cer' && this.validCerFileTypes.includes(file.MimeType)) {
        result.cerFile.file = file;
        result.cerFile.isValid = true;
      }
      // Validar archivo .key solo por extensión
      else if (fileExtension === 'key') {
        result.keyFile.file = file;
        result.keyFile.isValid = true;
      }
    }

    console.warn('Estado de archivos e.firma:', {
      cerValid: result.cerFile.isValid,
      keyValid: result.keyFile.isValid
    });

    return result;
  }

  saveProfile() {
    // Crear la estructura de la propiedad "Location" apropiada para la API.
    const newFormatLocation = {
      "type": "Point",
      "coordinates": [
        this.address.Location ? this.address.Location.lng : 0,
        this.address.Location ? this.address.Location.lat : 0
      ]
    };

    this.address.Location = newFormatLocation;

    // Formar el cuerpo con los datos de la solicitud para la API.
    const body = {
      firstName: this.profile.User.firstName,
      birthday: this.profile.User.birthday,
      lastName: this.dadName + ' ' + this.momName,
      email: this.profile.User.email,
      phoneNumber: this.profile.User.phoneNumber,
      ContactName: this.profile.User.ContactName,
      ContactPhoneNumber: this.profile.User.ContactPhoneNumber,
      Address: this.address,
      Person: this.profile.Person
    }

    // Descartar las propiedades no requeridas por la API.
    for (let p of ['id', 'Alias', 'Active', 'created_at', 'updated_at', 'PersonId', 'ReportId'])
      delete body.Address[p];

    for (let p of ['id', 'Name', 'RET', 'created_at', 'updated_at', 'user_id'])
      delete body.Person[p];

    // Subir los cambios realizados al perfil y al usuario en si.
    this.apiService.updateUser(this.id, body).subscribe((data) => {
      this.handleUserValidate();
    },
      error => {
        this.handleUserError(error.errors[0].instance.path);
      })
  }

  private handleUserValidate() {
    const toastOptions: ToastOptions = {
      title: 'Usuario',
      msg: 'Perfil actualizado exitosamente',
      showClose: true,
      timeout: 1700
    };
    this.onEdit = false;
    this.toastyService.success(toastOptions);
  }

  private handleUserError(path: string) {
    const toastOptions: ToastOptions = {
      title: 'Usuario',
      msg: path === 'TaxId' ? 'El RFC ya existe' : 'Perfil actualizado exitosamente',
      showClose: true,
      timeout: 1700
    };
    this.onEdit = false;
    this.toastyService.success(toastOptions);
  }

  uploadCerFile(file: any) {
    const Files = file.target.files;

    if (Files.length > 1) {
      console.log('No se puede ajuntar más de un archivo.');
    } else if (Files.length == 1) {
      // Se recibe el certificado y se procede con los pasos posteriores
      const File = Files[0];
      // Validar que realmente el tipo de archivo sea un cer
      if (this.validCerFileTypes.includes(File.type) && File.size <= 2500) {
        // Subir el archivo mediante el uso de la API

        const formData = new FormData();
        formData.append('UploadFile', File);

        this.apiService.uploadFielFile(formData).subscribe((res) => {
          console.log(res);
        }, error => {
          const err = error.error.stack.split('\n')[0];
          console.error(err);
        });

        // Actualizar la fecha de carga del cer
        this.fechaUltimaVez.cer = new Date();
        this.isCerLoaded = true;

        return;
      } else {
        console.log('El tipo de archivo que adjuntó no es un cert.');
      }
    } else {
      console.log('No adjuntó ningun archivo.');
    }

    return;
  }

  uploadKeyFile(file: any) {
    const Files = file.target.files;

    if (Files.length > 1) {
      console.log('No se puede ajuntar más de un archivo.');
    } else if (Files.length == 1) {
      // Se recibe el certificado y se procede con los pasos posteriores
      const File = Files[0];
      const FileNS = File.name.split('.');
      const FileExt = FileNS[FileNS.length - 1];

      // Validar que el tipo de archivo sea un .key
      if (FileExt == 'key' && File.type != 'application/vnd.apple.keynote' && File.size <= 5000) {

        const formData = new FormData();
        formData.append('UploadFile', File);

        this.apiService.uploadFielFile(formData).subscribe((res) => {
          console.log('Responde uploadFiel_Key:');
          console.log(res);
        }, error => {
          console.log(error);
        });

        // Actualizar la fecha de carga del key
        this.fechaUltimaVez.key = new Date();
        this.isKeyLoaded = true;

        return;
      } else {
        console.log('El tipo de archivo que adjuntó no parece ser una clave privada.');
      }
    } else {
      console.log('No adjuntó ningun archivo.');
    }

    return;
  }
}
