<div class="container-fluid">
    <div class="card">
        <div class="row product-page-main card-body">
            <div class="col-xl-4">
                <section>
                    <!-- <ks-carousel [id]="100" [images]="imagesRect" [dotsConfig]="{visible: false}"
                        [carouselConfig]="{maxHeight: '600px', keyboardEnable: true}"
                        [previewConfig]="{maxHeight: '125px'}">
                    </ks-carousel> -->
                    <img *ngIf="product?.ImgFileUrl" [src]="[product?.ImgFileUrl]" class="img-fluid blur-up lazyload bg-img" alt="">
                    <img *ngIf="!product?.ImgFileUrl" src="../assets/images/no-found.jpeg" class="img-fluid blur-up lazyload bg-img" alt="No image found">
                    <p *ngIf="!product?.ImgFileUrl" class="center">No se ha encontrado la imagen</p>
                </section>
            </div>
            <div class="col-xl-8">
                <div class="product-page-details product-right mb-0">
                    <h2>{{product?.Title}}</h2>
                    <div class="product-price digits mt-2">
                        <h3>{{product?.UnitPrice | currency}} {{product?.CurrencyCode}}</h3>
                    </div>
                    <h6 class="product-title">SKU:</h6> <span>{{product?.SKU}}</span>
                    <h6 class="product-title">Modelo:</h6> <span>{{product?.Model}}</span>
                    <h6 class="product-title">Marca:</h6> <span>{{product?.Brand}}</span>
                    <!-- <ngb-rating [rate]="3" class="rating-size-product"></ngb-rating> -->
                    <hr>
                    <div class="row" *ngIf="product?.Family == 'Refacciones'">
                        <div class="col-xs-12 col-sm-12 col-lg-12 pb-15">
                            <div class="card-header pl-0">
                                <h5>Stock</h5>
                            </div>
                        </div>
                        <br>
                        <div *ngIf="totalStock == 0" class="container">
                            <p>No cuenta con stock</p>
                        </div>
                        <div class="container" *ngIf="totalStock > 0">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                    <th>Sucursal</th>
                                    <th>Cantidad</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let stock of product?.Stock">
                                    <td>{{stock.Sucursal}}</td>
                                    <td>{{stock.Quantity}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <br>
                    <h6 class="product-title">Descripción</h6>
                    <p class="mb-0">{{product?.Description}}</p>
                    <hr *ngIf="show == false">
                    <h6 *ngIf="show == false" class="product-title size-text">Categorías y Familia
                        <!-- <span class="pull-right">
                            <a data-toggle="modal" (click)="open(content)" class="font-primary">Folleto</a>
                        </span> -->
                    </h6>
                    <!-- <ng-template #content let-modal>
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Sheer Straight Kurta</h5>
                            <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <img src="assets/images/size-chart.jpg" alt="" class="img-fluid blur-up lazyloaded">
                        </div>
                    </ng-template> -->
                    <div *ngIf="show == false" class="size-box">
                        <h6 class="product-title">Familia</h6>
                        <p class="mb-0">{{product?.Family}}</p>
                        <hr>
                        <h6 class="product-title">Categoría</h6>
                        <p class="mb-0">{{product?.Category}}</p>
                        <hr>
                        <h6 class="product-title">{{product?.Family == 'Refacciones' ? 'Línea' : 'Sub categoría'}}</h6>
                        <p class="mb-0">{{product?.SubCategory}}</p>
                    </div>
                    <!-- <hr>
                    <h6 class="product-title">Time Reminder</h6>
                    <div class="timer">
                        <p id="demo"><span>25 <span class="padding-l">:</span> <span class="timer-cal">Days</span>
                            </span><span>22 <span class="padding-l">:</span> <span class="timer-cal">Hrs</span>
                            </span><span>13 <span class="padding-l">:</span> <span class="timer-cal">Min</span>
                            </span><span>57 <span class="timer-cal">Sec</span></span>
                        </p>
                    </div> -->
                </div>
            </div>
            <hr *ngIf="show == false">
            <div class="row padlr" *ngIf="show == false">
                <div class="col-xs-12 col-sm-12 col-lg-12 pb-15">
                    <div class="card-header pl-0">
                        <h5>Características</h5>
                    </div>
                </div>
                <br>
                <div *ngIf="totalFeatures == 0" class="container">
                    <p>No cuenta con características</p>
                </div>
                <div class="container" *ngIf="totalFeatures > 0">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                            <th>Propiedad</th>
                            <th>Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let feature of product?.Features">
                            <td>{{feature.Property}}</td>
                            <td>{{feature.Value}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <hr *ngIf="show == false" >
            <div class="row padlr" *ngIf="show == false">
                <div class="col-xs-12 col-sm-12 col-lg-12 pb-15">
                    <div class="card-header pl-0">
                        <h5>Especificaciones</h5>
                    </div>
                </div>
                <br>
                <div *ngIf="totalSpecifications == 0" class="container">
                    <p>No cuenta con especificaciones</p>
                </div>
                <div *ngIf="totalSpecifications > 0" class="container">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                            <th>Propiedad</th>
                            <th>Valor</th>
                            <th>Grupo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let specification of product?.Specifications">
                            <td>{{specification.Property}}</td>
                            <td>{{specification.Value}}</td>
                            <td>{{specification.Group}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>