import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';


import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';

import { ToggleFullscreenDirective } from "./directives/fullscreen.directive";
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { NavService } from './service/nav.service';
import { WINDOW_PROVIDERS } from './service/windows.service';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';
import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { HeaderTwoComponent } from './components/header-two/header-two.component';
import { BreadcrumbTwoComponent } from './components/breadcrumb-two/breadcrumb-two.component';
import { CiudadanoLayoutComponent } from './layout/ciudadano-layout/ciudadano-layout.component';
import { ToastyModule } from 'ng2-toasty';
import { FooterTwoComponent } from './components/footer-two/footer-two.component';

@NgModule({
  declarations: [
    ToggleFullscreenDirective,
    FeatherIconsComponent,
    FooterComponent,
    FooterTwoComponent,
    HeaderComponent,
    SidebarComponent,
    ContentLayoutComponent,
    CiudadanoLayoutComponent,
    BreadcrumbComponent,
    RightSidebarComponent,
    HeaderTwoComponent,
    BreadcrumbTwoComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ToastyModule.forRoot(),
  ],
  providers: [NavService, WINDOW_PROVIDERS],
  exports: [BreadcrumbTwoComponent, FooterComponent, FooterTwoComponent, HeaderTwoComponent, FeatherIconsComponent, ToggleFullscreenDirective, HttpClientModule, MatGoogleMapsAutocompleteModule, ToastyModule]
})
export class SharedModule { }
