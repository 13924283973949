import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportsService } from 'src/app/components/services/reports/reports.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reports-by-area',
  templateUrl: './reports-by-area.component.html',
  styleUrls: ['./reports-by-area.component.scss']
})
export class ReportsByAreaComponent implements OnInit, OnDestroy {

  fileName= 'Reportes-Área.xlsx';

  private subs: Array<Subscription> = [];
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };

  public reports: any;
  public allReports: any;
  public values = [];
  public isLoading = false;
  public area: string;
  public message: string;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public deleteIsSuccess = false;
  public assignIsSuccess = false;
  public acceptIsSuccess = false;
  public rejectIsSuccess = false;
  public reportId: string;
  public total: number;
  public isResponseData = false;
  public page = 1;
  public limit: number = 10;
  public offset: number = 0;

  public actualDate: any;
  public checkText: boolean = false;
  public textSearch: string;
  public status = 'Total';
  public sources = [];
  public selectedSource = '';

  constructor(
    private reportService: ReportsService,
    private authService: AuthenticationService,
    private modalService: NgbModal,
    private router: Router,
  ) {
    this.actualDate = moment().tz('America/Mexico_City');
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      destroy: true,
      pageLength: 100,
      language: {
          emptyTable: '',
          zeroRecords: 'No se han encontrado resultados',
          lengthMenu: 'Mostrar _MENU_ elementos',
          search: 'Buscar:',
          info: 'De _START_ a _END_ de _TOTAL_ elementos',
          infoEmpty: 'De 0 a 0 de 0 elementos',
          infoFiltered: '(filtrados de _MAX_ elementos totales)',
          paginate: {
              first: 'Prim.',
              last: 'Últ.',
              next: 'Sig.',
              previous: 'Ant.'
          },
      },
      order: [[0, 'asc']]
  };

    this.subs.push(
      this.reportService
        .getReportsByArea(this.authService.currentUserValue.area, this.limit, this.offset, this.status)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.reports = data;
          this.total = data.Total
          this.dtTrigger.next();
        }),
        this.reportService.getReportSource().subscribe(data => {
          this.sources = data;
        })
    );
  }

  reloadTable(): void {
    this.subs.push(
      this.reportService
        .getReportsByArea(this.authService.currentUserValue.area, this.limit, this.offset, this.status)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.reports = data;
          this.total = data.Total
        })
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.subs.forEach(sub => sub.unsubscribe());
  }


  exportExcel(): void {
    this.isLoading = true;
    this.subs.push(
      this.reportService
        .getReportsByArea(this.authService.currentUserValue.area, 999999, 0, 'Total')
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.allReports = data;
          setTimeout(() =>{
            let element = document.getElementById('reports-area');
            const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
        
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Reportes');
          
            XLSX.writeFile(wb, this.fileName);
            this.isLoading = false;
          },2000)
        })
    );
  }

  onFilerbyStatus(status: string): void {
    this.status = status;

    if(this.selectedSource !== ''){
      this.subs.push(
        this.reportService.getReportsBySource(this.selectedSource,this.limit,this.offset,this.status).subscribe(data => {
          this.reports = data;
          this.total = data.Total
        })
      ) 
    }else{
      this.subs.push(
        this.reportService
          .getReportsByArea(this.authService.currentUserValue.area, this.limit, this.offset, this.status)
          .pipe(
            catchError(err => this.authService.handleUnauthorizedError(err))
          )
          .subscribe(data => {
            this.reports = data;
            this.total = data.Total
          })
      );
    }

   
  }

  filterSource(){
    this.isResponseData = false;
    if(this.selectedSource !== ''){
      this.reportService.getReportsBySource(this.selectedSource,this.limit,this.offset,this.status).subscribe(data => {
        this.reports = data;
        this.total = data.Total;
        this.isResponseData = true;
      })
    }else{
      this.ngOnInit();
    }
  }

  public loadPage(page: number) {
    if(this.selectedSource !== ''){
      this.subs.push(
        this.reportService
        .getReportsBySource(this.selectedSource,this.limit,page-1,this.status)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.reports = data;
          this.total = data.Total
        })
      ) 
    }else if (this.checkText === true){
      this.subs.push(
        this.reportService
        .getReportsByAreaText(this.authService.currentUserValue.area, this.limit, page-1, this.status, this.textSearch)
          .pipe(
            catchError(err => this.authService.handleUnauthorizedError(err))
          )
          .subscribe(data => {
            this.reports = data;
            this.total = data.Total
          })
      );
    }else {
      this.subs.push(
        this.reportService
        .getReportsByArea(this.authService.currentUserValue.area, this.limit, page-1, this.status)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          this.reports = data
          this.total = data.Total
        })
      );
    }
  }

  getResults(): any {
    this.checkText = true;
		this.subs.push(
			this.reportService
        .getReportsByAreaText(this.authService.currentUserValue.area, this.limit, this.offset, this.status, this.textSearch)
        .pipe(
          catchError(err => this.authService.handleUnauthorizedError(err))
        )
        .subscribe(data => {
          // console.log(data)
          this.reports = data;
          this.total = data.Total
        })
		);
	}

  public clearInputs(): any {
		this.textSearch = '';
    this.checkText = false;
    this.selectedSource = '';
    this.reloadTable();
	}

}

