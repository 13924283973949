<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="card tab2-card">
      <div class="card-body">
          <ngb-tabset class="tab-coupon mb-0">
              <ngb-tab>
                  <ng-template ngbTabContent>
                      <div class="tab-pane fade active show" id="account" role="tabpanel"
                          aria-labelledby="account-tab">
                          <h3 class="mb-3 title">Nuevo Trámite</h3><br>
                           <form (ngSubmit)="onSubmit()" #tramiteForm="ngForm">
                            <h2 for="Title">Información general del trámite</h2><br>
                              <div>
                                <label for="claveRegistro">Clave o código de registro:</label>
                                <input type="text" id="claveRegistro" name="claveRegistro" [(ngModel)]="tramite.Common.Code" required>
                              </div>
                              <div>
                                <label for="nombreTramite">Nombre del trámite:</label>
                                <input type="text" id="nombreTramite" name="nombreTramite" [(ngModel)]="tramite.Common.Name" required>
                              </div>
                              <div>
                                <label for="dependencia">Dependencia o Entidad:</label>
                                <select id="dependencia" name="dependencia">
                                  <!-- Options for dependencia -->
                                </select>
                              </div>
                              <div>
                                <label for="documento">Documento / comprobante a obtener:</label>
                                <select id="documento" name="documento">
                                  <!-- Options for documento -->
                                </select>
                              </div>
                              <div>
                                <label>¿De qué tipo es el trámite?</label>
                                  <div for="tipoTramite">
                                    <input type="checkbox" id="tipoTramiteObligacion" name="tipoTramite" value="obligacion" [(ngModel)]="tramite.Common.Type">
                                    <label for="tipoTramiteObligacion">Obligación</label><br>
                                    <input type="checkbox" id="tipoTramiteConservacion" name="tipoTramite" value="conservacion" [(ngModel)]="tramite.Common.Type">
                                    <label for="tipoTramiteConservacion">Conservación</label>
                                  </div>
                              </div>
                              <div>
                                <label>¿Quién debe o puede solicitar el trámite?</label>
                                  <div id="solicitante">
                                    <input type="checkbox" id="solicitanteFisicas" name="solicitante" value="fisicas" [(ngModel)]="tramite.Common.Applicant">
                                    <label for="solicitanteFisicas">Personas físicas</label><br>
                                    <input type="checkbox" id="solicitanteMorales" name="solicitante" value="morales" [(ngModel)]="tramite.Common.Applicant">
                                    <label for="solicitanteMorales">Personas Morales</label>
                                  </div>
                              </div>
                              <div>
                                <label for="casosSolicitud">¿En qué casos se puede o debe solicitar el trámite?</label>
                                <textarea id="casosSolicitud" name="casosSolicitud"></textarea>
                              </div><br>
                              
                              <div>
                                <h2 for = "title">Información específica del trámite</h2>
                              </div><br>

                              <div>
                                <label for="fundamentoJ">Fundamento jurídico:</label>
                                <input type="text" id="fundamentoJ" name="derechoUsuario" [(ngModel)]="tramite.Detail.LegalBase">                                  
                              </div>

                              <div>
                                <label for="criteriosR">Criterios de resolución:</label>
                                <input type="text" id="criteriosR" name="derechoUsuario" [(ngModel)]="tramite.Detail.ResolutionCriteria">                                  
                              </div>

                              <div>
                                <label for="derechoUsuario">Derecho del usuario ante la falta de respuesta:</label>
                                <input type="text" id="derechoUsuario" name="derechoUsuario" [(ngModel)]="tramite.Detail.RightBreachAnswer">
                              </div>

                              <div>
                                <label for="formatoAutorizado">Formato(s) correspondiente autorizado:</label>
                                <input type="text" id="formatoAutorizado" name="formatoAutorizado" [(ngModel)]="tramite.Detail.AuthorizedFormats">
                              </div>

                              <div>
                                <label for="descripcion">Descripción:</label>
                                <input type="text" id="descripcion" name="descripcion" [(ngModel)]="tramite.Detail.Description">
                              </div>

                              <div>
                                <label for="efectosEliminacion">¿Qué efectos tendría la eliminación de este trámite?</label>
                                <input type="text" id="efectosEliminacion" name="efectosEliminacion" [(ngModel)]="tramite.Detail.ReasonForElimination">
                              </div>

                              <div>
                                <label for="areaResponsable">Área responsable:</label>
                                <select id="areaResponsable" name="areaResponsable" [(ngModel)]="tramite.Detail.Office" required>
                                  <!-- Options for areaResponsable -->
                                </select>
                              </div>
                              
                              <div>
                                <label for="oficina">Oficina a la que se realiza el trámite:</label>
                                <input type="text" id="oficina" name="oficina" [(ngModel)]="tramite.Detail.Location">
                              </div>
                              <div>
                                <label for="ubicacionOficina">Ubicación de la oficina:</label>
                                <input type="text" id="ubicacionOficina" name="ubicacionOficina" [(ngModel)]="tramite.Detail.Address">
                              </div>
                              <div>
                                <label for="telefonoOficina">Teléfono de la oficina:</label>
                                <input type="text" id="telefonoOficina" name="telefonoOficina" [(ngModel)]="tramite.Detail.OfficePhone">
                                <label for="extensionOficina">Ext.:</label>
                                <input type="text" id="extensionOficina" name="extensionOficina" [(ngModel)]="tramite.Detail.Extension">
                              </div>

                              <div>
                                <label>Horario de atención al usuario:</label>
                                  <div for="horarioAtencion">
                                    <input type="time" id="horarioAtencionInicio" name="horarioAtencionInicio" [(ngModel)]="tramite.Detail.Schedule">
                                    <span>a</span>
                                    <input type="time" id="horarioAtencionFin" name="horarioAtencionFin" [(ngModel)]="tramite.Detail.Schedule">
                                  </div>
                              </div>

                              <div>
                                <label for="tiempoRespuestaVigencia">Tiempo de respuesta y vigencia</label>
                                  <div>
                                    <input type="number" id="tiempoRespuestaVigencia" name="tiempoRespuestaVigencia" [(ngModel)]="tramite.Common.AnswerTime" required>
                                      <div>
                                        <input type="radio" id="diasHabiles" name="tiempoRespuestaVigenciaUnidad" value="diasHabiles" [(ngModel)]="tramite.Common.AnswerTimeType" required>
                                        <label for="diasHabiles">Día(s) hábil(es)</label>
                                        <input type="radio" id="diasNaturales" name="tiempoRespuestaVigenciaUnidad" value="diasNaturales" [(ngModel)]="tramite.Common.AnswerTimeType" required>
                                        <label for="diasNaturales">Día(s) natural(es)</label>
                                      </div>
                                  </div>
                              </div>

                              <div>
                                <label>Vigencia del documento resultante:</label><br>
                                  <div id="vigenciaDocumento">
                                    <input type="radio" id="vigenciaPermanente" name="vigenciaDocumento" value="permanente">
                                    <label for="vigenciaPermanente">Permanente</label>
                                    <input type="radio" id="vigenciaDeterminada" name="vigenciaDocumento" value="determinada">
                                    <label for="vigenciaDeterminada">Determinada</label>
                                  </div>
                                <input type="number" id="duracionVigencia" name="duracionVigencia" [(ngModel)]="tramite.Common.Validity"><br>
                                <input type="radio" id="diasHabilesVigencia" name="duracionVigenciaUnidad" value="diasHabiles" [(ngModel)]="tramite.Common.ValidityType">
                                <label for="diasHabilesVigencia">Día(s) hábil(es)</label>
                                <input type="radio" id="diasNaturalesVigencia" name="duracionVigenciaUnidad" value="diasNaturales" [(ngModel)]="tramite.Common.ValidityType">
                                <label for="diasNaturalesVigencia">Día(s) natural(es)</label>
                              </div><br>
                               
                              
                              <h2 for="Title">Consultas</h2><br>
                                <div>
                                  <label for="nombreResponsableC">Nombre del responsable:</label>
                                  <input type="text" id="nombreResponsableC" name="nombreResponsable" [(ngModel)]="tramite.Inquiry.Attender">
                                </div>
                                <div>
                                  <label for="cargoResponsableC">Cargo del responsable:</label>
                                  <input type="text" id="cargoResponsableC" name="cargoResponsable" [(ngModel)]="tramite.Inquiry.Role">
                                </div>
                                <div>
                                  <label for="domicilioC">Domicilio:</label>
                                  <input type="text" id="domicilioC" name="domicilio" [(ngModel)]="tramite.Inquiry.Address">
                                </div>
                                
                                <div>
                                  <label>Horario de atención al usuario del responsable:</label>
                                    <div id="horarioAtencionResponsable">
                                      <input type="time" id="horarioAtencionResponsableInicioC" name="horarioAtencionResponsableInicio" [(ngModel)]="tramite.Inquiry.Schedule">
                                      <span>a</span>
                                      <input type="time" id="horarioAtencionResponsableFinC" name="horarioAtencionResponsableFin" [(ngModel)]="tramite.Inquiry.Schedule">
                                    </div>
                                </div>
                                
                                <div>
                                  <label for="telefonoResponsableC">Teléfono del responsable:</label>
                                  <input type="text" id="telefonoResponsableC" name="telefonoResponsable" [(ngModel)]="tramite.Inquiry.Phone">
                                  <label for="extensionResponsableC">Ext.:</label>
                                  <input type="text" id="extensionResponsableC" name="extensionResponsable" [(ngModel)]="tramite.Detail.Extension">
                                </div>
                                
                                <div>
                                  <label for="correoElectronicoC">Correo electrónico:</label>
                                  <input type="email" id="correoElectronicoC" name="correoElectronico" [(ngModel)]="tramite.Inquiry.Email">
                                </div>
                                
                                <h2 for="Title">Quejas</h2><br>
                                  <div>
                                    <label for="nombreResponsableQ">Nombre del responsable:</label>
                                    <input type="text" id="nombreResponsableQ" name="nombreResponsable" [(ngModel)]="tramite.Inquiry.Attender">
                                  </div>
                                  <div>
                                    <label for="cargoResponsableQ">Cargo del responsable:</label>
                                    <input type="text" id="cargoResponsableQ" name="cargoResponsable" [(ngModel)]="tramite.Inquiry.Role">
                                  </div>
                                  <div>
                                    <label for="domicilioQ">Domicilio:</label>
                                    <input type="text" id="domicilioQ" name="domicilio" [(ngModel)]="tramite.Inquiry.Address">
                                  </div>
                                
                                  <div>
                                    <label>Horario de atención al usuario del responsable:</label>
                                      <div id="horarioAtencionResponsable">
                                        <input type="time" id="horarioAtencionResponsableInicioQ" name="horarioAtencionResponsableInicio" [(ngModel)]="tramite.Inquiry.Schedule">
                                        <span> a </span>
                                        <input type="time" id="horarioAtencionResponsableFinQ" name="horarioAtencionResponsableFin" [(ngModel)]="tramite.Inquiry.Schedule">
                                      </div>
                                  </div>
                                
                                  <div>
                                    <label for="telefonoResponsableQ">Teléfono del responsable:</label>
                                    <input type="text" id="telefonoResponsableQ" name="telefonoResponsable" [(ngModel)]="tramite.Inquiry.Phone">
                                    <label for="extensionResponsableQ">Ext.:</label>
                                    <input type="text" id="extensionResponsableQ" name="extensionResponsable" [(ngModel)]="tramite.Detail.Extension">
                                  </div>
                                  <div>
                                    <label for="correoElectronicoQ">Correo electrónico:</label>
                                    <input type="email" id="correoElectronicoQ" name="correoElectronico" [(ngModel)]="tramite.Inquiry.Email">
                                  </div>
                                  <div>
                                    <label for="nombreResponsableInformacion">Nombre del responsable de la información:</label>
                                    <input type="text" id="nombreResponsableInformacion" name="nombreResponsableInformacion">
                                  </div>
                                  <div>
                                    <label for="cargoResponsableInformacion">Cargo del responsable de la información:</label>
                                    <input type="text" id="cargoResponsableInformacion" name="cargoResponsableInformacion">
                                  </div>
                               
                                <h2 for="Title">Requisitos del trámite</h2><br>
                                <div>
                                  <ul>
                                    <li>Si la opción es editar, primero elija el requisito</li>
                                    <li>Seleccione el tipo de requisito</li>
                                    <li>Añada una descripción</li>
                                    <li>Seleccione para qué tipo de personas aplica</li>
                                    <li>Haga click en crear/guardar</li>
                                  </ul>
                                  <div class="container">
                                    <div class="row">
                                        <!-- Columna izquierda -->
                                        <div class="col-md-6">Nombre requisitos</div>
                                        <!-- Columna derecha -->
                                        <div class="col-md-6">Acciones</div>
                                    </div>
                                </div>
                                </div>
                                <div>
                                  <div>
                                    <label for="tipoRequisito">Tipo de Requisito:</label>
                                    <select id="tipoRequisito" name="tipoRequisito">
                                      <!-- Opciones para tipoRequisito -->
                                    </select>
                                  </div>
                                  <div>
                                    <label for="descripcionRequisito">Descripción del Requisito:</label>
                                    <textarea id="descripcionRequisito" name="descripcionRequisito"></textarea>
                                  </div>
                                  <div>
                                    <label>¿A qué tipo de régimen pertenece este requisito?</label>
                                    <div>
                                      <input type="checkbox" id="regimenFisica" name="regimenFisica" value="fisica">
                                      <label for="regimenFisica">Persona Física</label>
                                    </div>
                                    <div>
                                      <input type="checkbox" id="regimenMoral" name="regimenMoral" value="moral">
                                      <label for="regimenMoral">Persona Moral</label>
                                    </div>
                                  </div>
                                </div>
                                <button type="submit">Crear Trámite</button>
                           </form>
                      </div>
                  </ng-template>
              </ngb-tab>
          </ngb-tabset>
      </div>
  </div>
</div>


<ng2-toasty></ng2-toasty>