<!-- Container-fluid starts-->
<div class="col-xs-12 pl-3 pr-3 mb-3 d-print-none">
    <label>Dirigido a:</label>
    <input type="text" class="form-control" [(ngModel)]="Name">
</div>
<div class="container-fluid">
    <div class="card">
        <div id="element-to-print" class="card-body container pt-5" style="background-image: url(../../../../assets/images/membrete2.png); background-size: 100%; background-repeat: no-repeat;">
            <div id="batchDelete" class="pt-5" style="margin: 4rem auto;">
                <span class="float-right text-right text-title">
                    <strong>ATENCIÓN CIUDADANA</strong><br>
                    <!-- Oficio número A.C. /0063/ 2021<br> -->
                    Asunto: Reportes SIRC pendientes<br>
                    H. Veracruz, Ver., a {{ currentDay }} de {{ months[currentMonth] }} del {{ currentYear }}
                </span>
                <br>
                <div class="d-grid mt-3">
                    <strong class="text-uppercase text-title">{{Name}}</strong><br>
                    <strong class="text-title text-uppercase">DIRECTOR DE {{report}}</strong><br>
                    <strong class="text-title">H. AYUNTAMIENDO DE VERACRUZ</strong>
                </div>
                <p class="text-justify text-body mt-5 mb-3">
                    Sirva la presente para saludarle cordialmente; y asimismo me permito informarle sobre <strong>los reportes pendientes de atención</strong> que se
                    encuentran en el Sistema Integral de Relación con los Ciudadanos (SIRC), lo anterior, en lo que respecta a los reportes ingresados durante el período comprendido del 
                    <span *ngIf="startYear == endYear">
                        Del {{ endMonth === startMonth ? 
                            week[startDay]+' '+dayStart+' al '+week[endDay]+' '+dayEnd+' de '+months[endMonth]+' '+endYear : 
                            week[startDay]+' '+dayStart+' de '+months[startMonth]+' al '+week[endDay]+' '+dayEnd+' de '+months[endMonth]+' '+endYear
                         }}
                    </span>
                    <span *ngIf="startYear !== endYear">
                        {{ week[startDay]+' '+dayStart+' de '+months[startMonth]+' '+startYear+' al '+dayEnd+' de '+months[endMonth]+' '+endYear }}
                    </span>. <br><br>
                    Se anexa tabla con el número de reportes atendidos y pendientes
                </p>
                <div class="mb-3">
                    <table class="table table-lg table-hover row-border hover border">
                        <thead>
                            <tr class="text-center">
                                <th colspan="10">
                                    <p *ngIf="startYear == endYear" class="text-center table-title">
                                        Del {{ endMonth === startMonth ? 
                                            week[startDay]+' '+dayStart+' al '+week[endDay]+' '+dayEnd+' de '+months[endMonth]+' '+endYear : 
                                            week[startDay]+' '+dayStart+' de '+months[startMonth]+' al '+week[endDay]+' '+dayEnd+' de '+months[endMonth]+' '+endYear
                                         }}
                                    </p>
                                    <p *ngIf="startYear !== endYear" class="text-center table-title">
                                        {{ week[startDay]+' '+dayStart+' de '+months[startMonth]+' '+startYear+' al '+dayEnd+' de '+months[endMonth]+' '+endYear }}
                                    </p>
                                </th>
                            </tr>
                            <tr>
                                <th class="border text-center table-title">Dependencia</th>
                                <th class="border text-center table-title">Total</th>
                                <th class="border text-center table-title">Pendientes</th>
                                <th class="border text-center table-title">%</th>
                                <th class="border text-center table-title">Desarrollo</th>
                                <th class="border text-center table-title">%</th>
                                <th class="border text-center table-title">Atendidos</th>
                                <th class="border text-center table-title">%</th>
                                <th class="border text-center table-title">No procede</th>
                                <th class="border text-center table-title">%</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="border text-center table-body bg-blue">{{report}}</td>
                                <td class="border text-center table-body">{{data.status[3].count}}</td>
                                <td class="border text-center table-body">{{data.status[0].count}}</td>
                                <td class="border text-center table-body">{{data.status[0].count / data.status[3].count * 100 | number : '1.2-2'}}</td>
                                <td class="border text-center table-body">{{data.status[1].count}}</td>
                                <td class="border text-center table-body">{{data.status[1].count / data.status[3].count * 100 | number : '1.2-2'}}</td>
                                <td class="border text-center table-body">{{data.status[2].count}}</td>
                                <td class="border text-center table-body">{{data.status[2].count / data.status[3].count * 100 | number : '1.2-2'}}</td>
                                <td class="border text-center table-body">0</td>
                                <td class="border text-center table-body">0</td>
                                <!-- <td *ngFor="let item of report.List">
                                    {{item.Count}}
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p class="text-justify text-body">
                    Es de suma importancia comunicarle que, dichos reportes son procedentes de los diferentes canales de contacto y atención con el ciudadano: <strong>giras de la alcaldesa,
                    presencial por área y en atención ciudadana, call center, whatsapp, redes sociales, correo institucional y chat de la página “Veracruz Nuestro Puerto, Nuestra Casa”</strong>.
                </p>
                <p class="text-justify text-body">
                    <strong>NOTA: </strong>Se hace la observación de que la cantidad de <u>reportes pendientes, en desarrollo y atendidos</u> puede variar en un rango de 1 a 8 reportes.
                    Debido a la constante retroalimentación del sistema por las diferentes vías de captura. Se anexan folios SIRC.
                </p>
                <p class="text-justify text-body">
                    Agradeciendo la atención prestada, quedo a sus órdenes para cualquier duda o comentario.
                </p>

                <div class="text-center mt-5 mb-5 pt-5 pb-5">
                    <hr class="line">
                    <p class="text-body">
                        <strong>Mtra. Natalia Gpe. Calleja Martínez</strong><br>
                        Directora de Atención Ciudadana<br>
                        H. Ayuntamiento de Veracruz
                    </p>
                </div>

                <p class="text-justify text-body">
                    <strong>C.c.p:</strong> Lic. Patricia lobeira Rodríguez - Presidenta Municipal del H. Ayuntamiento de Veracruz. para su conocimiento. <br>
                    <strong>C.c.p:</strong> Ing. Jorge Eduardo González Jiménez - Jefe de presidencia   <br>
                    <strong>C.c.p:</strong> L.C.C David Othoniel Beristain Hernández - Secretario particular  <br>
                    <strong>C.c.p:</strong> Arq. ElidaAhuet Romero  <br>
                    <strong>C.c.p:</strong> Archivo  <br>
                </p>
                <!-- <p class="text-justify text-body">
                  CPP  NGCM/NOPM/NHS/YMVS
                </p> -->
                <p class="text-justify text-body">
                    NGCM/NOPL/NHS/MVS
                </p>
                <!-- <div class="">
                    <img src="../../../../assets/images/membrete-footer.png" alt="attr" width="100%">
                </div> -->
            </div>
        </div>
        <div class="card-footer container text-right">
            <button class="btn btn-primary" (click)="getPDF()">Descargar</button>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->