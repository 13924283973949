<div *ngIf="trip?.Status === 'Cerrado'">
    <div class="page-wrapper">
        <div class="authentication-box paddd">
            <div class="container">
                <div class="row">
                    <div class="col-md-3"> </div>
                    <div class="col-md-6 p-0">
                        <div class="card tab2-card">
                            <div class="card-body center">
                                <div class="tcenter">
                                    <img src="../../../../assets/images/logo-ver.png" style="width: 60%;" alt="">
                                </div>
                                <ngb-tabset class="tab-coupon mb-0">
                                    <ngb-tab>
                                        <ng-template ngbTabContent>
                                            <div class="tab-pane fade active show" id="account" role="tabpanel"
                                                aria-labelledby="account-tab">
                                                <div class="tcenter">
                                                    <img src="../../../../assets/images/family-car.png"
                                                        style="width: 35%;" alt="">
                                                </div>
                                                <h3 class="thankstitle">VIAJE FINALIZADO</h3>
                                                <h3 class="thankstitle fs-20 pb-5">{{trip?.displayName}} ha llegado a su destino</h3>
                                                <!-- <p class="thanks">Hemos recibido su reporte y trabajaremos en la problemática lo más pronto posible. </p> -->
                                                <!-- <a [routerLink]="'/inicio'"><button class="btn btn-primary">
                                                        Ir a inicio
                                                    </button></a> -->
                                                <!-- <p class="slogan"><strong class="morelos">MORELOS</strong> ANFITRIÓN DEL MUNDO</p> -->
                                            </div>
                                        </ng-template>
                                    </ngb-tab>
                                </ngb-tabset>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3"> </div>
                </div>
                <!-- <a [routerLink]="'/dashboard/default'" class="btn btn-primary back-btn">
                            <app-feather-icons [icon]="'arrow-left'"></app-feather-icons>
                            back
                        </a> -->
            </div>
        </div>
    </div>
</div>


<div class="container-fluid">
    <div class="card">

        <div class="card-body" *ngIf="trip?.Status !== 'Cerrado'">
            <agm-map [latitude]="trip?.location?.lat" [longitude]="trip?.location?.lng" [scrollwheel]="true"
                [zoom]="zoom">
                <!-- <agm-direction [origin]="trip?.origin" [destination]="destination"></agm-direction> -->
                <agm-marker [latitude]="trip?.location?.lat" [longitude]="trip?.location?.lng"
                    [iconUrl]='{"url": "../../../../assets/images/user-pin.png","scaledSize": {"height": 55, "width": 55}}'>
                    <agm-info-window>
                        <div><strong>Pasajero: </strong>{{trip?.displayName}}</div>
                        <div><strong>Núm Económico: </strong> {{trip?.economicNumber}}</div>
                        <div><strong>Última actualización: </strong>{{trip?.updated_at | date:'dd/MM/yyyy, h:mm a'}}
                        </div>
                    </agm-info-window>
                </agm-marker>
            </agm-map>
        </div>
    </div>
</div>