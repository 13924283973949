import { NotificationType } from '@/app/components/interfaces/notification.enums';
import { ApiService } from '@/app/shared/service/api.service';
import { Component, Input, OnInit } from '@angular/core';
import { ToastOptions, ToastyConfig, ToastyService } from 'ng2-toasty';

@Component({
  selector: 'app-record-documents',
  templateUrl: './record-documents.component.html',
  styleUrls: ['./record-documents.component.scss']
})
export class RecordDocumentsComponent implements OnInit {

  @Input() id: string;
  data: any = [];

  constructor(
    private apiService: ApiService,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
  ) {

    this.toastyConfig.theme = 'bootstrap';

  }

  ngOnInit(): void {
    this.loadData()
  }

  async loadData() {
    const data = await this.apiService.getDocumentsByUserId(this.id).toPromise();
    this.data = [];
    for (const [key, value] of Object.entries(data)) {
      this.data.push({
        document: key,
        data: value[0]
      });
    }
  }

  openDocument(document) {
    const imageBlob = this.dataURItoBlob(document);
    window.open(URL.createObjectURL(imageBlob), '_blank')
  }

  changeDocument(event: any, document: any) {
    const validTypes = ['application/pdf', 'image/svg', 'image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'];
    const maxSize = 1024 * 1024 * 3;
    const file: File = event.target.files[0];
    if (!validTypes.includes(file.type)) {
      this.sendNotification(null, 'El formato del archivo no es valido', NotificationType.error);
      return;
    }
    if (file.size > maxSize) {
      this.sendNotification(null, 'El archivo no puede pesar más de 3 MB', NotificationType.error);
      return;
    }

    const formData = new FormData();
    formData.append('UploadFile', file);

    this.apiService.updateFile(formData, document.data.id).subscribe((data: any) => {
      this.sendNotification(null, 'El archivo ha sido reemplazado correctamente', NotificationType.success);
      this.loadData();
    })

  }

  dataURItoBlob(document: any) {

    const type = document.data.MimeType;

    const byteString = atob(document.data.File);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type });
    return blob;
  }

  private sendNotification(title: string = 'Morelos', message: string, type: string): void {
    const toastOptions: ToastOptions = {
      title,
      msg: message,
      showClose: true,
      timeout: 3500,
      theme: 'bootstrap'
    };

    this.toastyService[type](toastOptions);
  }

}
