import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FeaturesComponent } from './physical/features/features.component';
import { SubCategoryComponent } from './physical/sub-category/sub-category.component';
import { ProductListComponent } from './physical/product-list/product-list.component';
import { AddProductComponent } from './physical/add-product/add-product.component';
import { DigitalCategoryComponent } from './digital/digital-category/digital-category.component';
import { DigitalSubCategoryComponent } from './digital/digital-sub-category/digital-sub-category.component';
import { DigitalListComponent } from './digital/digital-list/digital-list.component';
import { DigitalAddComponent } from './digital/digital-add/digital-add.component';
import { ProductDetailComponent } from './physical/product-detail/product-detail.component';
import { SouvenirListComponent } from './souvenir/souvenir-list/souvenir-list.component';
import { SouvenirAddComponent } from './souvenir/souvenir-add/souvenir-add.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'catalogs',
        component: FeaturesComponent,
        data: {
          title: "Catálogos",
          breadcrumb: "Catálogos"
        }
      },
      {
        path: 'sub-category',
        component: SubCategoryComponent,
        data: {
          title: "Sub Categoría",
          breadcrumb: "Sub Categoría"
        }
      },
      {
        path: 'product-list',
        component: ProductListComponent,
        data: {
          title: "Lista Máquinas",
          breadcrumb: "Lista Máquinas"
        }
      },
      {
        path: 'product-list/show/:id',
        component: ProductDetailComponent,
        data: {
          title: "Detalle Producto",
          breadcrumb: "Detalle Producto"
        }
      },
      {
        path: 'product-list/edit/:id',
        component: AddProductComponent,
        data: {
          title: "Editar",
          breadcrumb: "Editar"
        }
      },
      {
        path: 'add-product',
        component: AddProductComponent,
        data: {
          title: "Agregar Máquina",
          breadcrumb: "Agregar Máquina"
        }
      },

      /*Refacciones*/
      {
        path: 'parts-list',
        component: DigitalListComponent,
        data: {
          title: "Lista Refacciones",
          breadcrumb: "Lista Refacciones"
        }
      },
      {
        path: 'parts-list/show/:id',
        component: ProductDetailComponent,
        data: {
          title: "Detalle Producto",
          breadcrumb: "Detalle Producto"
        }
      },
      {
        path: 'parts-list/edit/:id',
        component: DigitalAddComponent,
        data: {
          title: "Editar Máquina",
          breadcrumb: "Editar Máquina"
        }
      },
      {
        path: 'parts/add-product',
        component: DigitalAddComponent,
        data: {
          title: "Agregar Refacción",
          breadcrumb: "Agregar Refacción"
        }
      },
      {
        path: 'digital/digital-category',
        component: DigitalCategoryComponent,
        data: {
          title: "Categoría",
          breadcrumb: "Categoría"
        }
      },
      {
        path: 'digital/digital-sub-category',
        component: DigitalSubCategoryComponent,
        data: {
          title: "Sub Categoría",
          breadcrumb: "Sub Categoría"
        }
      },

      /*Souvenirs*/
      {
        path: 'souvenirs',
        component: SouvenirListComponent,
        data: {
          title: "Lista Souvenirs",
          breadcrumb: "Lista Souvenirs"
        }
      },
      {
        path: 'souvenirs/add-souvenir',
        component: SouvenirAddComponent,
        data: {
          title: "Agregar Souvenir",
          breadcrumb: "Agregar Souvenir"
        }
      },
      {
        path: 'souvenirs/show/:id',
        component: ProductDetailComponent,
        data: {
          title: "Detalle Souvenir",
          breadcrumb: "Detalle Souvenir"
        }
      },
      {
        path: 'souvenirs/edit/:id',
        component: SouvenirAddComponent,
        data: {
          title: "Editar Souvenir",
          breadcrumb: "Editar Souvenir"
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }
