import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getHeaders } from '../../utils/headers.functions';
import * as moment from 'moment-timezone';


const BASE_URL: string = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(
    private http: HttpClient
  ) { }

  public get(): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/users`);
  }

  public getOfficers(extra:any,limit: number, offset: number,role="funcionario"): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/functions?search=${extra}&limit=${limit}&offset=${offset}&role=${role}`);
  }

  public getSupervisors(limit: number, offset: number): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/users/supervisor?limit=${limit}&offset=${offset}`);
  } 

  public getAdmins(limit: number, offset: number): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/admins?limit=${limit}&offset=${offset}`);
  }

  public getUsers(limit: number, offset: number): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/userslist?limit=${limit}&offset=${offset}`);
  }

  public getNeighborhood(limit: number, offset: number): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/usersneighborhood?limit=${limit}&offset=${offset}`);
  }

  public getUsersByType(type: string, limit: number, offset: number): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/localbyType?localtype=${type}&limit=${limit}&offset=${offset}`);
  }

  public getUser(thisUserId: string): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/user/${thisUserId}`);
  }

  public deleteUser(id: string): Observable<any> {
    return this.http.delete<any>(`${BASE_URL}/user/${id}`);
  }

  public updateUser(id: number, user: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/user/${id}`, user, {headers});
  }

  public getUsersByDates(startDate: string, endDate: string): Observable<any> {
    startDate = moment(startDate).tz('America/Mexico_City').format('YYYY-MM-DD');
    endDate = moment(endDate).tz('America/Mexico_City').format('YYYY-MM-DD');
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/businesstime?StartDate=${startDate}&EndDate=${endDate}`, { headers });
  }

  public uploadImg(formData: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/uploadImage`, formData, { headers });
  }

  public uploadFile(formData: any, id: string | number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/uploadFile/${id}`, formData, { headers });
  }

  public sendNote(message: any, id: (number | string)): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/fileMessage/${id}`, message, { headers });
  }

  public updateRole(id:any,role:any){
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/user-role/${id}`, role, { headers });
  }

  public mapRequiredValues(user: any): any {
    const userInfo: any = {};

    userInfo.firstName = user.firstName;
    userInfo.lastName = user.lastName;
    userInfo.email = user.email;
    userInfo.phoneNumber = user.phoneNumber;
    userInfo.department = user.department;
    userInfo.roles = user.roles;

    return userInfo;
  }

  private editUser(user: any): any {
    const editUser = {
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      email: user.email,
      department: user.department
    };
    return editUser;
  }

  private addBackendStructure(user: any) {
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      email: user.email,
      department: user.department
    };
  }

  /*Clientes*/

  public getClients(): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/users/customer`);
  }
}