<div class="page-wrapper">
    <div class="authentication-box paddd">
        <div class="container">
            <div class="row">
                <div class="col-md-3">  </div>
                <div class="col-md-6 p-0">
                    <div class="card tab2-card">
                        <div class="card-body center">
                            <div class="tcenter">
                                <img src="../../../../assets/images/logo-ver.png" style="width: 60%;" alt="">
                            </div>
                            <ngb-tabset class="tab-coupon mb-0">
                                <ngb-tab>
                                    <ng-template ngbTabContent>
                                        <div class="tab-pane fade active show" id="account" role="tabpanel"
                                            aria-labelledby="account-tab">
                                            <div class="tcenter">
                                                <img src="../../../../assets/images/check.png" style="width: 20%;" alt="">
                                            </div>
                                            <h3 class="thankstitle">CUENTA VALIDADA</h3>
                                            <p class="thanks">Tu cuenta ha sido validada exitosamente. Ingresa a tu cuenta.</p>
                                            <a [routerLink]="'/iniciar-sesion'"><button class="btn btn-primary">
                                            Iniciar sesión
                                            </button></a>
                                            <!-- <p class="slogan"><strong class="morelos">MORELOS</strong> ANFITRIÓN DEL MUNDO</p> -->
                                        </div>
                                    </ng-template>
                                </ngb-tab>
                            </ngb-tabset>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">  </div>
            </div>
            <!-- <a [routerLink]="'/dashboard/default'" class="btn btn-primary back-btn">
                <app-feather-icons [icon]="'arrow-left'"></app-feather-icons>
                back
            </a> -->
        </div>
    </div>
</div>

<ng2-toasty></ng2-toasty>