import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, catchError } from 'rxjs/operators';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { ReportsService } from '../../services/reports/reports.service';
import { FollowReportModel } from '../reports.models';
import { ToastOptions, ToastyConfig, ToastyService } from 'ng2-toasty';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../../services/shared.service';
import { MenuItem } from 'primeng-lts/api';

@Component({
  selector: 'app-report-detail',
  templateUrl: './report-detail.component.html',
  styleUrls: ['./report-detail.component.scss']
})
export class ReportDetailsComponent implements OnInit, OnDestroy {
  public accountForm: FormGroup;
  public userId: string;
  public rol: string;
  public isLoading = false;
  public submitted = false;
  public requiredFileType: string;
  public ImgfileName = '';
  public responseImgFile = [];
  items: MenuItem[];
  items2: MenuItem[];
  public previewImg: any;
  public viewLoaderImg = false;
  public zoom: number;
  public fileTypes = [];

  public status: string;
  public updateIsSuccess = false;
  public reportId: string;
  public acceptIsSuccess = false;
  public rejectIsSuccess = false;
  public message: string;

  private subs: Array<Subscription> = [];
  report: any;
  categories = [];
  followReport: FormGroup;

  public partAddress = [];
  public address: any;
  public completeAddress: string;
  public values = [];
  public assignIsSuccess = false;
  public prevImages = [];
  public eventImages = [];
  isImage = false;
  url:any;
  deleteIsSuccess = false;
  public area: string;
  private modalContent: any;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };

  images: any[];

responsiveOptions:any[] = [
    {
        breakpoint: '1024px',
        numVisible: 5
    },
    {
        breakpoint: '768px',
        numVisible: 3
    },
    {
        breakpoint: '560px',
        numVisible: 1
    }
];

responsiveOptions2:any[] = [
    {
        breakpoint: '1500px',
        numVisible: 5
    },
    {
        breakpoint: '1024px',
        numVisible: 3
    },
    {
        breakpoint: '768px',
        numVisible: 2
    },
    {
        breakpoint: '560px',
        numVisible: 1
    }
];

displayBasic: boolean;

displayBasic2: boolean;

displayCustom: boolean;

activeIndex: number = 0;
optionsPanel:any[] = []




  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private reportService: ReportsService,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private router: Router,
    private sharedService: SharedService,
  ) {
    this.toastyConfig.theme = 'bootstrap';
    this.rol = this.authService.currentUserValue.roles;
    this.userId = this.authService.currentUserValue.id
   
  }

  ngOnInit() {
    this.subs.push(
      this.activatedRoute.paramMap.pipe(
        map(params => params.get('id')),
        switchMap(id => this.reportService.getReport(id)),
        catchError(error => {
          return throwError(error);
        })
      ).subscribe(report => {
        let arr = [];
        this.report = report;
        this.images = [
          {
            previewImageSrc:this.report.ImgUrl,
            thumbnailImageSrc:this.report.ImgUrl,
          }
        ]

        console.log("report",report)
        if (this.report.Event) {
          this.report.Event.forEach((item, idx) => {
            this.report.Event[idx].ImgUrl = JSON.parse(item.ImgUrl);
            item.fileTypes = [];
            item.ImgUrl.forEach(file => {
              arr = file.split('.')
              item.fileTypes.push(arr[arr.length-1])
            })
          })
        }
       
        this.sendAddress(this.report.Address);
      }),
      this.reportService
        .getValuestoCatalog('Departments')
        .subscribe(data => {
          this.values = data;
        })
    );
    this.reportService
      .getValuesCatalogtoUser(this.userId)
      .subscribe(data => {
        this.categories = data;
      });
    this.followReport = this.fb.group({
      EventCategory: ['', Validators.required],
      Message: ['', Validators.required],
    });
    this.zoom = 16;


    this.items = [
      {
          label: 'Reporte',
          icon: 'pi pi-file-pdf',
          command: () => {
            window.open("/download-report/"+this.report.id, '_blank')
        }
      },
      {
          label: 'Seguimientos',
          icon: 'pi pi-file-pdf',
          command: () => {
            window.open("/download-reportEvent/"+this.report.id, '_blank')
        }
      },
  ];

    this.items2 = [
      {
          label: 'Reporte',
          icon: 'pi pi-file-pdf',
          command: () => {
            window.open("/download-reportEvent/"+this.report.id, '_blank')
        }
      },
  ];


      

  }


  isRoleUSer(){
    const role = this.authService.currentUserValue.roles || "";
    return role == 'user' || role == "citizenhead" || role == "";
  }


  sendAddress(address: any): void {
    if (address.Street !== null) {
      this.partAddress.push(address.Street);
      this.address = [...this.partAddress];
      const addressParse = JSON.stringify(this.address);
      this.completeAddress = JSON.parse(addressParse);
    };
    if (address.ExtNumber !== null) {
      this.partAddress.push(address.ExtNumber);
      this.address = [...this.partAddress];
      const addressParse = JSON.stringify(this.address);
      this.completeAddress = JSON.parse(addressParse);
    };
    if (address.Neighborhood !== null) {
      this.partAddress.push(address.Neighborhood);
      this.address = [...this.partAddress];
      const addressParse = JSON.stringify(this.address);
      this.completeAddress = JSON.parse(addressParse);
    };
    if (address.ZipCode !== null) {
      this.partAddress.push(address.ZipCode);
      this.address = [...this.partAddress];
      const addressParse = JSON.stringify(this.address);
      this.completeAddress = JSON.parse(addressParse);
    };
    if (address.State !== null) {
      this.partAddress.push(address.State);
      this.address = [...this.partAddress];
      const addressParse = JSON.stringify(this.address);
      this.completeAddress = JSON.parse(addressParse);
    };
    if (address.City !== null) {
      this.partAddress.push(address.City);
      this.address = [...this.partAddress];
      const addressParse = JSON.stringify(this.address);
      this.completeAddress = JSON.parse(addressParse);
    };
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  public get formState() {
    return this.followReport.controls
  }

  onCreateWorkOrderClick(): void {
    this.sharedService.setReportWorkOrder(this.report);
    this.router.navigateByUrl('/work-orders/create-work-order');
  }

  onImgSelected(event) {
    const file: File = event.target.files[0];
    if (file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'application/pdf') {
      this.viewLoaderImg = true;
      setTimeout(() => {
        if (file) {
          const formData = new FormData();
          formData.append('eventImg', file);
          this.subs.push(
            this.reportService.uploadImg(formData)
              .subscribe(resolve => {
                if (resolve.FileUrl !== null) {
                  this.responseImgFile.push(resolve.ImageUrl);
                  this.viewLoaderImg = false;
                  if (file.type !== 'application/pdf') {
                    this.viewBase64(file).then(image => {
                      // this.previewImg = image;

                      this.prevImages.push({ previewImg: image, ImgfileName: file.name })
                    })
                  }else{
                    this.prevImages.push({ previewImg: {base: '../../../../assets/images/pdf.png'} })
                  }
                  // this.ImgfileName = file.name;
                }
              })
          );
        }
      }, 1800);
    } else {
      this.NoTypeImg()
    }
  }

  viewBase64 = async ($event: any) => new Promise((resolve, reject) => {
    try {
      const unsafeImg = window.URL.createObjectURL($event);
      const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = () => {
        resolve({
          base: reader.result
        });
      };
      reader.onerror = error => {
        resolve({
          base: null
        });
      };
    } catch (e) {
      return null;
    }
  })

  private buildFollow(): FollowReportModel {
    return {
      EventCategory: this.followReport.controls['EventCategory'].value,
      Message: this.followReport.controls['Message'].value,
      EventId: this.report.id,
      UserId: this.userId,
      ImgUrl: this.responseImgFile.length > 0 ? JSON.stringify(this.responseImgFile) : ''
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.followReport.invalid) {
      return;
    }

    const follow: FollowReportModel = this.buildFollow();
    this.isLoading = true;
    this.subs.push(
      this.reportService.createFollowReport(follow)
        .pipe(
          map(() => {
            this.handleReportCreation();
            this.prevImages = [];
            this.responseImgFile = [];
            setTimeout(() => {
              this.ngOnInit();
            }, 1000);
          }),
          catchError(error => this.handleError(error))
        )
        .subscribe()
    );
  }

  setModalContent(content: any): void {
    this.modalContent = content;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  acceptModalContent(contentAccept: any, reportId: string): void {
    this.reportId = reportId;
    this.modalContent = contentAccept;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  rejectModalContent(contentReject: any, reportId: string): void {
    this.reportId = reportId;
    this.modalContent = contentReject;
    this.modalService.open(this.modalContent, this.modalConfig);
  }

  public changeStatus(reportId) {
    this.subs.push(
      this.reportService.changeStatusReport(reportId)
        .subscribe(resolve => {
          if (resolve.Message === 'Status cambiado') {
            this.updateIsSuccess = true;
            setTimeout(() => {
              this.modalService.dismissAll();
              this.ngOnInit();
              this.updateIsSuccess = false;
            }, 2000);
          }
        })
    );
  }

  eliminarReporte() {
    this.isLoading = true;
    this.subs.push(
      this.reportService.deleteReport(this.report.id).subscribe(res => {
        // this.deleteIsSuccess = true;
        this.handleReportDeleted();
        setTimeout(() => {
          this.modalService.dismissAll();
          if(this.router.url.search("not-assigned") !== -1){
            this.router.navigateByUrl('reports/not-assigned');
          }else{
            this.router.navigateByUrl('reports');
          }
        }, 3000)

      })

    )
  }

  messageReport(){
    this.handlebuttonExportMessage()
  }

  
  messageReport2(){
    window.open("/download-reportEvent/"+this.report.id, '_blank')
  }

  public assignReport() {
    this.isLoading = true;
    this.subs.push(
      this.reportService.assignReport(this.reportId, this.area)
        .subscribe(resolve => {
          if (resolve.Message === 'Se asigno area') {
            // this.assignIsSuccess = true;
            this.handleReportAsigned();
            setTimeout(() => {
              this.modalService.dismissAll();
              this.assignIsSuccess = false;
              this.router.navigateByUrl('reports/not-assigned');
            }, 2000);
          }
        })
    );
  }

  public acceptOrRejectReport(statusAoR: string): void {
    this.isLoading = true;
    this.subs.push(
      this.reportService.AcceptOrRejectReport(this.reportId, statusAoR, this.message)
        .subscribe(resolve => {
          if (resolve.Message === 'Se acepto asinacion') {
            // this.acceptIsSuccess = true;
            setTimeout(() => {
              this.handleReportAcepted();
              this.modalService.dismissAll();
              this.ngOnInit();
            }, 2000);
          }
          if (resolve.Message === 'Se rechazo asinacion') {
            // this.rejectIsSuccess = true;
            this.handleReportRejected();
            setTimeout(() => {
              this.modalService.dismissAll();
              this.router.navigateByUrl('reports/my-area-reports');
            }, 2000);
          }
        })
    );
  }

  private handleError(error: any) {
    this.isLoading = false;
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: error.error.message,
      showClose: true,
      timeout: 2000
    };
    this.toastyService.error(toastOptions);
    return [];
  }

  private handleReportCreation() {
    this.isLoading = false;
    const toastOptions: ToastOptions = {
      title: 'Seguimiento',
      msg: 'Seguimiento añadido correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  private handleReportDeleted() {
    this.isLoading = false;
    const toastOptions: ToastOptions = {
      title: 'Atención ciudadana',
      msg: 'Reporte eliminado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  private handleReportAsigned() {
    this.isLoading = false;
    const toastOptions: ToastOptions = {
      title: 'Atención ciudadana',
      msg: 'Reporte asignado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  private handlebuttonExportMessage() {
    this.isLoading = false;
    const toastOptions: ToastOptions = {
      title: 'exportar',
      msg: 'selecciona una opción de exportación',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.warning(toastOptions);
  }

  private handleReportAcepted() {
    this.isLoading = false;
    const toastOptions: ToastOptions = {
      title: 'Atención ciudadana',
      msg: 'Reporte aceptado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }
  private handleReportRejected() {
    this.isLoading = false;
    const toastOptions: ToastOptions = {
      title: 'Atención ciudadana',
      msg: 'Reporte rechazado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  private NoTypeImg() {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'Debes subir una imagen de tipo png o jpg',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.error(toastOptions);
  }

}
