import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Ng2SmartTableModule } from 'ng2-smart-table';

import { ChartsModule } from 'ng2-charts';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartistModule } from 'ng-chartist'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ToastyModule } from 'ng2-toasty';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { DataTablesModule } from 'angular-datatables';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { environment } from 'src/environments/environment';
import { CatalogsComponent } from './catalogs.component';
import { CatalogsRoutingModule } from './catalogs-routing.module';
import { CreateServiciosComponent } from './create-servicios/create-servicios.component';
import { CreateTramitesComponent } from './create-tramites/create-tramites.component';
import { BandejatramitesComponent } from './bandejatramites/bandejatramites.component';
import { SharedModule } from '@/app/shared/shared.module';
import { ShowTramiteComponent } from './show-tramite/show-tramite.component';
import { VerTramiteComponent } from './ver-tramite/ver-tramite.component';
import { MatTabsModule } from '@angular/material/tabs';
import { SafeUrlPipe } from './ver-tramite/safe-url.pipe';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';

const API: string = environment.API_KEY;

@NgModule({
  declarations: [
    CatalogsComponent,
    CreateServiciosComponent,
    CreateTramitesComponent,
    BandejatramitesComponent,
    ShowTramiteComponent,
    CreateServiciosComponent,
    CreateTramitesComponent,
    BandejatramitesComponent,
    VerTramiteComponent,
    SafeUrlPipe
  ],
  imports: [
    MatChipsModule,
    CommonModule,
    FormsModule,
    MatTableModule,
    MatSortModule,
    MatTabsModule,
    ReactiveFormsModule,
    ChartsModule,
    Ng2GoogleChartsModule,
    NgxChartsModule,
    ChartistModule,
    CatalogsRoutingModule,
    Ng2SmartTableModule,
    NgSelectModule,
    AgmCoreModule.forRoot({
      apiKey: `${API}`,
      libraries: ["geometry", "places"],
      apiVersion: 'quarterly'
    }),
    MatGoogleMapsAutocompleteModule,
    DataTablesModule,
    NgxDatatableModule,
    NgbModule,
    SharedModule,
    NgMultiSelectDropDownModule.forRoot()
  ]
})
export class CatalogsModule { }
