<section data-id="0bf7162" data-element_type="section">
    <div>
        <div data-id="09a1e05" data-element_type="column">
            <div>
                <div data-id="343d705" data-element_type="widget" data-widget_type="image.default">
                    <div>
                        <img decoding="async" width="1920" height="17"
                            src="../../../../assets/images/miveracruz/footer-image.png"
                            class="attachment-full size-full wp-image-6571" alt="" loading="lazy"
                            sizes="(max-width: 1920px) 100vw, 1920px">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="bg-primary">
    <div class="container-fluid px-5 py-5">
        <div class="row">
            <div class="col-12 col-md-3 text-center">
                <a [routerLink]="['/']">
                    <img src="../../../../assets/images/miveracruz/logo-footer.png" height="145px" alt="">
                </a>
            </div>
            <div class="col-12 col-md-2">
                <div class="container">
                    <div class="list">
                        <ul>
                            <li><a href="#">Aviso de Privacidad</a></li>
                            <li><a href="#">Términos y Condiciones</a></li>
                            <li><a href="#">Configuración de Cookies</a></li>
                            <li><a href="#">Preguntas Frecuentes</a></li>
                            <li><a href="#">Videos Tutoriales</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-2">
                <div class="container">
                    <div class="list">
                        <ul>
                            <li><b>Sitios de interés</b></li>
                            <li><a href="#">Veracruz Municipio</a></li>
                            <li><a href="#">Tesorería Virtual</a></li>
                            <li><a href="#">Gobierno Abierto</a></li>
                            <li><a href="#">Cero Filas</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="container">
                    <div class="list">
                        <ul>
                            <li class="mb-3" id="first-item">Números de emergencia</li>
                            <li class="mb-3">Sigue nuestras redes sociales</li>
                            <li>
                                <a class="mr-4" href="">
                                    <img src="../../../../assets/images/miveracruz/x-logo.png" alt="">
                                </a>
                                <a class="mr-4" href="">
                                    <img src="../../../../assets/images/miveracruz/fb-logo.png" alt="">
                                </a>
                                <a class="mr-4" href="">
                                    <img src="../../../../assets/images/miveracruz/yt-logo.png" alt="">
                                </a>
                                <a class="mr-4" href="">
                                    <img src="../../../../assets/images/miveracruz/git-logo.png" alt="">
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section data-id="0bf7162" data-element_type="section">
    <div>
        <div data-id="09a1e05" data-element_type="column">
            <div>
                <div data-id="343d705" data-element_type="widget" data-widget_type="image.default">
                    <div>
                        <img decoding="async" width="1920" height="17"
                            src="../../../../assets/images/miveracruz/footer-image.png"
                            class="attachment-full size-full wp-image-6571" alt="" loading="lazy"
                            sizes="(max-width: 1920px) 100vw, 1920px">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->