<div class="bg-white">
    <div class="container bg-white py-3">
        <div class="row">
            <app-breadcrumb-two></app-breadcrumb-two>
        </div>
        <div class="row">
            <div class="col-12 col-md-3">
                <div class="row">
                    <div class="col-12 col-md-6 px-0">
                        <div class="profile-image">
                            <img class="image-profile" src="../../../../assets/images/miveracruz/veracruz-logo.png"
                                alt="">
                        </div>
                    </div>
                    <div class="col-12 col-md-6 px-0 primary-data">
                        <h5>Luis Alfredo Gómez Wong</h5>
                        <span>Dirección de Desarrollo, Inovación y Gobierno Abierto</span>
                    </div>
                </div>
                <div class="">
                    <nav>
                        <ul class="primary-menu">
                            <li class="menu-item">Mis Datos</li>
                            <li class="menu-item">Mi Expediente Electrónico</li>
                            <li class="menu-item">Mis Trámites</li>
                            <li class="menu-item">Mis Servicios</li>
                            <li class="menu-item">Mis Pagos</li>
                            <li class="menu-item">Mis Citas</li>
                            <li class="menu-item">Mis Postulaciones</li>
                        </ul>
                        <ul class="secondary-menu">
                            <li class="menu-item">Cambiar contraseña</li>
                            <li class="menu-item">Cerrar sesión</li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div class="col-12 col-md-9">
                <div class="secondary-data">
                    <h5 class="title">
                        Haz Realizado o solicitado
                    </h5>
                    <div class="row d-flex justify-content-between">
                        <div class="col-12 px-0 col-md-2 data-required">
                            <h4 class="counter">34</h4>
                            <span class="data-counter">Trámites</span>
                            <hr>
                        </div>
                        <div class="col-12 px-0 col-md-2 data-required">
                            <h4 class="counter">8</h4>
                            <span class="data-counter">Servicios</span>
                            <hr>
                        </div>
                        <div class="col-12 px-0 col-md-2 data-required">
                            <h4 class="counter">22</h4>
                            <span class="data-counter">Pagos</span>
                            <hr>
                        </div>
                        <div class="col-12 px-0 col-md-2 data-required">
                            <h4 class="counter">16</h4>
                            <span class="data-counter">Citas</span>
                            <hr>
                        </div>
                        <div class="col-12 px-0 col-md-2 data-required">
                            <h4 class="counter">7</h4>
                            <span class="data-counter">Postulaciones</span>
                            <hr>
                        </div>
                    </div>
                </div>
                <app-data class="w-100"></app-data>
            </div>
        </div>
    </div>
</div>