<div class="card w-100" *ngIf="!hasTramiteIdValue()">
    <div class="card-header d-flex justify-content-between align-items-end">
        <span class="title mb-0">
            Filtros
        </span>
    </div>

    <div class="container pt-3 filtros-lista-tramites">
        <div class="row">
            <div class="col-12 col-md-4 mb-2">
                <div class="row">
                    <div class="col text-right">
                        <span style="font-weight: 600">Desde:</span>
                    </div>
                    <div class="col p-0">
                        <input (change)="updateMinDate($event)" class="form-control" type="date">
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 mb-2">
                <div class="row">
                    <div class="col text-right">
                        <span style="font-weight: 600">Hasta:</span>
                    </div>
                    <div class="col p-0">
                        <input (change)="updateMaxDate($event)" class="form-control" type="date">
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 mb-2">
                <div class="row">
                    <div class="col text-right">
                        <span style="font-weight: 600">Estatus:</span>
                    </div>
                    <div class="col p-0">
                        <select (change)="updateStatus($event)" class="form-control" name="" id="">
                            <option value="All">Todos</option>
                            <option value="Pendiente">Pendiente</option>
                            <option value="Prevención">Prevención</option>
                            <option value="Finalizado">Finalizado</option>
                            <option value="Rechazado">Rechazado</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 mb-2">
                <div class="row">
                    <div class="col text-right">
                        <span style="font-weight: 600">Dependencia:</span>
                    </div>
                    <div class="col">
                        <select class="form-control" name="" id="">
                            <option value="all" selected disabled>Selecciona</option>
                            <option *ngFor="let dep of dependencies" value="{{dep.id}}">1</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 mb-2">
                <div class="row">
                    <div class="col text-right">
                        <span style="font-weight: 600">Folio:</span>
                    </div>
                    <div class="col">
                        <input (keyup)="updateFolio($event)" class="form-control" type="text">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card w-100">
        <div class="card-header d-flex justify-content-between align-items-end">
            <span class="title mb-0">
                Mis Trámites
            </span>
        </div>
        <div class="card-body">
            <div class="container-fluid">
                <div class="row">
                    <div class="tramite-data col-12" [ngClass]="{
                        'rechazado' : tramite.Phases.Status === 'Rechazado', 
                        'pendiente' : tramite.Phases.Status === 'Pendiente',
                        'prevencion' : tramite.Phases.Status === 'Prevención', 
                        'finalizado' : tramite.Phases.Status === 'Finalizado',
                        'sin-iniciar' : tramite.Phases.Status === 'Sin iniciar',
                        'pendiente-pago' : tramite.Phases.Status === 'En Proceso',
                        'prev-respondida': tramite.Phases.Status === 'Prevención respondida'
                    }" *ngFor="let tramite of data; let i = index;">
                        <div class="tramite-header">
                            <h5>{{ tramite.Form.FormsCommon.Name }}</h5>
                        </div>
                        <div class="tramite-body">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <h5>Dependencia</h5>
                                        <span>{{ tramite.Dependency.Name }}</span>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <h5>Área</h5>
                                        <span>{{ tramite.Form.Areas.Name }}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <h5>Folio</h5>
                                        <span>{{ tramite.Phases.CitizenRequest.Folio }}</span>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <h5>Fecha de creación</h5>
                                        <span>{{ tramite.Phases.CitizenRequest.created_at | date: 'dd/MM/Y'
                                            }}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <h5>Estatus</h5>
                                        <span class="tramite-status">
                                            <span [ngClass]="{'text-danger':tramite.Phases.Status === 'Prevención' }">{{
                                                tramite.Phases.Status }}</span>

                                            <!-- Obtener la fecha límite de Prevención del servidor y visualiar la "cuenta regresiva" -->
                                            <span class="text-danger" *ngIf="tramite.Phases.Status === 'Prevención'">
                                                Tiempo restante: <b>23:56:00 hrs</b>
                                            </span>
                                        </span>
                                    </div>
                                    <div class="col-12 col-md-6 d-flex align-items-center">
                                        <!-- Enlace 1 -->
                                        <a *ngIf="tramite.Phases.Status === 'En Proceso' || tramite.Phases.Status === 'Pendiente de Pago'"
                                            class="tramite-button"
                                        >
                                            Realizar Pago <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                        </a>
                                        <!-- Enlace 1 -->

                                        <!-- Enlace 2 -->
                                        <button *ngIf="tramite.Phases.Status !== 'En Proceso' && tramite.Phases.Status !== 'Pendiente de Pago' && tramite.Phases.Status !== 'Prevención'"
                                            class="tramite-button"
                                            (click)="actualizarTramiteId(tramite.Phases.CitizenRequestId)"
                                        >
                                            Ver detalle <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                        </button>
                                        <!-- Enlace 2 -->

                                        <!-- Enlace 3 -->
                                        <a *ngIf="tramite.Phases.Status !== 'En Proceso' && tramite.Phases.Status !== 'Pendiente de Pago' && tramite.Phases.Status === 'Prevención'"
                                            class="tramite-button"
                                            [routerLink]="['/ciudadano/tramites',tramite.Phases.CitizenRequestId]"
                                        >
                                            Corregir <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                        </a>
                                        <!-- Enlace 3 -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="data?.length === 0">
                        <h4>No se ha realizado ningún trámite</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Componente para visualizar los detalles del trámite -->
<ver-detalle-tramite *ngIf="hasTramiteIdValue()" [tramiteId]="tramiteId"></ver-detalle-tramite>
<!-- Componente para visualizar los detalles del trámite -->

<ng-template #ModalConfirmar let-modal>
    <div class="card container mb-0 modal-confirmar">
        <div class="card-body">
            <div class="container">
                <div class="row">
                    <h3 class="title-modal fs-1">¿Está seguro que desea proceder al pago?</h3>
                </div>
                <div class="row pt-3">
                    <div class="col-12"></div>
                    <div class="col-12 mt-3 d-flex justify-content-end">
                        <button (click)="closeModal()" class="btn btn-sm btn-secondary cancelar mr-3">Cancelar</button>
                        <button (click)="realizarPagoTramite()" class="btn btn-sm btn-success continuar mr-3">Continuar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>