<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card tab2-card">
        <div class="card-body">
            <ngb-tabset class="tab-coupon mb-0">
                <ngb-tab>
                    <ng-template ngbTabContent>
                        <div class="tab-pane fade active show" id="account" role="tabpanel"
                            aria-labelledby="account-tab">
                            <div class="mb-3">
                                <img src="../../../../assets/images/SIRC Logo.fw.png" style="width: 21%;" alt="">
                            </div>
                            <h3 class="mb-3 title">Reporte ciudadano</h3>
                            <form [formGroup]="personalDetails" class="form-horizontal auth-form" novalidate="">
                                <fieldset>
                                    <div class="row a-initial">
                                        <div class="col-xs-12 col-md-6">
                                            <span class="no-spacing">DATOS GENERALES</span>
                                            <hr>
                                            <div class="row">
                                                <!--Nombre-->
                                                <div class="col-xs-12 col-md-12" >
                                                    <div class="form-group">
                                                        <input required="" formControlName="Name" type="text"  id="nombre" [disabled]="true"
                                                        class="form-control" placeholder="Nombre completo"
                                                        [ngClass]="{'error': submitted && formState?.Name?.errors?.required}">
                                                    </div>
                                                </div>
                                                <!--Teléfono-->
                                                <div class="col-xs-12 col-md-12" >
                                                    <div class="form-group">
                                                        <input required="" formControlName="Phone" type="number" 
                                                        class="form-control" placeholder="Teléfono" maxlength="10"
                                                        [ngClass]="{'error': submitted && formState?.Phone?.errors?.required}">
                                                        <label class="errorRed" 
                                                            *ngIf="submitted && formState?.Phone?.errors?.pattern">
                                                            Ingresa solo 10 números.
                                                        </label>
                                                    </div>
                                                </div>
                                                <!--Email-->
                                                <div class="col-xs-12 col-md-12">
                                                    <div class="form-group">
                                                        <input required="" formControlName="Email" type="text" 
                                                        class="form-control" placeholder="Correo electrónico"
                                                        [ngClass]="{'error': submitted && formState?.Email?.errors?.required}">
                                                        <label class="errorRed"
                                                            *ngIf="submitted && formState?.Email?.errors?.pattern">
                                                            Ingresa un correo válido.
                                                        </label>
                                                    </div>
                                                </div>


                                                <div class="col-xs-12 col-md-12" *ngIf="rol !== 'user' && rol !== 'citizenhead'">
                                                    <div class="form-group">
                                                        <ng-select name="Source" id="Source" formControlName="Source"
                                                            [ngClass]="{'error': submitted && formState?.Source?.errors?.required}">
                                                            <ng-option value="" disabled selected>Seleccione la fuente
                                                            </ng-option>
                                                            <ng-option *ngFor="let source of sources" [value]="source">
                                                                {{source}}
                                                            </ng-option>
                                                        </ng-select>
                                                    </div>
                                                </div>

                                                <!--Asunto-->
                                                <div class="col-xs-12 col-md-12">
                                                    <div class="form-group">
                                                        <ng-select name="Subject" id="Subject" formControlName="Subject" placeholder="Seleccione el problema" [(ngModel)]="other" [ngClass]="{'error': submitted && formState?.Subject?.errors?.required}">
                                                            <ng-option *ngFor="let problem of problems" [value]="problem">
                                                                {{problem.ShortDescription}}
                                                            </ng-option>
                                                        </ng-select>
                                                    </div>
                                                </div>
                                                <!--Otro-->
                                                <div class="col-xs-12 col-md-12" *ngIf="this.other?.ShortDescription === 'Otro'">
                                                    <div class="form-group">
                                                        <ng-select name="Area" id="Area" formControlName="Area" placeholder="Seleccione el área">
                                                            <ng-option value="" disabled selected>Seleccione el área</ng-option>
                                                            <ng-option *ngFor="let value of values" [value]="value">
                                                                {{value}}
                                                            </ng-option>
                                                        </ng-select>
                                                    </div>
                                                </div>
                                                <!--Descripción-->
                                                <div class="col-xs-12 col-md-12">
                                                    <div class="form-group">
                                                        <textarea required="" formControlName="Description" id="" cols="30" rows="5"
                                                        class="form-control" placeholder="Descripción"
                                                        [ngClass]="{'error': submitted && formState?.Description?.errors?.required}"></textarea>
                                                    </div>
                                                </div>


                                                <div class="content-img">
                                                    <div class="" style="margin-top: 20px;">
                                                        <input type="file" class="file-input" [accept]="requiredFileType" (change)="onImgSelected($event)" #fileImgUpload>
                                                        <div class="file-upload">
                                                            <button class="btn btn-primary mat-btn" (click)="fileImgUpload.click()">
                                                                <i class="fa fa-cloud-upload icon-xl" aria-hidden="true"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="onEdit && !previewImg?.base" style="margin-left:20px;">
                                                        <img [src]="report?.ImgUrl" alt="">
                                                    </div>
                                                    <div class="info-img">
                                                        <div *ngIf="previewImg" class="box-input-file">
                                                            <img class="imgUpload" width="100%" alt="" [src]="previewImg.base">
                                                        </div>
                                                        <div *ngIf="viewLoaderImg " class="spinner-border text-danger" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <span  *ngIf="!viewLoaderImg && !onEdit" class="space"> {{ImgfileName || "Agregar imagen...  "}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-md-6">
                                            <span class="no-spacing">UBICACIÓN</span>
                                            <hr>
                                            <div class="row center mb-4 ubication">
                                                <div class="col-xs-12 col-md-12 sin-padding">
                                                    <div class="header-options">
                                                        <button pButton type="button" ngClass="small-size" [disabled]="ubicationAuto" label="Buscar mi ubicación" (click)="setCurrentLocation();" >
                                                            <i class="fa fa-spinner fa-spin"
                                                            [hidden]="!isSearching"></i>
                                                        </button>
                                                        
                                                    
                                                            <!-- <input matInput class="form-control" 
                                                            formControlName="Address"
                                                            matGoogleMapsAutocomplete
                                                            country="mx"
                                                            autocomplete="off"
                                                            placeholder="Ingresa una dirección"
                                                            (onAutocompleteSelected)="onAutocompleteSelected($event)"
                                                            (onLocationSelected)="onLocationSelected($event)"> -->
                                                            <button pButton type="button" ngClass="small-size" [disabled]="ubicationManuality" label="Ingresa la dirección" (click)="disableUbicacion();" >

                                                            </button>
                                                  
                                                    </div>
                                                    <!-- <button (click)="inyectMensaje()">mensaje</button> -->
                                                    <agm-map *ngIf="showMap && isUbication && !isloadingDirection" [latitude]="latitude" [longitude]="longitude" [scrollwheel]="true" [zoom]="zoom">
                                                        <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                                                        (dragEnd)="markerDragEnd($event)"></agm-marker>
                                                    </agm-map>
        
                                                </div>
                                            </div>
                
                                            <div style="margin-bottom:30px;" *ngIf="isUbication && !isloadingDirection">
                                                <p-messages [(value)]="msgs1" [enableService]="false"></p-messages>
                                            </div>
                                            
                                            <section *ngIf="showMap && isUbication && isloadingDirection">
                                                <ngx-skeleton-loader count="1" appearance="rounded" [theme]="{ height: '200px'}"></ngx-skeleton-loader>
                                                <ngx-skeleton-loader count="1" appearance="rounded" [theme]="{ height: '64px'}"></ngx-skeleton-loader>
                                                <section class="grid-formInputs">
                                                    <ngx-skeleton-loader count="1" appearance="rounded" [theme]="{ height: '36px'}"></ngx-skeleton-loader>
                                                    <ngx-skeleton-loader count="1" appearance="rounded" [theme]="{ height: '36px'}"></ngx-skeleton-loader>   
                                                    <ngx-skeleton-loader count="1" appearance="rounded" [theme]="{ height: '36px'}"></ngx-skeleton-loader>   
                                                    <ngx-skeleton-loader count="1" appearance="rounded" [theme]="{ height: '36px'}"></ngx-skeleton-loader>   
                                                    <div class="colonia">
                                                        <span class="p-float-label">
                                                            <ngx-skeleton-loader count="1" appearance="rounded" [theme]="{ height: '36px'}"></ngx-skeleton-loader>  
                                                        </span>
                                                
                                                     </div>
                                                </section>
                                            </section>

                                            <section class="grid-formInputs" [formGroup]="addressForm" *ngIf="isUbication && !isloadingDirection">
                                              
                                              
                                                <span class="p-float-label newSection">
                                                    
                                                    <input id="float-input" type="text" pInputText  formControlName="calle" (keydown)="inyectMensaje()" (blur)="inyectMensaje()"  [ngClass]="{'ng-errorInput': submitted && addressForm?.controls['calle'].errors,'input-with':true }"> 
                                                    <label for="float-input">Calle</label>
                                                </span>

                                                <span class="p-float-label newSection">
                                                    <input id="float-input" type="text" pInputText   formControlName="numExt" (keydown)="inyectMensaje()" (blur)="inyectMensaje()" [ngClass]="{'ng-errorInput': submitted && addressForm?.controls['numExt'].errors, 'input-with':true  }"> 
                                                    <label for="float-input">N° Ext</label>
                                                </span>
                                                <h3 class="info-p"> 
                                                    <!-- <p-message severity="warn" text="Entre Calles" styleClass="p-mr-2 space"></p-message> -->
                                                    Entre calles
                                                </h3>
                                                <span class="p-float-label">
                                                    <input id="float-input" type="text" pInputText formControlName="calleOne" [ngClass]="{'ng-errorInput': submitted && addressForm?.controls['calleOne'].errors }"> 
                                                    <label for="float-input">Calle 1</label>
                                                </span>
                                          
                                                <span class="p-float-label">
                                                    <input id="float-input" type="text" pInputText   formControlName="calleTwo"  [ngClass]="{'ng-errorInput': submitted && addressForm?.controls['calleTwo'].errors }"> 
                                                    <label for="float-input">Calle 2</label>
                                                </span>
                                                
                                                <div class="colonia">
                                                    <span class="p-float-label">
                                                      
                                                        <p-autoComplete [ngModelOptions]="{standalone: true}" onchange="inyectMensaje()"  [(ngModel)]="searchText" [suggestions]="resultSearch"  (completeMethod)="search()" [dropdown]="true" [ngClass]="{'ng-errorInput': searchText,'autocomplete-item': true  }" ></p-autoComplete>
                                                        <label for="float-input">Colonia</label>
                                                    </span>
                                            
                                                 </div>

                                                 
                                            </section>

                                        </div>
                                    </div>
                                        <div class="space-footer">
                                            <div class="mt-15 bottom-i ">
                                                <input type="checkbox" class="mr-10" formControlName="Check" class="item-check" value="true" [ngClass]="{'error': submitted && formState?.Check?.errors?.required}">
                                                <small>He leído el <a href="http://gobiernoabierto.veracruzmunicipio.gob.mx/avisos-de-privacidad/" target="_blank">aviso de privacidad</a>. Y estoy de acuerdo en ser contactado por un agente.</small>
                                            </div>
                                            <button type="button"
                                                class="btn btn-primary width-25"
                                                [disabled]="isLoading"
                                                (click)="onSubmit()">
                                                {{ isLoading ? 'Enviando reporte' : 'Enviar reporte'}}
                                                <i class="fa fa-spinner fa-spin"
                                                    [hidden]="!isLoading"></i>
                                            </button>
                                        </div>
                                </fieldset>
                            </form> 
                        </div>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </div>
    </div>
</div>


<ng2-toasty></ng2-toasty>