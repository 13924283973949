import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { AuthenticationService } from '../../services/auth/authentication.service';
import { UserModel, AuthModel, EstablishmentModel, UserCreateModel } from '../auth.models';
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';
import { Location } from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import { ProductsService } from '../../services/products/products.service';


@Component({
    selector: 'app-signup',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss']
})

export class SignUpComponent implements OnInit, OnDestroy {

    public values: any;
    public form: FormGroup;
    public submitted: boolean = false;
    public isLoading = false;

    personalDetails!: FormGroup;
    private subs: Array<Subscription> = [];

    constructor(
        private fb: FormBuilder,
        private authService: AuthenticationService,
        private productService: ProductsService,
        private router: Router,
        private toastyService: ToastyService,
        private toastyConfig: ToastyConfig
    ) { }

    ngOnInit(): void {
        this.personalDetails = this.fb.group({
            FirstName: ['', Validators.required],
            LastName: ['', Validators.required],
            PhoneNumber: [''],
            Email: ['', [Validators.required, Validators.pattern('(^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$)|(^[0-9]{10}$)')]],
            Password: ['', Validators.required],
            ConfirmedPassword: ['', [Validators.required]]
        });
    }

    ngOnDestroy(): void {
        let sub: Subscription;
        while (sub = this.subs.shift()) {
            sub.unsubscribe();
        }
    }

    public get formState() {
        return this.personalDetails.controls;
    }

    public onSubmit(): void {
        this.submitted = true;
        this.validatePasswords();

        if (this.personalDetails.invalid) {
            return;
        }

        this.createStore();
    }

    private createStore() {
        const user: UserCreateModel = this.buildUser();
        this.isLoading = true;
        this.subs.push(
        this.authService.createUserPerson(user)
        .pipe(
            map(() => {
                this.handleUserCreation();
                setTimeout(() => {
                this.router.navigateByUrl('/iniciar-sesion');
                }, 1800);
            }),
            catchError(error => this.handleError(error))
            )
            .subscribe()
        );
        
      }

    private validatePasswords(): void {
        const password: string = this.personalDetails.get('Password').value;
        const confirmPassword: string = this.personalDetails.get('ConfirmedPassword').value;

        if (password !== confirmPassword) {
            this.personalDetails.controls['ConfirmedPassword'].setErrors({ incorrect: true });
        };
    }

    private buildUser(): UserCreateModel {
        return {
            firstName: this.personalDetails.controls['FirstName'].value,
            lastName: this.personalDetails.controls['LastName'].value,
            email: this.personalDetails.controls['Email'].value,
            password: this.personalDetails.controls['Password'].value,
            phoneNumber: this.personalDetails.controls['PhoneNumber'].value,
            roles: 'user'
        }
    }

    private handleError(error: any) {
        this.isLoading = false;
        const toastOptions: ToastOptions = {
          title: 'Error',
          msg: error.error.message,
          showClose: true,
          timeout: 2000
        };
        this.toastyService.error(toastOptions);
        return [];
    }
    
    private handleUserCreation() {
        this.isLoading = false;
        const toastOptions: ToastOptions = {
          title: 'Registro',
          msg: 'Usuario creado correctamente',
          showClose: true,
          timeout: 1700
        };
        this.toastyService.success(toastOptions);
    }
}
