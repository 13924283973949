<div class="w-100">
    <div class="card w-100">
        <div class="card-header d-flex justify-content-between align-items-end">
            <span class="title mb-0">
                Mis Datos
            </span>
            <i class="fa fa-edit"></i>
        </div>
        <div class="card-body">
            <div class="container-fluid">
                <div class="section-info">
                    <label class="title-label">
                        Área o Dirección
                    </label>
                    <label class="data-label">Dirección de Desarrollo, Innovación y Gobierno Abierto.</label>
                </div>
            </div>
        </div>
        <div class="card-header">
            <div class="subtitle-section">
                <span class="subtitle">
                    Datos de acceso
                </span>
            </div>
        </div>
        <div class="card-body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                CURP
                            </label>
                            <label class="data-label">
                                SOLE970312RGA
                            </label>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Correo electrónico
                            </label>
                            <label class="data-label">
                                eduardo@stackcode.io
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-header">
            <div class="subtitle-section">
                <span class="subtitle">
                    Datos Generales
                </span>
            </div>
        </div>
        <div class="card-body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Nombre(s)
                            </label>
                            <label class="data-label">
                                Eduardo
                            </label>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Apellido Paterno
                            </label>
                            <label class="data-label">
                                Soriano
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Apellido materno
                            </label>
                            <label class="data-label">
                                López
                            </label>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Fecha de nacimiento
                            </label>
                            <label class="data-label">
                                12/03/1997
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                RFC
                            </label>
                            <label class="data-label">
                                SOLE970312RGA
                            </label>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Teléfono celular
                            </label>
                            <label class="data-label">
                                (229)184-7482
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Teléfono particular
                            </label>
                            <label class="data-label">

                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-header">
            <div class="subtitle-section">
                <span class="subtitle">
                    Domicilio
                </span>
            </div>
        </div>
        <div class="card-body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Código postal
                            </label>
                            <label class="data-label">
                                91700
                            </label>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Estado
                            </label>
                            <label class="data-label">
                                Veracruz
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Municipio
                            </label>
                            <label class="data-label">
                                Veracruz
                            </label>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Colonia
                            </label>
                            <label class="data-label">
                                Miguel Hidalgo
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Calle
                            </label>
                            <label class="data-label">
                                Hermandad
                            </label>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Número exterior
                            </label>
                            <label class="data-label">
                                254
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Número interior
                            </label>
                            <label class="data-label">
                                s/n
                            </label>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Referencias
                            </label>
                            <label class="data-label">
                                Fachada de mosaico café
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer"></div>
    </div>
</div>