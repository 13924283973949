import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListClientComponent } from './list-client/list-client.component';
// import { CreateUserComponent } from './create-user/create-user.component';
import { ClientDetailsComponent } from './client-detail/client-detail.component';

const routes: Routes = [
  {
    path: ':type',
    component: ListClientComponent,
    data: {
      title: "Lista clientes",
      breadcrumb: "Lista clientes"
    }
  },
  {
    path: 'show/:id',
    component: ClientDetailsComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientsRoutingModule { }
