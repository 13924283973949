import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FeatureProductModel, ProductModel, SpecificationProductModel } from '../../product.models';
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';
import { ProductsService } from 'src/app/components/services/products/products.service';
import { map, catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {
  public productForm: FormGroup;
  public formfeature: FormGroup;
  public isLoading = false;
  public submitted = false;
  public onEdit: boolean;
  public counter: number = 1;
  public productId: number;
  public id: number;
  public features: any;
  public specifications: any;
  public families: any;
  public categories: any;
  public subcategories: any;

  public featuresProduct: Array<FeatureProductModel> = [];
  public specificationsProduct: Array<SpecificationProductModel> = [];
  public selectedFeature: string;
  public valueFeature: string;
  public GroupSpecification: string;
  public selectedSpecification: string;
  public valueSpecification: string;
  public results: any;

  public url = [{
    img: "assets/images/photo.png",
  },
  {
    img: "assets/images/photo.png",
  },
  {
    img: "assets/images/photo.png",
  }
  ]
  private subs: Array<Subscription> = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private productService: ProductsService,
    private activatedRoute: ActivatedRoute,
  ) {
      this.toastyConfig.theme = 'bootstrap';
  }

  increment() {
    this.counter += 1;
  }

  decrement() {
    this.counter -= 1;
  }

  //FileUpload
  readUrl(event: any, i) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url[i].img = reader.result.toString();
    }
    //console.log(reader);
  }

  ngOnInit() {
    this.subs.push(
      this.activatedRoute.paramMap.subscribe((data: any) => {
        if (data.params.id) {
          this.productId = data.params.id;
          this.onEdit = true;
          this.productService.getProduct(data.params.id)
            .subscribe(product => this.setValues(product));
            this.productForm = this.fb.group({
              Title: ['', [Validators.required]],
              Model: ['', [Validators.required]],
              UnitPrice: ['', [Validators.required]],
              CurrencyCode: ['', [Validators.required]],
              SKU: ['', [Validators.required]],
              Brand: ['', [Validators.required]],
              Family: ['', Validators.required],
              Category: [''],
              SubCategory: ['', Validators.required],
              Description: ['', Validators.required],
              ImgFileUrl: ['', Validators.required],
              ProductInfo: [],
            })
        }else {
          this.onEdit = false;
          this.productForm = this.fb.group({
            Title: ['', [Validators.required]],
            Model: ['', [Validators.required]],
            UnitPrice: ['', [Validators.required]],
            CurrencyCode: ['', [Validators.required]],
            SKU: ['', [Validators.required]],
            Brand: ['', [Validators.required]],
            Family: ['', Validators.required],
            Category: [''],
            SubCategory: ['', Validators.required],
            Description: ['', Validators.required],
            ImgFileUrl: ['', Validators.required],
            ProductInfo: []
          })
        }
        this.productService
        .getFeatures()
        .subscribe(data => {
          this.features = data;
        })
        this.productService
        .getSpecifications()
        .subscribe(data => {
          this.specifications = data;
        })
        this.productService
        .getFamilies()
        .subscribe(data => {
          this.families = data;
        })
        this.productService
        .getCategories()
        .subscribe(data => {
          this.categories = data;
        })
        this.productService
        .getSubCategories()
        .subscribe(data => {
          this.subcategories = data;
        })
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
  
  addFeature(){
    if (this.selectedFeature == null || this.valueFeature == null){
      alert('Debes llenar todos los campos para agregar una característica.')
    }
    else{
      this.featuresProduct.push({CatalogName: 'Feature', Property: this.selectedFeature, Value: this.valueFeature});
      this.selectedFeature = "";
      this.valueFeature = "";
      this.results = [ ...this.featuresProduct, ...this.specificationsProduct];
    }
  }

  removeFeature(feature) {
    const index: number = this.featuresProduct.indexOf(feature);
    this.featuresProduct.splice(index, 1);
    this.results = [ ...this.featuresProduct, ...this.specificationsProduct];
  }

  addSpecification(){
    if (this.GroupSpecification == null || this.selectedSpecification == null || this.valueSpecification == null){
      alert('Debes llenar todos los campos para agregar una especificación.')
    }else{
      this.specificationsProduct.push({CatalogName: 'Specification', Group: this.GroupSpecification, Property: this.selectedSpecification, Value: this.valueSpecification});
      this.GroupSpecification = "";
      this.selectedSpecification = "";
      this.valueSpecification = "";
      this.results = [ ...this.featuresProduct, ...this.specificationsProduct];
    }
  }

  removeSpecification(specification) {
    const index: number = this.specificationsProduct.indexOf(specification);
    this.specificationsProduct.splice(index, 1);
    this.results = [ ...this.featuresProduct, ...this.specificationsProduct];
  }

  public get formState() {
    return this.productForm.controls
  }

  private setValues(product: any): void {
    const values = this.productService.mapRequiredValues(product);
    this.id = values.id;
    this.featuresProduct = values.featuresProduct;
    this.specificationsProduct = values.specificationsProduct;
    const { controls } = this.productForm;

    for (const value in values) {
      if (controls.hasOwnProperty(value)) {
        this.productForm.controls[value].setValue(values[value]);
      }
    }
  }

  private buildProduct(): ProductModel {
    return {
        Title: this.productForm.controls['Title'].value,
        Model: this.productForm.controls['Model'].value,
        UnitPrice: this.productForm.controls['UnitPrice'].value,
        CurrencyCode: this.productForm.controls['CurrencyCode'].value,
        SKU: this.productForm.controls['SKU'].value,
        Brand: this.productForm.controls['Brand'].value,
        Family: this.productForm.controls['Family'].value,
        Category: this.productForm.controls['Category'].value,
        SubCategory: this.productForm.controls['SubCategory'].value,
        Description: this.productForm.controls['Description'].value,
        ImgFileUrl: this.productForm.controls['ImgFileUrl'].value,
        ProductInfo: this.results
    }
  }

  private buildProductUpdate(): ProductModel {
    return {
      id: this.id,
      Title: this.productForm.controls['Title'].value,
      Model: this.productForm.controls['Model'].value,
      UnitPrice: this.productForm.controls['UnitPrice'].value,
      CurrencyCode: this.productForm.controls['CurrencyCode'].value,
      SKU: this.productForm.controls['SKU'].value,
      Brand: this.productForm.controls['Brand'].value,
      Family: this.productForm.controls['Family'].value,
      Category: this.productForm.controls['Category'].value,
      SubCategory: this.productForm.controls['SubCategory'].value,
      Description: this.productForm.controls['Description'].value,
      ImgFileUrl: this.productForm.controls['ImgFileUrl'].value,
      ProductInfo: this.results
    }
  }

  private handleError(error: any) {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'No se pudo crear la máquina',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions);

    return [];
  }

  private handleErrorEdit(error: any) {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'No se pudo actualizar la máquina',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions);

    return [];
  }

  private handleProductCreation() {
    const toastOptions: ToastOptions = {
      title: 'Máquina',
      msg: 'Máquina creada correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  private handleProductEdit() {
    const toastOptions: ToastOptions = {
      title: 'Máquina',
      msg: 'Máquina actualizada correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  onSubmit() {
    this.submitted = true;

    if (this.productForm.invalid) {
        return;
    }

    if (this.onEdit) {
      this.updateProduct();
    } else {
      this.createProduct();
    } 
  }

  createProduct() {

    const product: ProductModel = this.buildProduct();
    this.isLoading = true;

    this.subs.push(
        this.productService.createProduct(product)
        .pipe(
        map(() => {
            this.handleProductCreation();
            setTimeout(() => {
            this.router.navigateByUrl('products/product-list');
            }, 1800);
        }),
        catchError(error => this.handleError(error))
        )
        .subscribe()
    );
  }

  updateProduct() {
    const product: ProductModel = this.buildProductUpdate();
    this.isLoading = true;
    this.subs.push(
        this.productService.updateProduct(this.productId, product)
        .pipe(
        map(() => {
            this.handleProductEdit();
            setTimeout(() => {
            this.router.navigateByUrl('products/product-list');
            }, 1800);
        }),
        catchError(error => this.handleErrorEdit(error))
        )
        .subscribe()
    ); 
  }

}
