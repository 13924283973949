<div class="paddd">
    <div class="container-fuid">
        <div class="card tab2-card" id="htmlData">
            <div class="card-body">
                <div class="row card-body">
                    <div class="col-2 text-left">
                        <img src="../../../../assets/images/SIRC Logo.fw.png" style="width: 80%;" alt="">
                    </div>
                    <div class="col-8 text-center">
                        <!-- <h3 class="text-uppercase">Programa de Mantenimiento de {{workOrder?.Department ?
                            workOrder?.Department : 'Alumbrado Público'}}</h3>
                        <h4 class="text-uppercase">Orden Reporte del trabajo diario</h4> -->
                    </div>
                    <div class="col-2 text-right">
                        <img src="../../../../assets/images/logo-nver.png" class="position-relative"
                            style="width: 80%; bottom: 10px;" alt="">
                    </div>
                </div>

                <div class="row card-body">
                    <div class="col-md-12">
                        <div class="row ">
                            <div class="col-12">
                                <div class="row mb-1">
                                    <div class="label f-size-20">Asignado a:</div>
                                    <div class="text-uppercase font-weight-700 f-size-20">{{workOrder?.Department}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-6">
                                <div class="row mb-1">
                                    <div class="label f-size-20">Reporte SIRC:</div>
                                    <div class="font-weight-700 f-size-20">{{workOrder?.Folio ? workOrder?.Folio : workOrder?.id}}</div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="row mb-1">
                                    <div class="label f-size-20">Fecha:</div>
                                    <div class="font-weight-700 f-size-20">{{workOrder?.created_at | date: 'dd/MM/yyyy'}}</div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="row mb-1">
                                    <div class="label f-size-20">Atención:</div>
                                    <div></div>
                                </div>
                            </div>

                        </div>
                        <div class="row ">
                            <div class="col-12">
                                <div class="row mb-1">
                                    <div class="label f-size-20">Reportó:</div>
                                    <div class="text-uppercase font-weight-700 f-size-20">{{workOrder?.Report ? workOrder.Report.Name :
                                        workOrder?.Name}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-12">
                                <div class="row mb-1">
                                    <div class="label f-size-20">Dirección:</div>
                                    <div class="text-uppercase font-weight-700 f-size-20">
                                        {{workOrder?.Report ? workOrder?.Report?.Address?.Street :
                                        workOrder?.Address?.Street}} ,{{workOrder?.Report ?
                                        workOrder?.Report?.Address?.ExtNumber : workOrder?.Address?.ExtNumber}}
                                        <!-- <span *ngIf="workOrder?.Address?.Street1"> Entre {{workOrder?.Address?.Street1}} y {{workOrder?.Address?.Street2}}</span> -->
                                                
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row ">
                            <div class="col-12">
                                <div class="row mb-1">
                                    <div class="label f-size-20">Entre Calles:</div>
                                    <div class="text-uppercase font-weight-700 f-size-20">
                        
                                        <span *ngIf="workOrder?.Address?.Street1"> {{workOrder?.Address?.Street1}} y {{workOrder?.Address?.Street2}}</span>
                                                
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row ">
                            <div class="col-6">
                                <div class="row mb-1">
                                    <div class="label f-size-20">Colonia - Fraccionamiento:</div>
                                    <div class="text-uppercase font-weight-700 f-size-20">{{workOrder?.Report ?
                                        workOrder?.Report?.Address?.Neighborhood:
                                        workOrder?.Address?.Neighborhood}}</div>
                                </div>
                            </div>
                            <div class="col-3">
                                <!-- <div class="row mb-1">
                                    <div class="label f-size-20">Sector:</div>
                                    <div class="text-uppercase"></div>
                                </div> -->
                            </div>
                            <div class="col-3">
                                <!-- <div class="row mb-1">
                                    <div class="label f-size-20">Cuadrilla:</div>
                                    <div class="text-uppercase"></div>
                                </div> -->
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-6">
                                <div class="row mb-1">
                                    <div class="label f-size-20">Teléfono</div>
                                    <div class="font-weight-700 f-size-20">{{workOrder?.Report ? workOrder?.Report?.PhoneNumber: workOrder?.PhoneNumber}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row mb-1">
                                    <div class="label f-size-20">Celular:</div>
                                    <div class="font-weight-700 f-size-20">{{workOrder?.Report ? workOrder?.Report?.PhoneNumber: workOrder?.PhoneNumber}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row card-body" *ngIf="isEvent">
                    <div class="col-6">
                        <h5 class="f-size-20">Descripción del problema</h5>
                        <p class="text-uppercase f-size-20">{{workOrder?.Description}}</p>
                    </div>
                    <div class="col-6">
                    </div>
                </div>

                <div class="my-5" *ngIf="isEvent">
                    <table class="table table-striped border-bottom">
                        <thead>
                            <tr>
                                <th class="center f-size-20">EVENTO</th>
                                <th class="center f-size-20">DESCRIPCIÓN</th>
                                <th class="center f-size-20">RESPONSABLE</th>
                                <th class="center f-size-20">FECHA/HORA</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr class="center f-size-20" *ngFor="let event of workOrder?.Event">
                                <td>{{event.EventCategory}}</td>
                                <td>{{event.Message}}</td>
                                <td>{{event.User.firstName}} {{event.User.lastName}}</td>
                                <td>{{event.created_at | date: 'dd/MM/yyyy, h:mm a'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="row card-body" *ngIf="!isEvent">
                    <div class="col-12">
                        <div class="mr-3">
                            <h4 class="titleh4 f-size-20">
                                Favor de ejecutar el siguiente trabajo: <span class="mb-1 text-uppercase">
                                    {{workOrder?.Report ? workOrder?.Report?.Subject : workOrder?.Subject}}</span>
                            </h4>
                        </div>
                    </div>
                </div>

                <div class="row card-body" *ngIf="!isEvent">
                    <div class="col-6">
                        <h5 class="f-size-20">Descripción del problema</h5>
                        <p class="text-uppercase f-size-20">{{workOrder?.Description}}</p>
                    </div>
                    <div class="col-6">
                    </div>
                </div>

                <div class="row card-body mt-3" *ngIf="!isEvent">
                    <div class="col-3">
                        <h4 class="titleh4 f-size-20">CARGO:</h4>
                    </div>
                    <div class="col-3">
                        <h4 class="titleh4 f-size-20">INSPECCIÓN ( )</h4>
                    </div>
                    <div class="col-3">
                        <h4 class="titleh4 f-size-20">MANTENIMIENTO ( )</h4>
                    </div>
                    <div class="col-3">
                        <h4 class="titleh4 f-size-20">INVERSIÓN CUENTA ( )</h4>
                    </div>
                </div>

                <div class="row card-body" *ngIf="!isEvent">
                    <div class="col-12">
                        <!-- <div *ngIf="eventos.length > 0">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th class="center">#Act.</th>
                                        <th class="center">Descripción/Cantidad</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let evento of workOrder?.Event">
                                        <td>0</td>
                                        <td>{{evento.EventCategory}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> -->
                        <div *ngIf="workOrder?.Department === 'Alumbrado público'">
                            <div class="col-12 text-center  titleTable" >
                                <h4 class="py-2 f-size-20">Material Instalado</h4>
                            </div>
                            <table class="table table-bordered w-100 staticTable">
                                <thead>
                                    <tr>
                                        <th class="center">FOCO Cant./VSAP.AM</th>
                                        <th class="center">BALASTRO</th>
                                        <th class="center">CANT.</th>
                                        <th class="center">CABLE / CAL</th>
                                        <th class="center">MTS.</th>
                                        <th class="center">LUMINARIO</th>
                                        <th class="center">CANT.</th>
                                        <th class="center">POSTE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>250W</td>
                                        <td>250</td>
                                        <td></td>
                                        <td>CU /</td>
                                        <td></td>
                                        <td>OV-15</td>
                                        <td></td>
                                        <td>Concreto Metal</td>
                                    </tr>
                                    <tr>
                                        <td>175W</td>
                                        <td>150</td>
                                        <td></td>
                                        <td>AL /</td>
                                        <td></td>
                                        <td>HACIENDA</td>
                                        <td></td>
                                        <td>Estado poste</td>
                                    </tr>
                                    <tr>
                                        <td>150W</td>
                                        <td>100</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>$-XX1</td>
                                        <td></td>
                                        <td>MUY MAL</td>
                                    </tr>
                                    <tr>
                                        <td>100W</td>
                                        <td>BRAZO</td>
                                        <td>FOTOCELDA</td>
                                        <td>BASE</td>
                                        <td>MÉNSULA</td>
                                        <td>AERIS</td>
                                        <td></td>
                                        <td>REGULAR</td>
                                    </tr>
                                    <tr>
                                        <td>OTRO</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>OTRO</td>
                                        <td></td>
                                        <td>BIEN</td>
                                    </tr>
                                    <tr >
                                        <td style="border-right: 0px;" colspan="2">CONTRACTOR <span class="mx-5">70/60/40</span></td>
                                        <td style="border-left: 0px;" colspan="6"><span class="ml-5">UBICACIÓN</span> </td>
                                        
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div *ngIf="workOrder?.Department === 'Alumbrado público'">
                            <div class="col-12 text-center  titleTable">
                                <h4 class="py-2">Material recuperado</h4>
                            </div>
                            <table class="table table-bordered w-100 staticTable">
                                <thead>
                                    <tr>
                                        <th class="center">FOCO  Cant./VSAP.AM</th>
                                        <th class="center">BALASTRO</th>
                                        <th class="center">CANT.</th>
                                        <th class="center">CABLE / CAL</th>
                                        <th class="center">MTS.</th>
                                        <th class="center">LUMINARIO</th>
                                        <th class="center">CANT.</th>
                                        <th class="center">POSTE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>250W</td>
                                        <td>250</td>
                                        <td></td>
                                        <td>CU /</td>
                                        <td></td>
                                        <td>OV-15</td>
                                        <td></td>
                                        <td>Concreto Metal</td>
                                    </tr>
                                    <tr>
                                        <td>175W</td>
                                        <td>150</td>
                                        <td></td>
                                        <td>AL /</td>
                                        <td></td>
                                        <td>HACIENDA</td>
                                        <td></td>
                                        <td>Estado poste</td>
                                    </tr>
                                    <tr>
                                        <td>150W</td>
                                        <td>100</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>$-XX1</td>
                                        <td></td>
                                        <td>MUY MAL</td>
                                    </tr>
                                    <tr>
                                        <td>100W</td>
                                        <td>BRAZO</td>
                                        <td>FOTOCELDA</td>
                                        <td>BASE</td>
                                        <td>MÉNSULA</td>
                                        <td>AERIS</td>
                                        <td></td>
                                        <td>REGULAR</td>
                                    </tr>
                                    <tr>
                                        <td>OTRO</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>OTRO</td>
                                        <td></td>
                                        <td>BIEN</td>
                                    </tr>
                                    <tr>
                                        <td style="border-right: 0px;" colspan="2">CONTRACTOR <span class="mx-5">70/60/40</span></td>
                                        <td style="border-left: 0px;" colspan="6"><span class="ml-5">UBICACIÓN</span> </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="mt-2">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th class="center">NOMBRE</th>
                                        <th class="center">CATEGORÍA</th>
                                        <th class="center">HORARIO DE TRABAJO</th>
                                        <th class="center">FIRMA</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="row card-body center font-weight-bold mt-3 f-size-20">
                    <div class="col-4">
                        <div>FORMULÓ</div>
                        <div class="text-center mt-5 mb-5 pt-5 pb-5">
                            <hr class="line">
                            <p class="text-body f-size-20">
                                NOMBRE Y FIRMA DEL SUPERVISOR
                            </p>
                        </div>
                    </div>
                    <div class="col-4">
                        <div>REVISÓ</div>
                        <div class="text-center mt-5 mb-5 pt-5 pb-5">
                            <hr class="line">
                            <p class="text-body f-size-20">
                                JEFE DE ZONA
                            </p>
                        </div>
                    </div>
                    <div class="col-4">
                        <div>AUTORIZÓ</div>
                        <div class="text-center mt-5 mb-5 pt-5 pb-5">
                            <hr class="line">
                            <p class="text-body f-size-20">
                                JEFE DE OPERACIÓN Y MANTTO
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="mr-5 mb-5">
            <div class="text-right">
                <button class="btn btn-primary width-25 mt-2" [disabled]="isDownload" (click)="downloadPDF()">{{
                    isDownload ? 'Descargando' : 'Descargar'}}
                    <i class="fa fa-spinner fa-spin" [hidden]="!isDownload"></i>
                </button>
            </div>
        </div>
    </div>
</div>