<div>

        <section class="blocked-one">

            <form class="section_1" [formGroup]="registerForm">
                <div class="bloque_filter">
                 <div class="bloque_inputs">
                      <h4>Filtro por fechas</h4>
                        <div>
                            <div>
                                <label for="">Desde:</label>
                                <input type="date" formControlName="start_date">
                            </div>
                        
                                
                            <div>
                                <label for="">Hasta:</label>
                                <input type="date" formControlName="end_date">
                            </div>
                        </div>
             
                        <p-button label="Filtrar" class="button-filter" style="border:1px solid #0b4057; color:white; text-decoration: none; border-radius: 10px; "  (onClick)="filterDashboard()" styleClass="p-button-link"></p-button>
                    </div>
                </div>
            </form>
            
             <section class="section_2">
                    <div class="content-card">
                        <div class="card">
                            <h5>{{dasboardReport?.Total}}</h5>
                            <strong> <span class="separador">Total de </span> Reportes</strong>
                        </div>
                        <div class="card">
                            <h5>{{dasboardReport?.ClosedReport}}</h5>
                            <strong> <span class="separador">Reportes</span>Cerrados</strong>
                        </div>
                        <div class="card">
                            <h5>{{dasboardReport?.pendingReport}}</h5>
                            <strong><span class="separador">Reportes</span> Pendientes</strong>
                        </div>
                        <div class="card">
                            <h5>{{dasboardReport?.compliance}}%</h5>
                            <strong><span>Cumplimiento</span></strong>
                        </div>
                    </div>
               
                <!-- <div class="message-subtitle" *ngIf="mesesDiferent">Reportes los últimos {{mesesDiferent}} meses</div> -->
             </section>
        </section>


       <section class="section_3" >
            <section class="line-bar-chart">
                <!-- <h5  style="text-align: center; width: 100%;">No hay datos para mostrar</h5> -->
                <div class="chartjs-container" id="grafic" >
                    <div style="display: block">
                        <canvas baseChart  #baseC 
                        [datasets]="chartData"
                        [labels]="chartLabels"
                        [options]="chartOptions"
                        [legend]="chartLegend"
                        [chartType]="chartType"
                        [colors]="chartColors">
                      </canvas>
                      </div>
                </div>
            </section>

            <section class="blocked-doug">
                       
                            <div class="item-doug">
                                <h6 *ngIf="chartoneExit == 0" style="text-align: center;">No hay datos para mostrar</h6>
                                <canvas  #chartCanvas  [class.ocult]="chartoneExit == 0" 
                                baseChart
                                [labels]="doughnutChartLabels_one"
                                
                                >
                            </canvas>
                                 <h4>Reportes por problemas</h4>
                            </div>
                            <div class="item-doug">
                                <h6 *ngIf="charttwoExit == 0" style="text-align: center;">No hay datos para mostrar</h6>
                                <canvas  #chartCanvastwo  [class.ocult]="charttwoExit == 0" 
                                baseChart
                                [labels]="doughnutChartLabels_two"
                                >
                            </canvas>
                                 <h4>Reportes por área</h4>
                            </div>
                            <div class="item-doug">
                                <h6 *ngIf="chartthreeExit == 0" style="text-align: center;">No hay datos para mostrar</h6>
                                <canvas  #chartCanvasthree  [class.ocult]="chartthreeExit == 0" 
                                baseChart
                                [labels]="doughnutChartLabels_three"
                                >
                            </canvas>
                               <h4>Reportes por fuente</h4>

                            </div>
            </section>
       </section>
    
    


       <section class="section_4">


       </section>
</div>


   
<div *ngIf="isLoading == true">
    

    <div class="item" style="width:75%;">
        <ngx-skeleton-loader count="1" appearance="rectangle" 
        [theme]="{ 
            'height.px': 70
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class="grid-loader">

                <ngx-skeleton-loader count="1" appearance="rectangle" 
                [theme]="{ 
                    'height.px': 100
                }"
                ></ngx-skeleton-loader>

                <ngx-skeleton-loader count="1" appearance="rectangle" 
                [theme]="{ 
                    'height.px': 100
                }"
                ></ngx-skeleton-loader>

                <ngx-skeleton-loader count="1" appearance="rectangle" 
                [theme]="{ 
                    'height.px': 100
                }"
                ></ngx-skeleton-loader>

                <ngx-skeleton-loader count="1" appearance="rectangle" 
                [theme]="{ 
                    'height.px': 100
                }"
                ></ngx-skeleton-loader>

                <ngx-skeleton-loader count="1" appearance="rectangle" 
                [theme]="{ 
                    'height.px': 100
                }"
                ></ngx-skeleton-loader>

                <ngx-skeleton-loader count="1" appearance="rectangle" 
                [theme]="{ 
                    'height.px': 100
                }"
                ></ngx-skeleton-loader>

      </div>

      <div class="dasboard-loading">
        <ngx-skeleton-loader count="1" appearance="rectangle" 
        [theme]="{ 
            'height.px': 300
          }"
        ></ngx-skeleton-loader>
      </div>

</div>