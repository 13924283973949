<div class="w-100">
    <div class="secondary-data">
        <h5 class="title">
            Has Realizado o solicitado
        </h5>
        <div class="row d-flex justify-content-between">
            <div class="col-12 px-0 col-md-2 data-required">
                <h4 class="counter">34</h4>
                <span class="data-counter">Trámites</span>
                <hr>
            </div>
            <div class="col-12 px-0 col-md-2 data-required">
                <h4 class="counter">8</h4>
                <span class="data-counter">Servicios</span>
                <hr>
            </div>
            <div class="col-12 px-0 col-md-2 data-required">
                <h4 class="counter">22</h4>
                <span class="data-counter">Pagos</span>
                <hr>
            </div>
            <div class="col-12 px-0 col-md-2 data-required">
                <h4 class="counter">16</h4>
                <span class="data-counter">Citas</span>
                <hr>
            </div>
            <div class="col-12 px-0 col-md-2 data-required">
                <h4 class="counter">7</h4>
                <span class="data-counter">Postulaciones</span>
                <hr>
            </div>
        </div>
    </div>
    <div class="card w-100">
        <div class="card-header d-flex justify-content-between align-items-end">
            <span class="title mb-0">
                Mis Datos
            </span>

            <button (click)="onEdit = true;" class="btn btn-lg" style="margin:0; padding:0; border:0;">
                <i class="fa fa-edit p-2" *ngIf="!onEdit" style="margin-right:0"></i>
            </button>

            <button class="btn btn-info btn-sm p-2 m-1" *ngIf="onEdit" (click)="saveProfile()">
                <span style="font-weight: 600">Guardar Cambios</span>
            </button>
        </div>
        <div class="card-body">
            <div class="container-fluid" *ngIf="currentUser?.roles !== 'user'">
                <div class="section-info">
                    <label class="title-label">
                        Área o Dirección
                    </label>
                    <label class="data-label" *ngIf="!onEdit">
                        {{ profile?.Area }}
                    </label>
                    <input type="text" class="data-field" *ngIf="onEdit" [(ngModel)]="profile.Area">
                </div>
            </div>
        </div>

        <!-- Sección para realizar carga de firmar electrónica -->
        <ng-container *ngIf="currentUser?.roles == 'admin'">
            <!-- Solo el administrador puede realizar carga de FIEL -->
            <div class="card-header">
                <div class="subtitle-section">
                    <span class="subtitle">Firma digital</span>
                </div>
            </div>
            <div class="card-body" style="padding: 20px 10px">
                <div class="container-fluid">
                    <table class="table table-mv table-danger-mv table-borderless">
                        <thead>
                            <tr class="w-100">
                                <th style="width:90%; border-bottom: 0"></th>
                                <th style="width:10%; border-bottom: 0">
                                    <span *ngIf="!isCerLoaded || !isKeyLoaded" class="text-danger">
                                        Agregar
                                    </span>

                                    <span *ngIf="isCerLoaded && isKeyLoaded" class="text-primary">
                                        Reemplazar
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="w-100 certificado">
                                <td style="width:90%" [ngClass]="{ 'loaded' : isCerLoaded, 'notloaded': !isCerLoaded }">
                                    <div class="d-flex justify-content-between">
                                        <div class="bd-highlight">
                                            <span
                                                [ngClass]="{ 'text-danger': !isCerLoaded, 'text-secondary': isCerLoaded }">Certificado (.cer)</span>
                                        </div>
                                        <div class="bd-highlight">
                                            <!-- Cargar Certificado (.cer) -->
                                            <div *ngIf="!isCerLoaded">
                                                <button class="btn-static text-uppercase text-danger text-center"
                                                    style="font-weight: bold" (click)="fileCerInput.click()">
                                                    Sin Agregar
                                                </button>
                                            </div>
                                            <!-- ./Cargar Certificado (.cer) -->

                                            <!-- Fecha de Actualización de Certificado (.cer) -->
                                            <div *ngIf="isCerLoaded">
                                                <span class="text-secondary">Actualizado {{ fechaUltimaVez.cer |
                                                    date:'yyyy-MM-dd H:m:s' }}</span>
                                            </div>
                                            <!-- ./Fecha de Actualización de Certificado (.cer) -->
                                        </div>
                                    </div>
                                </td>
                                <td style="width:10%" class="text-center"
                                    [ngClass]="{ 'loaded' : isCerLoaded, 'notloaded': !isCerLoaded }">
                                    <ng-container *ngIf="!isCerLoaded">
                                        <button class="btn-static text-danger text-center" style="font-weight: bold"
                                            (click)="fileCerInput.click()">
                                            <i class="fa fa-plus-square" aria-hidden="true"></i>
                                        </button>
                                    </ng-container>

                                    <ng-container *ngIf="isCerLoaded">
                                        <button class="btn-static text-primary text-center" style="font-weight: bold"
                                            (click)="fileCerInput.click()">
                                            <i class="fa fa-refresh" aria-hidden="true"></i>
                                        </button>
                                    </ng-container>

                                    <!-- Elemento auxiliar para subir cer -->
                                    <input type="file" #fileCerInput class="d-none" accept="application/x-x509-ca-cert"
                                        (change)="uploadCerFile($event)">
                                    <!-- ./Elemento auxiliar para subir cer -->
                                </td>
                            </tr>
                            <tr class="w-100 clave-privada">
                                <td style="width:90%" [ngClass]="{ 'loaded' : isKeyLoaded, 'notloaded': !isKeyLoaded }">
                                    <div class="d-flex justify-content-between">
                                        <div class="bd-highlight">
                                            <span
                                                [ngClass]="{ 'text-danger': !isKeyLoaded, 'text-secondary': isKeyLoaded }">Clave
                                                privada (.key)</span>
                                        </div>
                                        <div class="bd-highlight text-danger">
                                            <!-- Cargar Clave privada (.key) -->
                                            <div *ngIf="!isKeyLoaded">
                                                <form>
                                                    <button type="submit"
                                                        class="btn-static text-uppercase text-danger text-center"
                                                        style="font-weight: bold">
                                                        Sin Agregar
                                                    </button>
                                                </form>
                                            </div>
                                            <!-- Cargar Clave privada (.key) -->

                                            <!-- Reemplazar Clave privada (.key) -->
                                            <div *ngIf="isKeyLoaded">
                                                <span class="text-secondary">Actualizado {{ fechaUltimaVez.key |
                                                    date:'yyyy-MM-dd H:m:s' }}</span>
                                            </div>
                                            <!-- Reemplazar Clave privada (.key) -->
                                        </div>
                                    </div>
                                </td>
                                <td style="width:10%" class="text-center"
                                    [ngClass]="{ 'loaded' : isKeyLoaded, 'notloaded': !isKeyLoaded }">
                                    <ng-container *ngIf="!isKeyLoaded">
                                        <button class="btn-static text-danger" style="font-weight: bold"
                                            (click)="fileKeyInput.click()">
                                            <i class="fa fa-plus-square" aria-hidden="true"></i>
                                        </button>
                                    </ng-container>

                                    <ng-container *ngIf="isKeyLoaded">
                                        <button class="btn-static text-primary" style="font-weight: bold"
                                            (click)="fileKeyInput.click()">
                                            <i class="fa fa-refresh" aria-hidden="true"></i>
                                        </button>
                                    </ng-container>

                                    <!-- Elemento auxiliar para subir key -->
                                    <input type="file" #fileKeyInput class="d-none" accept=".key"
                                        (change)="uploadKeyFile($event)" required>
                                    <!-- ./Elemento auxiliar para subir key -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </ng-container>
        <!-- ./Sección para realizar carga de firmar electrónica -->

        <div class="card-header">
            <div class="subtitle-section">
                <span class="subtitle">
                    Datos de acceso
                </span>
            </div>
        </div>
        <div class="card-body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                CURP <span *ngIf="onEdit" class="text-danger">*</span>
                            </label>
                            <label class="data-label" *ngIf="!onEdit">
                                {{ profile?.Person?.CURP }}
                            </label>
                            <input type="text" disabled class="data-field" *ngIf="onEdit"
                                [(ngModel)]="profile.Person.CURP">
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Correo electrónico <span *ngIf="onEdit" class="text-danger">*</span>
                            </label>
                            <label class="data-label" *ngIf="!onEdit">
                                {{ profile?.User?.email }}
                            </label>
                            <input type="text" disabled class="data-field" *ngIf="onEdit"
                                [(ngModel)]="profile.User.email">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-header">
            <div class="subtitle-section">
                <span class="subtitle">
                    Datos Generales
                </span>
            </div>
        </div>
        <div class="card-body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Nombre(s) <span *ngIf="onEdit" class="text-danger">*</span>
                            </label>
                            <label class="data-label" *ngIf="!onEdit">
                                {{ profile?.User?.firstName }}
                            </label>
                            <input type="text" class="data-field" *ngIf="onEdit" [(ngModel)]="profile.User.firstName">
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Apellido Paterno <span *ngIf="onEdit" class="text-danger">*</span>
                            </label>
                            <label class="data-label" *ngIf="!onEdit">
                                {{ dadName }}
                            </label>
                            <input type="text" class="data-field" *ngIf="onEdit" [(ngModel)]="dadName">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Apellido materno <span *ngIf="onEdit" class="text-danger">*</span>
                            </label>
                            <label class="data-label" *ngIf="!onEdit">
                                {{ momName }}
                            </label>
                            <input type="text" class="data-field" *ngIf="onEdit" [(ngModel)]="momName">
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Fecha de nacimiento <span *ngIf="onEdit" class="text-danger">*</span>
                            </label>
                            <label class="data-label" *ngIf="!onEdit">
                                {{ profile?.User?.birthday ? ( profile?.User?.birthday | date: 'dd/MM/yyyy') : '-' }}
                            </label>
                            <input type="date" class="data-field" *ngIf="onEdit" [(ngModel)]="profile.User.birthday">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                RFC <span *ngIf="onEdit" class="text-danger">*</span>
                            </label>
                            <label class="data-label" *ngIf="!onEdit">
                                {{ profile?.Person?.TaxId || '-' }}
                            </label>
                            <input type="text" class="data-field" *ngIf="onEdit" [(ngModel)]="profile.Person.TaxId">
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Teléfono celular <span *ngIf="onEdit" class="text-danger">*</span>
                            </label>
                            <label class="data-label" *ngIf="!onEdit">
                                {{ profile?.User?.phoneNumber || '-' }}
                            </label>
                            <input type="text" class="data-field" *ngIf="onEdit" [(ngModel)]="profile.User.phoneNumber">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Teléfono particular
                            </label>
                            <label class="data-label" *ngIf="!onEdit">
                                {{ profile?.User?.ContactPhoneNumber || '-' }}
                            </label>
                            <input type="text" class="data-field" *ngIf="onEdit"
                                [(ngModel)]="profile.User.ContactPhoneNumber">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-header">
            <div class="subtitle-section">
                <span class="subtitle">
                    Domicilio
                </span>
            </div>
        </div>
        <div class="card-body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Código postal <span *ngIf="onEdit" class="text-danger">*</span>
                            </label>
                            <label class="data-label" *ngIf="!onEdit">
                                {{ address?.ZipCode || '-' }}
                            </label>
                            <input type="text" class="data-field" *ngIf="onEdit" [(ngModel)]="address.ZipCode">
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Estado <span *ngIf="onEdit" class="text-danger">*</span>
                            </label>
                            <label class="data-label" *ngIf="!onEdit">
                                {{ address?.State || '-' }}
                            </label>
                            <input type="text" class="data-field" *ngIf="onEdit" [(ngModel)]="address.State">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Municipio <span *ngIf="onEdit" class="text-danger">*</span>
                            </label>
                            <label class="data-label" *ngIf="!onEdit">
                                {{ address?.City || '-' }}
                            </label>
                            <input type="text" class="data-field" *ngIf="onEdit" [(ngModel)]="address.City">
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Colonia <span *ngIf="onEdit" class="text-danger">*</span>
                            </label>
                            <label class="data-label" *ngIf="!onEdit">
                                {{ address?.Neighborhood || '-' }}
                            </label>
                            <input type="text" class="data-field" *ngIf="onEdit" [(ngModel)]="address.Neighborhood">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Calle <span *ngIf="onEdit" class="text-danger">*</span>
                            </label>
                            <label class="data-label" *ngIf="!onEdit">
                                {{ address?.Street || '-' }}
                            </label>
                            <input type="text" class="data-field" *ngIf="onEdit" [(ngModel)]="address.Street">
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Número exterior <span *ngIf="onEdit" class="text-danger">*</span>
                            </label>
                            <label class="data-label" *ngIf="!onEdit">
                                {{ address?.ExtNumber || '-' }}
                            </label>
                            <input type="text" class="data-field" *ngIf="onEdit" [(ngModel)]="address.ExtNumber">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Número interior
                            </label>
                            <label class="data-label" *ngIf="!onEdit">
                                {{ address?.IntNumber || '-' }}
                            </label>
                            <input type="text" class="data-field" *ngIf="onEdit" [(ngModel)]="address.IntNumber">
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="section-info">
                            <label class="title-label">
                                Referencias
                            </label>
                            <label class="data-label" *ngIf="!onEdit">
                                {{ address?.Notes || '-' }}
                            </label>
                            <input type="text" class="data-field" *ngIf="onEdit" [(ngModel)]="address.Notes">
                        </div>
                    </div>

                    <div class="col-12 col-md-6 py-5" *ngIf="onEdit">
                        <b for="" class="data-label"> <span *ngIf="onEdit" class="text-danger">*</span> Campos
                            obligatorios</b>
                    </div>

                </div>
            </div>
        </div>
        <div class="card-footer"></div>
    </div>
</div>

<ng2-toasty></ng2-toasty>