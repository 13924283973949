<div class="row pt-5 pb-2">
    <div class="col-12">
        <ol class="bread px-0">
            <li class="bread-item" *ngFor="let item of historyItems; let i = index">
                <a routerLink="item.path">
                    {{ item.name }}
                    <i *ngIf="i !== historyItems.length-1" class="fa fa-chevron-right px-2 mx-0"></i>
                </a>
            </li>
        </ol>
    </div>
</div>