import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { ProductModel, FeatureModel } from '../../products/product.models';
import { environment } from 'src/environments/environment';
import { getHeaders } from '../../utils/headers.functions';

const BASE_URL: string = environment.API_URL;


@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
  }

  public createFeature(feature: FeatureModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/catalogs`, feature, { headers });
  }

  public updateFeature(id: number, feature: any): Observable<any> {
    const buildFeature = this.editFeature(feature);
    const updatedFeature = this.addBackendStructureFeature(buildFeature);
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/catalog/${id}`, updatedFeature, {headers});
  }

  public getCatalogs(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/catalogs`, {headers});
  }

  public getValuestoCatalog(Catalog: string): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/catalogs-data/${Catalog}`);
  }

  public getFeatures(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/catalogs-data/${'Feature'}`, {headers});
  }

  public deleteFeature(id: number): Observable<any> {
    return this.http.delete<any>(`${BASE_URL}/catalog/${id}`);
  }

  public getSpecifications(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/catalogs-data/${'Specification'}`, {headers});
  }

  public getFamilies(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/catalogs-data/${'Family'}`, {headers});
  }

  public getCategories(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/catalogs-data/${'Category'}`, {headers});
  }

  public getSubCategories(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/catalogs-data/${'Subcategory'}`, {headers});
  }

  public getProducts(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/products`);
  }

  public getProduct(productId: string): Observable<any> {
    return this.http.get<any>(`${BASE_URL}/products/${productId}`);
  }

  public getProductsFiltered(family: string): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/products-filter?family=${family}`);
  }

  public createProduct(product: ProductModel): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/products`, product, { headers });
  }

  public updateProduct(id: number, product: ProductModel): Observable<any> {
    // const buildProduct = this.editProduct(product);
    // console.log(buildProduct);
    // const updatedProduct = this.addBackendStructure(buildProduct);
    // console.log(updatedProduct);
    const headers: HttpHeaders = getHeaders();
    return this.http.put<any>(`${BASE_URL}/products/${id}`, product, {headers});
  }

  public deleteProduct(id: number): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.delete<Observable<any>>(`${BASE_URL}/products/${id}`, { headers });
  }

  public mapRequiredValues(product: any): any {
    //console.log(product);
    const productInfo: any = {};

    productInfo.id = product.id,
    productInfo.Title = product.Title,
    productInfo.Family = product.Family,
    productInfo.Category = product.Category,
    productInfo.SubCategory = product.SubCategory,
    productInfo.Brand = product.Brand,
    productInfo.SKU = product.SKU,
    productInfo.Model = product.Model,
    productInfo.Description = product.Description,
    productInfo.UnitPrice = product.UnitPrice,
    productInfo.CurrencyCode = product.CurrencyCode,
    productInfo.Quantity = product.Quantity,
    productInfo.featuresProduct = product.Features,
    productInfo.specificationsProduct = product.Specifications

    return productInfo;
  }

  private editProduct(product: any): any {
    // console.log(product);
    const editProduct = {
      Title: product.Title,
      Family: product.Family,
      Category: product.Category,
      SubCategory: product.SubCategory,
      Brand: product.Brand,
      SKU: product.SKU,
      Model: product.Model,
      Description: product.Description,
      UnitPrice: product.UnitPrice,
      CurrencyCode: product.CurrencyCode,
    };
    return editProduct;
  }

  private addBackendStructure(product: any) {
    return {
      Title: product.Title,
      Family: product.Family,
      Category: product.Category,
      SubCategory: product.SubCategory,
      Brand: product.Brand,
      SKU: product.SKU,
      Model: product.Model,
      Description: product.Description,
      UnitPrice: product.UnitPrice,
      CurrencyCode: product.CurrencyCode,
      ProductInfo: product.ProductInfo
    };
  }

  private editFeature(feature: any): any {
    const editFeature = {
      CatalogName: feature.CatalogName,
      ValueAlpha: feature.ValueAlpha,
    };
    return editFeature;
  }

  private addBackendStructureFeature(feature: any) {
    return {
      CatalogName: feature.CatalogName,
      ValueAlpha: feature.ValueAlpha
    };
  }
}