<div class="container-fluid">
    <div class="form-group row inputfilter">
        <label for="exampleFormControlSelect1" class="col-xl-4 col-sm-4 mb-0 textfilter">Filtrar Máquinas por Familia:</label>
        <ng-select class="col-xl-8 col-sm-8 no-padd" name="Family" id="Family" [(ngModel)]="selectedFamily" (change)="getProducts()">
          <ng-option *ngFor="let family of families" [value]="family.ValueAlpha">
              {{family.ValueAlpha}}
          </ng-option>
      </ng-select>
    </div>
    <br>

    <p>Total de productos: <strong>{{totalProducts}}</strong></p>
    
    <div *ngIf="totalProducts > 0" class="row products-admin ratio_asos">
        <div class="col-xl-3 col-sm-6" *ngFor="let product of product_list">
            <div class="card">
                <div class="card-body product-box">
                    <div class="img-wrapper">
                        <div class="lable-block"><span class="lable3" *ngIf="product.tag == 'new'">new</span> <span
                                class="lable4" *ngIf="product.sale == 'on sale'">on sale</span></div>
                        <div class="front">
                            <a href="javascript:void(0)"><img [src]="[product.ImgFileUrl]"
                                    class="img-fluid blur-up lazyload bg-img" alt=""></a>
                            <div class="product-hover">
                                <ul>
                                    <li>
                                        <button [routerLink]="['show', product.id]" class="btn" type="button" data-original-title="" title="">
                                            <i class="icon-eye"></i></button>
                                    </li>
                                    <li>
                                        <button [routerLink]="['edit', product.id]" class="btn" type="button" data-original-title="" title="">
                                            <i class="icon-pencil-alt"></i></button>
                                    </li>
                                    <li>
                                        <button class="btn" type="button" (click)="setModalContent(content, product.id)" data-original-title="" title=""><i
                                                class="icon-trash"></i></button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="product-detail">
                        <!-- <div class="rating"><i class="fa fa-star"></i> <i class="fa fa-star"></i> <i
                                class="fa fa-star"></i> <i class="fa fa-star"></i> <i class="fa fa-star"></i></div> -->
                        <a href="javascript:void(0)">
                            <h5>{{product.Title}}</h5>
                        </a>
                        <h4>{{product.UnitPrice | currency}} {{product.CurrencyCode}}</h4>
                        <br>
                        <h6>Marca: {{product.Brand}}</h6>
                        <h6>Modelo: {{product.Model}}</h6>
                        <!-- <ul class="color-variant">
                            <li class="bg-light0"></li>
                            <li class="bg-light1"></li>
                            <li class="bg-light2"></li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="totalProducts == 0" class="card center">
        <div class="card-body">
            <img class="image-notfound" src="../assets/images/no-found.jpeg" alt="Not found">
            <p class="nofound">No se han encontrado máquinas de la familia seleccionada.</p>
        </div>
      </div>
</div>

<ng-template #content
  let-modal>
  <div class="modal-header center">
    <h4 *ngIf="!productDeleteSuccess" class="modal-title text-center width-100"
      id="modal-title">Eliminar Producto</h4>

    <h4 *ngIf="productDeleteSuccess" class="modal-title text-center width-100"
      id="modal-title"><strong>Producto Eliminado</strong></h4>
  </div>
  <div class="modal-body text-center">
    <ng-container *ngIf="!productDeleteSuccess">
      <p class="no-mrg">Se eliminará el producto</p>
      <p class="no-mrg">Esta acción no se puede deshacer.</p>
    </ng-container>

    <ng-container *ngIf="productDeleteSuccess">
      <p>Producto eliminado correctamente</p>
    </ng-container>
  </div>
  <ng-container *ngIf="!productDeleteSuccess">
    <div class="modal-footer right">
      <a type="button"
        class="btn btn-outline-light"
        (click)="modal.close()">Cancelar</a>
      <button type="button"
        class="btn btn-inverse btn-danger"
        (click)="deleteProduct()">Eliminar</button>
    </div>
  </ng-container>
</ng-template>