<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-body">
            <div id="batchDelete" class="">
                <div class="row pl-3">
                    <div class="form-group w-25" *ngIf="catalogName === 'services'">
                        <h5><strong>Filtrar por área:</strong></h5>
                        <ng-select (change)="filterArea()" [(ngModel)]="departmentSelected">
                            <ng-option [value]="'Todos'">Todos</ng-option>
                            <ng-option *ngFor="let department of departments" [value]="department">
                                {{department}}
                            </ng-option>
                        </ng-select>
                    </div>
                    <div class="col-xs-12 col-md-6 text-right my-2" style="margin-left: auto;">
                        <button *ngIf="catalogName === 'dependencias'; else areas" class="btn btn-primary Add-btn"
                            (click)="setModalContent(content, '')">+ Crear Nueva Dependencias</button>
                        <ng-template #areas>
                            <button class="btn btn-primary Add-btn" (click)="setModalContent(content, '')">
                                Agregar
                            </button>
                        </ng-template>
                    </div>
                    <div class="search-bar">
                        <div class="flex-grow-1 mr-1 search-container">
                            <i class="fa fa-search search-icon" aria-hidden="true"></i>
                            <!-- <input placeholder="Buscar" class="form-control search-input" id="validationCustom1" type="text" [(ngModel)]="searchValue"> -->
                        </div>
                        <div class="mr-1">
                            <!-- <button class="btn btn-primary btn-buscar" (click)="applyFilters()">Buscar</button> -->
                        </div>
                        <div>
                            <!-- <button class="btn btn-primary btn-filtros" (click)="toggleSearchMenu()">
                                <i class="fa fa-filter" aria-hidden="true"></i>
                            </button> -->
                        </div>
                    </div>


                </div>

                <div *ngIf="catalogName === 'services'">
                    <div *ngIf="catalogs.length === 0">
                        <div class="col-sm-12 text-center section-b-space mt-5 no-found">
                            <img src="../../../assets/images/empty-search.jpg" class="img-fluid mb-4">
                            <h3>¡Lo sentimos! ¡No se han encontrado resultados! </h3>
                        </div>
                    </div>

                    <div class="table-responsive mt-5" *ngIf="catalogs.length > 0">
                        <table datatable [dtOptions]="dtOptions" class="table table-lg table-hover row-border hover">
                            <thead>
                                <tr>
                                    <th>Descripción Corta</th>
                                    <th>Descripción</th>
                                    <th>Área</th>
                                    <th>Etiquetas</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let catalog of catalogs">
                                    <td>{{catalog.ShortDescription}}</td>
                                    <td>{{catalog.Description}}</td>
                                    <td>{{catalog.Department}}</td>
                                    <td>{{catalog.Tags}}</td>
                                    <td class="actions-cell">
                                        <span>
                                            <span (click)="setModalContent(content, catalog)" title="Editar"
                                                style="cursor: pointer;">
                                                <i class="fa fa-edit"></i>
                                            </span>
                                        </span>
                                        <span title="Borrar" (click)="setModalContent(contentDelete,catalog)">
                                            <i class="fa fa-trash"></i>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <ngb-pagination (pageChange)="loadPage($event)" [collectionSize]="total" [(page)]="page"
                            [maxSize]="10" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
                    </div>
                </div>

                <div *ngIf="catalogName !== 'services'">
                    <div class="table-responsive mt-5" *ngIf="catalogs.length > 0">
                        <table datatable [dtOptions]="dtOptions" class="unstyle" class="table-lg table-hover row-border hover"> 
                            <thead>
                                <tr>
                                    <th>ITEM </th>
                                    <th>Abreviatura</th>
                                    <th *ngIf="catalogName === 'dependencias'; else areas">Dependencia </th>
                                    <ng-template areas> areas </ng-template>
                                    <th *ngIf="catalogName === 'dependencias'; else areas">Encargado</th>
                                    <ng-template #areas>
                                        <th>
                                            Nombre del director
                                        </th>
                                      </ng-template>
                                      <th class="text-center">ver</th>
                                      <th class="text-center">Editar</th>
                                    <th class="text-center">Eliminar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let catalog of catalogs">
                                    <td>{{catalog?.id}}</td>
                                    <td>{{catalog?.Alias}}</td>
                                    <td>{{catalog?.ValueAlpha}}</td>
                                    <td>{{catalog?.abbreviation || "-"}}</td>
                                    <td>{{catalog?.nameDirector || "-"}}</td>
                                    <td>{{catalog?.options || "-"}}</td>
                                    <td class="actions-cell text-center">
                                        <span (click)="setModalContent(content,catalog)" title="Editar"
                                            style="cursor: pointer;">
                                            <i class="fa fa-edit"></i>
                                        </span>
                                        <span title="Borrar" (click)="setModalContent(contentDelete,catalog)">
                                            <i class="fa fa-trash"></i>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <ngb-pagination (pageChange)="loadPage($event)" [collectionSize]="total" [(page)]="page"
                            [maxSize]="10" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title text-center width-100" id="modal-title" *ngIf="catalogName === 'areas'">{{onEdit ?
            'Editar Área':'Agregar Área'}}</h4>
        <h4 class="modal-title text-center width-100 title-modal" id="modal-title"
            *ngIf="catalogName === 'dependencias'">{{onEdit ? 'Editar Dependencia':'Crear Dependencia'}}</h4>
        <h4 class="modal-title text-center width-100" id="modal-title" *ngIf="catalogName === 'services'">{{onEdit ?
            'Editar Servicio':'Agregar Servicio'}}</h4>
        <h4 class="modal-title text-center width-100" id="modal-title" *ngIf="catalogName === 'sources'">{{onEdit ?
            'Editar Fuente' :'Agregar Fuente'}}</h4>
        <h4 class="modal-title text-center width-100" id="modal-title" *ngIf="catalogName === 'users'">{{onEdit ?
            'Editar Perfil':'Agregar Perfil'}}</h4>

    </div>
    <div class="modal-body text-center">
        <ng-container *ngIf="!isSuccess && catalogName === 'services'">
            <form [formGroup]="problemForm">
                <div class="form-group">
                    <h5>Descripción Corta:</h5>
                    <input class="form-control" type="text" formControlName="ShortDescription">
                </div>
                <div class="form-group">
                    <h5>Descripción:</h5>
                    <input class="form-control" type="text" formControlName="Description">
                </div>
                <div class="form-group">
                    <h5>Área:</h5>
                    <ng-select formControlName="Department">
                        <ng-option *ngFor="let department of departments" [value]="department">
                            {{department}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="form-group">
                    <h5>Etiquetas (para futuras consultas):</h5>
                    <input class="form-control" type="text" formControlName="Tags">
                </div>
            </form>
        </ng-container>

        <ng-container *ngIf="!isSuccess && catalogName !== 'services'">

            <ng-container *ngIf="!isSuccess && catalogName == 'dependencias'; else areas">
                <div class="mt-5">
                    <h5 class="subtitle-modal"> Nombre de dependencia <b class="asterisk-modal">*</b></h5>
                    <input class="form-control input-modal" placeholder="Seleccione dependencia"
                        [(ngModel)]="valueAlpha">
                </div>
                <div class="mt-5">
                    <h5 class="subtitle-modal">Abreviatura <b class="asterisk-modal">*</b></h5>
                    <input class="form-control" type="text" [(ngModel)]="abbreviation">
                </div>
                <div class="mt-5">
                    <h5 class="subtitle-modal">Encargado de dependencia <b class="asterisk-modal">*</b></h5>
                    <input class="form-control" placeholder="Seleccione encargado de área" [(ngModel)]="nameDirector">
                </div>
                <div class="mt-5">
                    <h5 class="subtitle-modal"><b class="asterisk-modal">*</b> Campos obligatorios</h5>
                    <input class="form-control" type="text" [(ngModel)]="options">
                </div>
            </ng-container>
            <ng-template #areas>
                <div class="mt-4">
                    <h5>Nombre:</h5>
                    <input class="form-control" type="text" [(ngModel)]="valueAlpha">
                </div>
            </ng-template>

            <div class="error" *ngIf="!responseValidate">Campo nombre obligatorio.</div>
        </ng-container>

        <!-- <ng-container *ngIf="isSuccess">
            <p>Valor agregado correctamente</p>
        </ng-container> -->
    </div>
    <div class="modal-footer">
        <a type="button" class="btn btn-outline-light cancel-btn" (click)="modal.close()">Cancelar </a>
        <button [disabled]="isLoading" class="btn btn-primary submit-btn" (click)="onSubmit()">{{onEdit ? 'Editar' :
            'Guardar'}}</button>
    </div>

</ng-template>


<ng-template #contentDelete let-modal>
    <div class="modal-header">
        <h4 class="modal-title text-center width-100" id="modal-title" *ngIf="catalogName === 'areas'">Eliminar Área
        </h4>
        <h4 class="modal-title text-center width-100" id="modal-title" *ngIf="catalogName === 'services'">Eliminar
            Servicio</h4>
        <h4 class="modal-title text-center width-100" id="modal-title" *ngIf="catalogName === 'sources'">Eliminar Fuente
        </h4>
        <h4 class="modal-title text-center width-100" id="modal-title" *ngIf="catalogName === 'users'">Eliminar Perfil
        </h4>
    </div>
    <div class="modal-body text-center">
        <ng-container *ngIf="!deleteIsSuccess">
            <p class="no-mrg">Se eliminarán todos los datos del catálogo.</p>
            <p class="no-mrg">Esta acción no se puede deshacer.</p>
        </ng-container>

        <!-- <ng-container *ngIf="deleteIsSuccess">
      <p>Datos borrados correctamente</p>
    </ng-container> -->
    </div>
    <ng-container *ngIf="!deleteIsSuccess">
        <div class="modal-footer">
            <a type="button" class="btn btn-outline-light" (click)="modal.close()">Cancelar</a>
            <button type="button" class="btn btn-inverse btn-danger" (click)="deleteCatalog()"
                [disabled]="isLoading">Eliminar</button>
        </div>
    </ng-container>
</ng-template>

<ng2-toasty></ng2-toasty>