import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as moment from 'moment-timezone';
import { getHeaders } from 'src/app/shared/utils/headers.functions';

const BASE_URL: string = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(
    private http: HttpClient
  ) { }

  public getBestArea(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/bestarea`, {headers});
  }

  public getBestZone(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/bestcolony`, {headers});
  }

  public getAreasGraph(startDate:any, endDate:any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/graph?startDate=${startDate}&endDate=${endDate}`, {headers});
  }

  public getDashboardKPI(startDate:any, endDate:any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/summary-area/Alumbrado público?startDate=${startDate}&endDate=${endDate}`, {headers});
  }

//   public getTopCourses(): Observable<any> {
//     const headers: HttpHeaders = getHeaders();
//     return this.http.get<any>(`${BASE_URL}/topcourses`, {headers});
//   }

//   public getTopMembers(): Observable<any> {
//     const headers: HttpHeaders = getHeaders();
//     return this.http.get<any>(`${BASE_URL}/topmembers`, {headers});
//   }

//   public getCoursesGraph(): Observable<any> {
//     const headers: HttpHeaders = getHeaders();
//     return this.http.get<any>(`${BASE_URL}/coursesgraph`, {headers});
//   }

//   public getDashDates(): Observable<any> {
//     const headers: HttpHeaders = getHeaders();
//     const startDate = moment().startOf('month').format('YYYY-MM-DD');
//     const endDate   = moment().endOf('month').format('YYYY-MM-DD');
//     return this.http.get<any>(`${BASE_URL}/dashboarddates?StartDate=${startDate}&EndDate=${endDate}`, {headers});
//   }

}

