<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>Agregar Maquinaria</h5>
                </div> -->
                <div class="card-body">
                    <div class="row product-adding">
                        <div class="col-xl-5">
                            <div class="add-product">
                                <div class="row">
                                    <div class="col-xl-9 xl-50 col-sm-6 col-9">
                                        <img src="assets/images/pro3/1.jpg" alt="" class="img-fluid image_zoom_1">
                                    </div>
                                    <div class="col-xl-3 xl-50 col-sm-6 col-3">
                                        <ul class="file-upload-product">
                                            <li *ngFor="let item of url; let i=index">
                                                <div class="box-input-file">
                                                    <input class="upload" type="file" (change)="readUrl($event,i)">
                                                    <img class="imgUpload" alt="" [src]="item.img"></div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-7">
                            <form class="needs-validation" [formGroup]="productForm" (ngSubmit)="onSubmit()" novalidate>
                                <div class="add-product-form">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom01" class="col-form-label pt-0">Nombre</label>
                                                <input class="form-control" id="validationCustom01" type="text" [formControl]="productForm.controls['Title']"
                                                [ngClass]="{'error': submitted && formState?.Title?.errors?.required}">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom02" class="col-form-label pt-0">SKU</label>
                                                <input class="form-control" id="validationCustom02" type="text" [formControl]="productForm.controls['SKU']"
                                                [ngClass]="{'error': submitted && formState?.SKU?.errors?.required}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom03" class="col-form-label pt-0">Precio</label>
                                                <input class="form-control" id="validationCustom03" type="number" [formControl]="productForm.controls['UnitPrice']"
                                                [ngClass]="{'error': submitted && formState?.UnitPrice?.errors?.required}">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom04" class="col-form-label pt-0">Moneda</label>
                                                <select class="form-control custom-select" [formControl]="productForm.controls['CurrencyCode']"
                                                [ngClass]="{'error': submitted && formState?.CurrencyCode?.errors?.required}">
                                                    <option value="">Seleccciona moneda</option>
                                                    <option value="MXN">MXN</option>
                                                    <option value="USD">USD</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom05" class="col-form-label pt-0">Marca</label>
                                                <input class="form-control" id="validationCustom05" type="text" [formControl]="productForm.controls['Brand']"
                                                [ngClass]="{'error': submitted && formState?.Brand?.errors?.required}">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom06" class="col-form-label pt-0">Modelo</label>
                                                <input class="form-control" id="validationCustom06" type="text" [formControl]="productForm.controls['Model']"
                                                [ngClass]="{'error': submitted && formState?.Model?.errors?.required}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom07" class="col-form-label pt-0">Familia</label>
                                                <ng-select name="Family" id="Family" [formControl]="productForm.controls['Family']" [ngClass]="{'error': submitted && formState?.Family?.errors?.required}">
                                                    <ng-option *ngFor="let family of families" [value]="family.ValueAlpha">
                                                        {{family.ValueAlpha}}
                                                    </ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom08" class="col-form-label pt-0">Categoría</label>
                                                <ng-select name="Category" id="Category" [formControl]="productForm.controls['Category']">
                                                    <ng-option *ngFor="let category of categories" [value]="category.ValueAlpha">
                                                        {{category.ValueAlpha}}
                                                    </ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom09" class="col-form-label pt-0">Sub categoría</label>
                                                <ng-select name="SubCategory" id="SubCategory" [formControl]="productForm.controls['SubCategory']" [ngClass]="{'error': submitted && formState?.SubCategory?.errors?.required}">
                                                    <ng-option *ngFor="let subcategory of subcategories" [value]="subcategory.ValueAlpha">
                                                        {{subcategory.ValueAlpha}}
                                                    </ng-option>
                                                </ng-select>
                                            </div>
                                            <div class="form-group">
                                                <label for="validationCustom11" class="col-form-label pt-0">URL Imagen</label>
                                                <input class="form-control" id="validationCustom11" type="text" [formControl]="productForm.controls['ImgFileUrl']"
                                                [ngClass]="{'error': submitted && formState?.ImgFileUrl?.errors?.required}">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom10" class="col-form-label pt-0">Descripción</label>
                                                <br>
                                                <textarea class="form-control" [formControl]="productForm.controls['Description']" name="Description" id="" cols="40%" rows="10"
                                                [ngClass]="{'error': submitted && formState?.Description?.errors?.required}"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="form-group row">
                                        <label class="col-xl-3 col-sm-4 mb-0">Total de Productos :</label>
                                        <div class="qty-box1">
                                            <div class="input-group">
                                                <i class="fa fa-minus btnGtr1" (click)=decrement()></i>
                                                <input class="touchspin1 text-center" name="counter" [value]="counter"
                                                    type="text">
                                                <i class="fa fa-plus btnLess1" (click)=increment()></i>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-xs-12 col-sm-12 col-lg-12 pb-15">
                                        <div class="card-header no-border pl-0">
                                            <h5>Agregar Características</h5>
                                        </div>
                                        <div class="row">
                                            <div class="col-xs-12 col-lg-5">
                                                <ng-select name="featureId" id="featureId" [(ngModel)]="selectedFeature" [ngModelOptions]="{standalone: true}"
                                                [ngClass]="{'error': submitted && formState?.featureId?.errors?.required}">
                                                    <ng-option *ngFor="let feature of features" [value]="feature.ValueAlpha">
                                                      {{feature.ValueAlpha}}
                                                    </ng-option>
                                                </ng-select>
                                            </div>
                                            <div class="col-xs-12 col-lg-5">
                                                <input class="form-control" id="validationCustom11" type="text" [(ngModel)]="valueFeature" placeholder="Valor" [ngModelOptions]="{standalone: true}">
                                            </div>
                                            <div class="col-xs-12 col-lg-2">
                                                <button type="button" class="btn btn-primary mr-1" (click)="addFeature()">+</button>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="container" *ngIf="featuresProduct.length !== 0">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                <th>Propiedad</th>
                                                <th>Valor</th>
                                                <th>Eliminar</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let feature of featuresProduct">
                                                <td>{{feature.Property}}</td>
                                                <td>{{feature.Value}}</td>
                                                <td>
                                                    <span>
                                                        <a (click)="removeFeature(feature)"
                                                            title="Eliminar">
                                                            <i class="fa fa-trash"></i>
                                                        </a>
                                                    </span>
                                                </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-xs-12 col-sm-12 col-lg-12 pb-15">
                                        <div class="card-header no-border pl-0">
                                            <h5>Agregar Especificaciones</h5>
                                        </div>
                                        <div class="row">
                                            <div class="col-xs-12 col-lg-4">
                                                <ng-select name="specificationId" id="specificationId" [(ngModel)]="selectedSpecification" [ngModelOptions]="{standalone: true}">
                                                    <ng-option *ngFor="let specification of specifications" [value]="specification.ValueAlpha">
                                                      {{specification.ValueAlpha}}
                                                    </ng-option>
                                                </ng-select>
                                            </div>
                                            <div class="col-xs-12 col-lg-3">
                                                <input class="form-control" id="validationCustom12" type="text" [(ngModel)]="valueSpecification" placeholder="Valor" [ngModelOptions]="{standalone: true}">
                                            </div>
                                            <div class="col-xs-12 col-lg-3">
                                                <input class="form-control" id="validationCustom13" type="text" [(ngModel)]="GroupSpecification" placeholder="Grupo" [ngModelOptions]="{standalone: true}">
                                            </div>
                                            <div class="col-xs-12 col-lg-2">
                                                <button type="button" class="btn btn-primary mr-1" (click)="addSpecification()">+</button>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="container" *ngIf="specificationsProduct.length !== 0">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                <th>Propiedad</th>
                                                <th>Valor</th>
                                                <th>Grupo</th>
                                                <th>Eliminar</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let specification of specificationsProduct">
                                                <td>{{specification.Property}}</td>
                                                <td>{{specification.Value}}</td>
                                                <td>{{specification.Group}}</td>
                                                <td>
                                                    <span>
                                                        <a (click)="removeSpecification(specification)"
                                                            title="Eliminar">
                                                            <i class="fa fa-trash"></i>
                                                        </a>
                                                    </span>
                                                </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <hr>
                                <div class="form-group row mb-0">
                                    <!-- <div class="product-buttons col-sm-8 offset-xl-3 offset-sm-4">
                                        <button type="submit" class="btn btn-primary mr-1">Agregar</button>
                                    </div> -->
                                    <div class="product-buttons col-sm-8 offset-xl-4 offset-sm-4">
                                        <button class="btn btn-primary mright"
                                            [disabled]="isLoading" *ngIf="onEdit == false">
                                            {{ isLoading ? 'Creando máquina' : 'Agregar máquina'}}
                                            <i class="fa fa-spinner fa-spin"
                                            [hidden]="!isLoading"></i>
                                        </button>
                                    </div>

                                    <div class="product-buttons col-sm-8 offset-xl-4 offset-sm-4">
                                        <button type="submit" class="btn btn-primary mright" *ngIf="onEdit == true">
                                            {{ isLoading ? 'Editando máquina' : 'Editar máquina'}}
                                            <i class="fa fa-spinner fa-spin"
                                            [hidden]="!isLoading"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng2-toasty></ng2-toasty>