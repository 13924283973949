import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/components/services/auth/authentication.service';
import { Tramite, Queja, Requisito, Consulta } from 'src/app/components/catalogs/catalogs.models';

@Component({
  selector: 'app-create-tramites',
  templateUrl: './create-tramites.component.html',
  styleUrls: ['./create-tramites.component.scss']
})

export class CreateTramitesComponent implements OnInit {

  tramite = {
    Common: {
      DocumentId: '', // o asignar un valor por defecto que la API reconozca como vacío
      Name: '',
      Code: '',
      IsOnline: true,
      Applicant: '',
      Type: '',
      AnswerTime: 1,
      AnswerTimeType: 'Días hábiles',
      Validity: 1,
      ValidityType: 'Días naturales'
    },
    Detail: {
      Recipient: '',
      LegalBase: '',
      ResolutionCriteria: '',
      RightBreachAnswer: '',
      AuthorizedFormats: '',
      Description: '',
      ReasonForElimination: '',
      Office: '',
      Location: '',
      OfficePhone: '',
      Extension: '',
      Address: '',
      Schedule: '',
      IsVisible: true,
      IsLimited: false,
      IsRequestable: true
    },
    Inquiry: {
      Attender: '',
      Role: '',
      Address: '',
      Schedule: '',
      Phone: '',
      Email: '',
      Type: 1
    },
    Form: {
      AreaId: 11,
      created_by: ''
    }
  };

  constructor(private authService: AuthenticationService) { }

  ngOnInit(): void {
  }

  onSubmit(){
      this.authService.sendFormData(this.tramite)
        .subscribe(
          (response) => {
            console.log('Solicitud exitosa:', response);
            // Manejar la respuesta aquí
          },
          (error) => {
            console.error('Error al realizar la solicitud:', error);
            // Manejar el error aquí
          }
        );
  }
}