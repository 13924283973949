import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TramiteService } from '../tramite.service';
import { ToastOptions, ToastyConfig, ToastyService } from 'ng2-toasty';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { getHeaders } from '../tramite.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { UserModel } from '../../auth/auth.models';
import { environment } from '@/environments/environment';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-ver-tramite',
  templateUrl: './ver-tramite.component.html',
  styleUrls: ['./ver-tramite.component.scss'],
})
export class VerTramiteComponent implements OnInit {
  customColor = '#0f4260';
  tramiteId: string;
  tramiteDetails: any = new BehaviorSubject<any>(undefined);

  comments: string = '';
  isButtonsDisabled: boolean = false;
  isAcceptAndContinueDisabled: boolean = true;
  isPrevencionRespondida: boolean = false;

  private statusChangeSubscription: Subscription;

  // Información de la solicitud del trámite
  nombretramite: string;
  /* codigo: string; */
  folio: string;
  dependencia: string;
  area: string;
  fechacreacion: string;
  status: string = '';
  atendidoPor: string;
  fechaupdate: string;

  //Documentos
  documentos: any[] = []; // Array para almacenar la información de los documentos
  documentoActivo: any = null; // Documento activo que se mostrará en el visualizador
  displayedColumns: string[] = ['documento', 'aceptado', 'rechazado'];

  //Historial de la solicitud
  historial: any[]= null;

  //Datos Generales
  selectedOption: string;
  nombre: string;
  apellidoPaterno: string;
  apellidoMaterno: string;
  fecha_nacimiento: string;
  rfc: string;
  celular: string;
  telefono: string;
  calle: string;
  numExt: string;
  numInt: string;
  colonia: string;
  CodPos: string;
  municipio: string;
  estado: string;
  referencias: string;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic',
    size: 'lg',
    centered: true
  };
  // CitizenSurvey
  citizenSurveys: any[] = []; // Array para almacenar los datos de CitizenSurvey
  mostrarDatosFormulario: boolean = true;
  mostrarCampoDatosIncorrectos: boolean = true;
  isEditableSurveyComment: boolean = false;

  auxSurveyComment: string = '';

  isExpanded: boolean = true;
  isCollapsed: boolean = false;

  allDataFromAPI: any[] = [];
  dataFromAPI: any[] = [];
  requirementsFiles: string[] = []; // Aquí guardaremos los archivos base64

  errorMessage: string = ''; // Declaración de la propiedad errorMessage
  public documentSelect: any;
  adminResponse: string = '';
  indexDocument: any;
  showDocumento: any;
  formSelected: any;
  surveyComment: string = '';

  commentsAdded: string = '';

  emptyRefusedComment: boolean = false;

  isTramitePagado: boolean = false;
  isTramitePendientePago: boolean = false;

  isCursorLoading: boolean = false;
  isLoadingDocument: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private tramiteService: TramiteService,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private http: HttpClient // Agrega HttpClient para hacer la solicitud HTTP adicional
  ) {
    this.citizenSurveys = [];
    this.toastyConfig.theme = 'bootstrap';
  }

  closeModal() {
    this.modalService.dismissAll()
  }

  togglePanel(): void {
    this.isExpanded = !this.isExpanded;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.tramiteId = params.get('id');
      this.getTramiteDetails();

      // Cuando la variable tenga un valor en lugar de "undefined"..
      this.tramiteDetails.pipe( distinctUntilChanged() ).subscribe( () => this.getTramiteHistory(this.tramiteId));
    });

    this.statusChangeSubscription = this.tramiteService.tramiteStatusChanged$.subscribe(
      (data: any) => {
        this.tramiteDetails = undefined;

        if (data && data.tramiteId === this.tramiteId) {
          this.getTramiteDetails();

          // Cuando la variable cambie su valor..
          this.tramiteDetails.pipe( distinctUntilChanged() ).subscribe( () => this.getTramiteHistory(this.tramiteId));
        }
      }
    );

  }

  ngOnDestroy(): void {
    // Desuscribirse para evitar fugas de memoria
    if (this.statusChangeSubscription) {
      this.statusChangeSubscription.unsubscribe();
    }
  }

  checkStatus(): void {
    const status = this.status || localStorage.getItem('currentStatus');
    
    this.isButtonsDisabled = (status == 'Prevención') ? true : false;
    this.mostrarCampoDatosIncorrectos = (status === 'Prevención' || status === 'Rechazado' || status === 'Caducado' || status === 'Aceptado') ? false : true ;

    this.isEditableSurveyComment = this.mostrarCampoDatosIncorrectos;

    if (status === 'Prevención respondida') {
      this.isButtonsDisabled = false;
      this.mostrarCampoDatosIncorrectos = true;

      this.isEditableSurveyComment = true;
      
      this.isPrevencionRespondida = true;
    }

    console.log('checkStatus():');
    console.log(status);

    // Cambiar las opciones del trámite según el estado actual.
    switch(status){
      case 'Pendiente de Pago':
        /* Muestra el botón que permite proceder con la autorización pero deshabilitado */
        this.isTramitePendientePago= true;
      break;
      case 'Pagado':
        this.isTramitePendientePago= false;
        this.isTramitePagado= true;
      break;
      default:
      break;
    }
  }

  customizarTramiteHistory(h: any) {
    const historial = [];
    var d: Date, d2: Date;
    const options: Intl.DateTimeFormatOptions[] = [
      {
        year: 'numeric',
        month: 'long',
        day: '2-digit'
      },
      {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true // Usar el formato de 12 horas (AM / PM)
      }
    ];

    for(let hs of h){
      d = new Date(hs.created_at);
      d2 = new Date(hs.created_at);

      historial.push({
        ...hs,
        fechaFormateada: d.toLocaleDateString('es-MX', options[0]),
        horaFormateada: new Intl.DateTimeFormat('es-MX', options[1]).format(d2)
      });
    }

    return historial;
  }

  async getTramiteDetails() {
    if (!this.tramiteId) {
      console.error('El ID del trámite no es válido:', this.tramiteId);
      this.sendNotification('Error', 'El ID del trámite no es válido', 'error');
      return;
    }

    this.tramiteService.getTramiteById(this.tramiteId)
      .subscribe(
        async (data: any) => {
          if (data && data.CitizenRequest && data.UserProfile) {
            const formsCommonCode = data.Form.FormsCommon.Code ?? 'Null';
            const formCommonName = data.Form.FormsCommon.Name ?? 'Null';

            this.nombretramite = `${formsCommonCode} - ${formCommonName}`;
            this.folio = data.CitizenRequest.Folio ?? 'Null';
            this.area = data.Form.Areas.Name ?? 'Null';
            this.dependencia = data.Form.Areas.Departments.Name ?? 'Null';
            this.fechacreacion = this.formatDate(data.CitizenRequest.created_at) ?? 'Null';
            this.fechaupdate = this.formatDate(data.CitizenRequest.updated_at) ?? 'No actualizada';

            // Verificar todas las fases, incluyendo las inactivas

            console.log('data.Phases:');
            console.log(data.Phase);

            if (data.Phase && data.Phase.length > 0) {
              const activePhases = data.Phase.filter((phase: any) => phase.IsActive);

              if (activePhases.length > 0) {
                this.status = activePhases[activePhases.length - 1].Status;
              } else {
                // Si no hay fases activas, buscar la última fase no activa con un estado significativo
                const lastPhase = data.Phase[data.Phase.length - 1];  
                this.status = lastPhase ? lastPhase.Status : 'No disponible';
              }
            } else {
              this.status = 'No disponible';
            }

            console.log('this.status:');
            console.log(this.status);

            const user = JSON.parse(localStorage.getItem('currentUser'));

            if (user && user.displayName) {
              const storedAtendidoPor = localStorage.getItem(`atendidoPor_${this.tramiteId}`);
              if (!storedAtendidoPor) {
                localStorage.setItem(`atendidoPor_${this.tramiteId}`, user.displayName);
                this.atendidoPor = user.displayName;
              } else {
                this.atendidoPor = storedAtendidoPor;
              }
            } else {
              this.atendidoPor = 'Usuario desconocido';
            }

            this.selectedOption = data.UserProfile.TaxRegime ?? 'Null';
            this.rfc = data.UserProfile.TaxId ?? 'Null';

            // Consultar datos del perfil del usuario
            const userData = await this.authService.getUserV2Async(data.UserProfile.user_id);

            console.log('userData:');
            console.log(userData);
            
            const fullName = userData.User.firstName ?? 'Null';
            const lastNames = userData.User.lastName ?? 'Null';

            const names = fullName !== 'Null' ? fullName.split(' ') : '-'; // Divide el nombre completo por espacios en blanco
            const apellidos = lastNames.split(' ');

            this.nombre = names[0]; // El primer elemento es el nombre

            if (Array.isArray(apellidos) && apellidos.length == 2) {
              // Si hay al menos tres partes en el nombre, asumimos que el segundo y tercer elemento son los dos apellidos
              this.apellidoPaterno = apellidos[1]; // El segundo elemento es el primer apellido
              this.apellidoMaterno = apellidos[0]; // El primer elemento es el segundo apellido
            } else {
              // Si hay menos de dos partes en el nombre, algo está mal, puedes manejarlo según sea necesario
              this.apellidoPaterno = '-';
              this.apellidoMaterno = '-';
            }

            this.fecha_nacimiento = userData.User.birthday ?? 'Null';
            this.celular = data.UserProfile.MobilePhone ?? 'Null';
            this.telefono = data.UserProfile.OfficePhone ?? 'Null';
            this.calle = userData.Address.Street ?? 'Null';
            this.numExt = userData.Address.ExtNumber ?? 'Null';
            this.numInt = userData.Address.IntNumber ?? 'Null';
            this.colonia = userData.Address.Neighborhood ?? 'Null';
            this.CodPos = userData.Address.ZipCode ?? 'Null';
            this.municipio = userData.Address.City ?? 'Null';
            this.estado = userData.Address.State ?? 'Null';
            this.referencias = userData.Address.Notes ?? 'Null';

            this.tramiteDetails = data;

            if (data.CitizenRequirements && data.CitizenRequirements.length > 0) {
              this.documentos = data.CitizenRequirements.map((documento: any, index: number) => {
                return {
                  id: documento.id,
                  index: index + 1,
                  CitizenRequestId: documento.CitizenRequestId,
                  RequirementId: documento.Requirements?.id ?? 'Null',
                  FilePath: documento.FilePath,
                  status: documento.IsValid ?? null,
                  documentUpload: documento.FilePath ? true : false,
                  MimeType: documento.MimeType, // Agrega el campo MimeType
                  Name: documento.Requirements.Name ?? 'Null', // Usamos el nombre del documento en lugar de FilePath
                  Comments: documento.Comments ?? 'Null',
                  IsValid: documento.IsValid ?? false
                };
              })
              if (this.documentos && this.documentos.length > 0) {
                this.documentoActivo = this.documentos[0];
              }

              console.log(this.documentos)
            }

            if (data.CitizenSurvey && data.CitizenSurvey.length > 0) {
              this.citizenSurveys = data.CitizenSurvey.map((surveySection: any) => {
                this.surveyComment = (surveySection.Survey) ? surveySection.Survey[0].Comments : '';

                return {
                  id: surveySection.id,
                  Section: surveySection.Section,
                  Survey: surveySection.Survey.map((surveyItem: any) => {
                    if( surveyItem.IsValid === false && this.surveyComment == '' ){
                      this.surveyComment = surveyItem.Comments;
                    }

                    return {
                      Question: surveyItem.SurveyForm.Question ?? 'Null',
                      Answer: surveyItem.Answer ?? 'Null',
                      Comments: surveyItem.Comments ?? 'Null'
                    };
                  })
                };
              });
            }

            // Llamar a la función obtenerDocumentosBase64() después de procesar los detalles del trámite
            this.obtenerDocumentosBase64();

            this.checkStatus();

          } else {
            console.error('Los datos recibidos no contienen los detalles del trámite y/o los datos de CitizenSurvey.');
          }
        },
        (error: any) => {
          console.error('Error al obtener los detalles del trámite:', error);
        }
      );
  }

  getTramiteHistory(tramiteId: string | number): void {
    // Obtener el historial del trámite
    this.tramiteService.getTramiteHistory( tramiteId ).subscribe(
      (historial) => this.historial = this.customizarTramiteHistory(historial),
      (error) => console.error(error)
    );
  }

  public _modalValidarPrevencion: NgbModalRef = null;

  modalValidarPrevencion(content: any): void {
    this._modalValidarPrevencion= this.modalService.open(content, this.modalConfig);
    return;
  }

  public async verifyUserByPasswd(passwd: string): Promise<boolean> {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const userModel: UserModel = {
      email: currentUser.email,
      password: passwd
    };

    return new Promise((resolve, reject) => {
      this.http.post(`${environment.API_URL}/auth/signin`, userModel).subscribe(
        (data) => { console.log(data);  resolve(true); },
        (error) => { console.error(error); resolve(false); }
      );
    });
  }

  async validarPrevencionContra(passwd: HTMLInputElement) {
    const result = await this.verifyUserByPasswd(passwd.value);

    console.log( result );

    if(result){
      // Enviar el trámite a estatus de "Prevención".
      this.updateStatus('Prevención');
      return;
    }

    this.sendNotification('Contraseña Incorrecta','La contraseña que ha proporcionado no corresponde con la del usuario actual.','error',4000);
    return;
  }

  obtenerDocumentosBase64(): void {
    const documents = this.documentos.filter((doc: any) => doc.documentUpload);
    const headers = getHeaders();

    if (!documents.length) {
      console.error('No hay documentos para procesar.');
      return;
    }

    if (!headers) {
      console.error('Headers no están configurados correctamente.');
      return;
    }
    
    // Al cargar la página, mostrar el contenido del primer documento.
    const documento = documents[0];
    //http://45.85.146.201:3000/

    //this.http.get<any>(`http://45.85.146.201:3000/api/miveracruz/get_requirements_files/${documento.id}`, { headers }).subscribe((response: any) => {
    this.http.get<any>(`https://veraxapi.stackcode.io/api/miveracruz/get_requirements_files/${documento.id}`, { headers }).subscribe((response: any) => {
      if (!response) {
        console.warn('Respuesta nula para algún documento, continuando con el siguiente.');
        return;
      }

      const documentBase64 = response.Data.toString();

      // Mostrar la selección del documento.
      this.indexDocument = documento.index + 1;
      this.showDocumento = documento;

      // Validar que se tenga el documento y mostrarlo, caso contrario notificar un error.
      if( documentBase64.length > 0 && response.MimeType ){
        this.showDocument(documentBase64, response.MimeType);

        documento.base64 = response.Data;
        documento.MimeType = response.MimeType;
      }else{
        this.sendNotification('Error en el documento', 'No existe el documento cargado por el ciudadano.', 'error', 6000);
      }
    })
  }

  previousDocument() {
    if (this.showDocumento.index > 1) {
      this.showDocumento = this.documentos[this.showDocumento.index - 2];
    } else {
      this.showDocumento = this.documentos[this.documentos.length - 1];
    }
    this.seleccionarDocumento(this.showDocumento);
  }

  nextDocument() {
    if (this.showDocumento.index < this.documentos.length) {
      this.showDocumento = this.documentos[this.showDocumento.index];
    } else {
      this.showDocumento = this.documentos[0];
    }

    this.seleccionarDocumento(this.showDocumento);
  }

  openNewDocument() {
    const blob = this.base64ToBlob(this.showDocumento.base64, this.showDocumento.MimeType);
    window.open(URL.createObjectURL(blob), '_blank')
  }

  public base64ToBlob(base64Data: string, contentType = '', sliceSize = 512): Blob {
    // Remove any whitespace characters (for IE compatibility)
    base64Data = base64Data.replace(/\s/g, '');

    // Decode the base64 string
    const byteCharacters = atob(base64Data);

    // Create an array of byte arrays (sliced for performance)
    const byteArrays: Uint8Array[] = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    // Create the Blob
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  async seleccionarDocumento(documento: any, titleBeignPressed: boolean = false) {
    
    if (documento && documento.base64 && documento.MimeType) {
      this.showDocumento = documento;

      const base64Data = documento.base64;
      const mimeType = documento.MimeType;

      // Llamar a la función showDocument() con base64Data y mimeType, y mostrar el documento.
      this.showDocument(base64Data, mimeType);
    } else {
      const headers = getHeaders();
      
      if( this.isLoadingDocument == false ){ // Si "isLoadingDocument" es verdadero, significa que ya se está intentando cargar un documento.
        try{
          this.isLoadingDocument = true;
          this.isCursorLoading = true;

          const response = await this.http.get<any>(`https://veraxapi.stackcode.io/api/miveracruz/get_requirements_files/${documento.id}`, { headers }).toPromise();

          if (!response) { // Se informa en la consola de javascript y se interrumpe el resto del proceso ya que no se recibió ningun documento del servidor.

            console.error('No se pudo obtener el documento solicitdado.');
            this.isLoadingDocument = false; // Marcar que un documento no está siendo cargado.
            this.isCursorLoading = false; // Desactivar el cursor en modo cargando.

            return;
          }

          const documentBase64 = response.Data.toString();

          // Mostrar la selección del documento.
          this.indexDocument = documento.index + 1;
          this.showDocumento = documento;

          // Validar que se tenga el documento y mostrarlo, caso contrario notificar un error.
          if( documentBase64.length > 0 && response.MimeType ){
            this.showDocument(documentBase64, response.MimeType);

            documento.base64 = response.Data;
            documento.MimeType = response.MimeType;
          }else{
            this.sendNotification('Error en el documento', 'No existe el documento cargado por el ciudadano.', 'error');
          }

          this.isLoadingDocument = false; // Marcar que un documento no está siendo cargado.
          this.isCursorLoading = false; // Desactivar el cursor en modo cargando.

        }catch(error){
          console.error('seleccionarDocumento():', error);
        }
      }
    }
  }

  // Método para mostrar el documento en el visor
  showDocument(base64Data: string, mimeType: string): void {
    if (base64Data && mimeType) {
      // Crear un elemento para mostrar el documento
      let element: HTMLElement;

      if (mimeType === 'application/pdf') {
        // Si es un PDF, crear un elemento embed para mostrarlo
        element = document.createElement('embed');
        element.setAttribute('src', 'data:application/pdf;base64,' + base64Data);
        element.setAttribute('type', 'application/pdf');
        element.setAttribute('width', '100%');
        element.setAttribute('height', '600px');
      } else if (mimeType.startsWith('image/')) {
        // Si es una imagen, crear un elemento img para mostrarla
        element = document.createElement('img');
        element.setAttribute('src', 'data:' + mimeType + ';base64,' + base64Data);
        element.style.maxWidth = '100%';
      } else {
        // Tipo de archivo no compatible
        console.error('Tipo de archivo no compatible:', mimeType);
        return;
      }

      // Verificar si el elemento viewerContainer está definido
      const viewerContainer = document.getElementById('documentViewer');
      if (viewerContainer) {
        // Limpiar el contenedor del visualizador
        viewerContainer.innerHTML = '';

        // Agregar el elemento al contenedor del visualizador
        viewerContainer.appendChild(element);
      } else {
        console.error('No se encontró el elemento con id "documentViewer" en el HTML');
      }
    } else {

      const viewerContainer = document.getElementById('documentViewer');
      if (viewerContainer) {
        viewerContainer.innerHTML = '';
      } else {
        console.error('No se encontró el elemento con id "documentViewer" en el HTML');
      }
      
      this.errorMessage = 'El contenido del documento, es decir, el base64Data o el mimeType son indefinidos o nulos';
      console.error( this.errorMessage );
    }
  }

  detectFileType(base64Data: string): string {
    if (!base64Data) {
      console.error('El base64Data es indefinido o nulo');
      return ''; // O puedes manejarlo según tus necesidades
    }

    // Verificar el tipo de archivo basado en el base64Data
    if (base64Data.startsWith('data:image')) {
      return 'image';
    } else if (base64Data.startsWith('data:application/pdf')) {
      return 'pdf';
    } else {
      console.error('Tipo de archivo no compatible:', base64Data);
      return ''; // O puedes manejarlo según tus necesidades
    }
  }
  
  isDocumentsReviewed(){
    const documentsReviewed = this.documentos.filter((d) => d.status != null);
    
    if(documentsReviewed.length == this.tramiteDetails.CitizenRequirements.length){
      return true;
    }

    return false;
  }
  
  // Now you can use the 'blob' for further processing (e.g., displaying or downloading)
  approveDocument(documento: any, index: number) {

    // Independientemente del estado, si el trámite ya sufrió de un cambio ya no se puede volver a modificar mediante interacciones.
    if ( (this.tramiteDetails?.CitizenRequest?.updated_by &&
        this.tramiteDetails?.CitizenRequest?.updated_by !== this.tramiteDetails?.CitizenRequest?.created_by) && 
        this.status !== 'Prevención respondida')
    {
      this.sendNotification('Error', 'No se puede realizar está acción', 'error');
      return;
    }

    console.log('current index of document approve:');
    console.log(index);

    if(this.showDocumento.index == index){
      // "documento" se obtiene desde html (mediante el listado de documentos) al seleccionar la opción "aceptado" en la fila del documento
      // Se actualiza el estatus del documento seleccionado a aprovar.
      documento.status = true;
      documento.Comments = 'Null';
    }

    console.log('this.showDocumento:');
    console.log(this.showDocumento);
  }

  openCommentsDoc(content: any, documento: any, pos: number) {
    // content: corresponde al id del modal en html
    if(documento.Comments != '' && documento.Comments != 'Null'){
      
      this.documentSelect = documento;

      this.commentsAdded = this.documentSelect.Comments;

      this.modalService.open(content, this.modalConfig);
    }
  }

  rejectForm(content) {
    if( this.isDocumentsReviewed() ){
      // Se reinicia el contenido del comentario de "¿Los datos son correctos?".
      this.surveyComment = '';
      // Se abre el modal para enviar un comentario y negar el status del trámite por Datos del formulario incorrectos.
      this.modalService.open(content, this.modalConfig);
    }else{
      this.sendNotification(null, 'Los Documentos no han sido revisados. Favor de revisar antes de continuar.', 'warning');
      return;
    }
  }

  confirmCorrectData(event: Event, content: any){

    if( this.isDocumentsReviewed() ){
      // mostrar modal para confirmar cambios.
      this.modalConfig.size = 'md';
      this.modalService.open(content, this.modalConfig);

      // Regresar al modal su tamaño original.
      this.modalConfig.size = 'lg';
    }else{
      this.sendNotification(null, 'Los Documentos no han sido revisados. Favor de revisar antes de continuar.', 'warning');
      return;
    }
  }

  confirmFormData(){
    // Si desde el modal de "Confirmar Cambios" (de Datos del formulario) se da en aceptar...
    
    // Ocultar campo ¿Los datos son correctos? de Datos del formulario
    this.mostrarCampoDatosIncorrectos= false;

    this.closeModal();

    // Validar si no existe algun Documento rechazado
    const aDocumentInvalid = this.documentos.filter((d) => d.status == false );
    if(aDocumentInvalid.length > 0){
      // Deshabilitar el botón "Aceptar y Continuar"
      this.isAcceptAndContinueDisabled= true;
    }else{
      this.isAcceptAndContinueDisabled= false;
    }

    // Se establece IsValid como verdadero y comentarios en Null
    for(let ctsurvey of this.tramiteDetails.CitizenSurvey)
      for(let cmmsurvey of ctsurvey.Survey){
        cmmsurvey.IsValid = true;
        cmmsurvey.Comments = '';
      }
    
  }

  saveIncorrectDataComments(event: Event){
    // Guardar los comentarios en las preguntas de los Datos del formulario
    if(this.surveyComment.length > 0){
      // Asignar comentario realizado por el admin al formulario del ciudadano
      for(let ctsurvey of this.tramiteDetails.CitizenSurvey){

        /** Se asigna el comentario "surveyComment" al campo "Comments" de las preguntas de Datos del formulario **/
        for(let cmmsurvey of ctsurvey.Survey){
          cmmsurvey.IsValid = false;
          cmmsurvey.Comments = this.surveyComment;
        }
      }

      // Ocultar campo ¿Los datos son correctos? de Datos del formulario
      this.mostrarCampoDatosIncorrectos= false;

      // Deshabilitar el botón "Aceptar y Continuar", ya que se han invalidado los Datos del formulario
      this.isAcceptAndContinueDisabled= true;

      this.closeModal();
    }else{
      this.sendNotification('Error', "No se puede continuar si no ha ingresado un motivo al ciudadano.", 'error');
    }
    
    this.auxSurveyComment = '';
  }

  editCommentSurvey(content: any){
    // Guardar el texto original (observaciones del Dato de formulario)
    this.auxSurveyComment = this.surveyComment;

    this.modalService.open(content, this.modalConfig); 
  }

  undoSurveyComment(){
    this.surveyComment = '';

    this.isAcceptAndContinueDisabled= true;

    this.mostrarCampoDatosIncorrectos = true;
  }

  openRejectRequest(content: any) {
    this.comments= '';
    this.modalService.open(content, this.modalConfig);
  }

  openPrevencionRequest(content: any) {
    this.comments = '';
    this.modalService.open(content, this.modalConfig);
  }

  openReject(content: any, documento: any, index: number) {
    // Independientemente del estado, si el trámite ya sufrió de un cambio ya no se puede volver a modificar mediante interacciones.
    if (this.tramiteDetails?.CitizenRequest?.updated_by &&
      this.tramiteDetails?.CitizenRequest?.updated_by !== this.tramiteDetails?.CitizenRequest?.created_by &&
      this.status !== 'Prevención respondida')
    {
      this.sendNotification('Error', 'No se puede realizar está acción', 'error');
      return;
    }

    console.log('current index of document reject:');
    console.log(index);

    if(this.showDocumento.index == index){
      documento.index = index;
      // Se actualiza el estatus del documento seleccionado a no aprovado.
      documento.status = false;

      this.documentSelect = documento;
      this.documentSelect.Comments = '';

      this.modalService.open(content, this.modalConfig);
    }

    console.log('this.showDocumento:');
    console.log(this.showDocumento);
  }

  openModal(content: any){
    this.modalService.open(content, this.modalConfig);
    return;
  }

  getIndispensableSurveyData(){
    const surveysObject = [];

    // Recorrer cada sección de Survey
    for(let survey of this.tramiteDetails?.CitizenSurvey){
      Object.keys(survey.Survey).forEach((e, i) => {
        // acceder a cada pregunta de la seccion
        surveysObject.push({
          id: survey.Survey[i].id,
          IsValid: survey.Survey[i].IsValid
        });
      });
    }

    // Se regresa el objeto de las preguntas con los datos requeridos (id e IsValid)
    return surveysObject;
  }

  updateStatus(status: string) {
    const user = JSON.parse(localStorage['currentUser']);
    const auxComments = this.comments;

    const body = {
      Status: status,
      RejectReason: (status === 'Prevención' || status === "Rechazado" || status === 'Caducado') ? auxComments : '',
      CitizenRequest: { id: this.tramiteId, updated_by: user.id },
      CitizenRequirements: null,
      CitizenSurvey: null
    };

    // En caso que el trámite se encuentre en Pendiente Pago, Pagado, Finalizado o Aceptado.
    if(['Pendiente de Pago', 'Pagado', 'Finalizado', 'Aceptado', 'En Revisión', 'En Proceso'].includes(status)){
      delete body.CitizenRequirements;
      delete body.CitizenSurvey;
    }else{
      body.CitizenRequirements = this.documentos.map((doc: any) => {
        return {
          id: doc.id,
          IsValid: doc.status ? true : false,
          Comments: doc.Comments ?? ''
        };
      });

      body.CitizenSurvey= {
        Comments: this.surveyComment,
        Survey: this.getIndispensableSurveyData()
      };
    }
    

    /**
     * Cuando se encuentra en Prevención Respondida, las respuestas que se envían no se almacenan
     * debido a que en el endpoint al ser dicho estatus solo recibe CitizenRequest, Status y RejectReason.
    */



    /*console.log( body );
    return;*/

    this.tramiteService.updateTramite(body).subscribe(
      (data: any) => {
        this.sendNotification('Solicitud actualizada', 'Le notificaremos al ciudadano', 'success');
        this.comments = '';
        this.modalService.dismissAll();

        // En tiempo de interacción los botones se desactivan, al cargar/refrescar la página se realiza un chequeo y se vuelven a deshabilitar.
        if (status === 'Prevención') {
          this.isButtonsDisabled = true;
          this.mostrarCampoDatosIncorrectos = false;
        } else if (status === 'Prevención respondida') {
          this.isButtonsDisabled = false;
          this.mostrarCampoDatosIncorrectos = true;
        }
        // Almacenar el estado actualizado en localStorage
        localStorage.setItem('currentStatus', status);

        // Notificar el cambio de estado (si es necesario)
        this.tramiteService.notifyTramiteStatusChange(this.tramiteId, status);
        this.getTramiteDetails(); // Agrega esta línea
      },
      (error: any) => {
        console.error('Error al actualizar el estatus:', error);
        this.sendNotification('Error', 'Hubo un problema al actualizar el estatus', 'error');
      }
    );
  }

  limit150(event: Event) {
    var inputValue = event.target as HTMLTextAreaElement | HTMLInputElement;

    const value: string = this.documentSelect.Comments || this.comments || '';

    if(value.length > 150){
      if(this.documentSelect.Comments != undefined){
        this.documentSelect.Comments = value.slice(0,150);
      } else if(this.comments != undefined){
        this.comments = value.slice(0,150);
      }

      if(inputValue){
        inputValue.value = inputValue.value.slice(0,150);
      }
    }
  }

  limit300(event: Event) {
    var inputValue = event.target as HTMLTextAreaElement | HTMLInputElement;

    const value: string = this.comments || this.surveyComment || '';

    if(value.length > 300){
      if(this.comments != undefined){
        this.comments = value.slice(0,300);
      } else if(this.surveyComment != undefined){
        this.surveyComment = value.slice(0,300);
      }

      if(inputValue){
        inputValue.value = inputValue.value.slice(0,300);
      }
    }
  }

 handleError(event: Event) {
    console.error('Error al cargar el archivo:', event);
    // Realiza acciones adicionales según sea necesario, como mostrar un mensaje de error.
  }

  onSubmit(): void { 
  }

  get hasNoDocuments(): boolean {
    return !this.requirementsFiles || this.requirementsFiles.length === 0;
  }

  // Función para formatear la fecha
  formatDate(dateString: string | undefined): string {
    if (!dateString) {
      // Manejar el caso cuando la cadena es undefined
      return 'Fecha no disponible';
    }
    // Ejemplo de formato de fecha: "2024-04-12T00:00:00.000Z"
    const dateParts = dateString.split('T');
    if (dateParts.length < 2) {
      // Si la cadena no tiene el formato esperado, retornar un mensaje de error
      return 'Formato de fecha inválido';
    }
    const date = dateParts[0];
    const time = dateParts[1].split('.')[0];
    const [year, month, day] = date.split('-');
    const [hour, minute] = time.split(':');
    // Formatear la fecha como deseado
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  }

  // Declaración de la propiedad fileLoadError
  fileLoadError: boolean = false;

  private sendNotification(title: string = 'Morelos', message: string, type: string, timeo: number= 3500): void {
    const toastOptions: ToastOptions = {
      title,
      msg: message,
      showClose: true,
      timeout: timeo,
      theme: 'bootstrap'
    };

    this.toastyService[type](toastOptions);
  }

}